import { Request } from "./Api";
import { UPDATE_HOME_PROPS, MERGE_HOME_PROPS } from "./types";
import { checkisAuthorized } from "../../General/GeneralFunctions";
import toast from "toasted-notes";
import { local } from "../../Localization/local";
export const getUsers = (history, filter) => async (dispatch) => {
  if (checkisAuthorized() === false) {
    history.push("/");
  } else {
    dispatch({
      type: UPDATE_HOME_PROPS,
      payload: [{ prop: "isLoading", value: true }],
    });
    let date = new Date();
    date.setDate(date.getDate() + 1);
    filter.duration.to = date.toISOString();

    let counter = 1;
    let page = 0;

    let shipmentCounter = null;
    let filtershipment = {
      object: {},
      duration: {
        from: "2006-01-02T15:04:05Z",
        to: date.toISOString(),
      },
      page: 0,
    };
    Request()
      .post("/shipment", filtershipment)
      .then((response) => {
        if (response.data.status === true) {
          if (response.data.data.items !== null) {
            shipmentCounter = response.data.data.pages;
          }
        }
      });
    if (counter === 1) {
      await Request()
        .post("/user", filter)
        .then(async (response) => {
          if (response.data.status === true) {
            if (response.data.data.items !== null) {
              page = response.data.data.pages;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: UPDATE_HOME_PROPS,
            payload: [{ prop: "isLoading", value: false }],
          });
          history.push("/error");
        })
        .finally();
    }

    while (counter <= page) {
      await Request()
        .post("/user", filter)
        .then(async (response) => {
          if (response.data.status === true) {
            if (response.data.data.items !== null) {
              if (shipmentCounter !== null) {
                await response.data.data.items.map((item) => {
                  dispatch(getShipment(item, shipmentCounter));
                });
              }
            }
          }
        })
        .catch((error) => {
          toast.notify(local.InternetConnection, {
            position: "top-right",
            duration: 2000,
          });
          console.log(error);
          dispatch({
            type: UPDATE_HOME_PROPS,
            payload: [{ prop: "isLoading", value: false }],
          });
        })
        .finally();
      filter.page = filter.page + 1;
      counter = counter + 1;
    }
    dispatch({
      type: UPDATE_HOME_PROPS,
      payload: [{ prop: "isLoading", value: false }],
    });
  }
};

export const getShipment = (item, counter) => async (dispatch) => {
  let date = new Date();
  date.setDate(date.getDate() + 1);
  let page = 0;
  let shipments = [];
  while (page < counter) {
    let filter = {
      object: {
        user: item.id,
      },
      duration: {
        from: "2006-01-02T15:04:05Z",
        to: date.toISOString(),
      },
      page: page,
    };
    await Request()
      .post("/shipment", filter)
      .then((response) => {
        if (response.data.status === true) {
          if (response.data.data.items !== null) {
            response.data.data.items.map((sh) => {
              if (sh.status !== undefined) {
                if (sh.status !== 2) {
                  shipments.push(sh);
                }
              } else {
                shipments.push(sh);
              }
            });

            // response.data.data.items.map((sh) => {
            //   shipments.push(sh)
            // })
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally();
    page = page + 1;
  }
  if (page === counter) {
    const merge3 = shipments.flat(1);

    dispatch({
      type: MERGE_HOME_PROPS,
      payload: [
        {
          prop: "usersHome",
          value: {
            user: item,
            shipment: merge3,
            count: merge3.length,
          },
        },
      ],
    });
  }
};

export const EnglishCountries = [
  // { label: 'Afghanistan', value: 1 },
  // { label: 'Albania', value: 2 },
  // { label: 'Algeria', value: 3 },
  // { label: 'American Samoa', value: 4 },
  // { label: 'Andorra', value: 5 },
  // { label: 'Angola', value: 6 },
  // { label: 'Anguilla', value: 7 },
  // { label: 'Antarctica', value: 8 },
  // { label: 'Antigua and Barbuda', value: 9 },
  // { label: 'Argentina', value: 10 },
  // { label: 'Armenia', value: 11 },
  // { label: 'Australia', value: 12 },
  // { label: 'Austria', value: 13 },
  // { label: 'Azerbaijan', value: 14 },
  // { label: 'Bahamas', value: 15 },
  // { label: 'Bahrain', value: 16 },
  // { label: 'Bangladesh', value: 17 },
  // { label: 'Barbados', value: 18 },
  // { label: 'Belarus', value: 19 },
  // { label: 'Belgium', value: 20 },
  // { label: 'Belize', value: 21 },
  // { label: 'Benin', value: 22 },
  // { label: 'Bermuda', value: 23 },
  // { label: 'Bhutan', value: 24 },
  // { label: 'Bolivia', value: 25 },
  // { label: 'Bosnia and Herzegovina', value: 26 },
  // { label: 'Botswana', value: 27 },
  // { label: 'Brazil', value: 28 },
  // { label: 'Brunei', value: 29 },
  // { label: 'Bulgaria', value: 30 },
  // { label: 'Burkina Faso', value: 31 },
  // { label: 'Burundi', value: 32 },
  // { label: 'Cambodia', value: 33 },
  // { label: 'Cameroon', value: 34 },
  // { label: 'Canada', value: 35 },
  // { label: 'Cape Verde', value: 36 },
  // { label: 'Cayman Islands', value: 37 },
  // { label: 'Central African Republic', value: 38 },
  // { label: 'Chad', value: 39 },
  // { label: 'Chile', value: 40 },
  // { label: 'China', value: 41 },
  // { label: 'Colombia', value: 42 },
  // { label: 'Comoros', value: 43 },
  // { label: 'Democratic Republic of the Congo (Kinshasa)', value: 44 },
  // { label: 'Congo, Republic of (Brazzaville)', value: 45 },
  // { label: 'Cook Islands', value: 46 },
  // { label: 'Costa Rica', value: 47 },
  // { label: 'Ivory Coast (Cote d Ivoire)', value: 48 },
  // { label: 'Croatia', value: 49 },
  // { label: 'Cuba', value: 50 },
  // {
  //   label: 'Cyprus',
  //   value: 51,
  //   code: 'cy',
  //   lat: '35.1658799',
  //   lng: '34.5465551',
  // },
  // { label: 'Czechia', value: 52 },
  // { label: 'Denmark', value: 53 },
  // { label: 'Djibouti', value: 54 },
  // { label: 'Dominica', value: 55 },
  // { label: 'Dominican Republic', value: 56 },
  // { label: 'East Timor (Timor-Leste)', value: 57 },
  // { label: 'Ecuador', value: 58 },
  // { label: 'Estonia', value: 59 },
  // { label: 'El Salvador', value: 60 },
  // { label: 'Equatorial Guinea', value: 61 },
  // { label: 'Eritrea', value: 62 },
  {
    label: "Egypt",
    value: 63,
    code: "eg",
    lat: "26.8446991",
    lng: "35.3740421",
  },
  // { label: 'Ethiopia', value: 64 },
  // { label: 'Falkland Islands', value: 65 },
  // { label: 'Faroe Islands', value: 66 },
  // { label: 'Fiji', value: 67 },
  // { label: 'Finland', value: 68 },
  // { label: 'France', value: 69 },
  // { label: 'French Guiana', value: 70 },
  // { label: 'French Polynesia', value: 71 },
  // { label: 'Gabon', value: 72 },
  // { label: 'Gambia', value: 73 },
  // { label: 'Georgia', value: 74 },
  // { label: 'Germany', value: 75 },
  // { label: 'Ghana', value: 76 },
  // { label: 'Greece', value: 77 },
  // { label: 'Greenland', value: 78 },
  // { label: 'Grenada', value: 79 },
  // { label: 'Guadeloupe', value: 80 },
  // { label: 'Guam', value: 81 },
  // { label: 'Guatemala', value: 82 },
  // { label: 'Guinea', value: 83 },
  // { label: 'Guinea-Bissau', value: 84 },
  // { label: 'Guyana', value: 85 },
  // { label: 'Haiti', value: 86 },
  // { label: 'Honduras', value: 87 },
  // { label: 'Hong Kong', value: 88 },
  // { label: 'Hungary', value: 89 },
  // { label: 'Iceland', value: 90 },
  // { label: 'India', value: 91 },
  // { label: 'Indonesia', value: 92 },
  // { label: 'Iran (Islamic Republic of)', value: 93 },
  // { label: 'Iraq', value: 94 },
  // { label: 'Ireland', value: 95 },
  // { label: 'Israel', value: 96 },
  // { label: 'Italy', value: 97 },
  // { label: 'Jamaica', value: 98 },
  // { label: 'Japan', value: 99 },
  // { label: 'Jordan', value: 100 },
  // { label: 'Kazakhstan', value: 101 },
  // { label: 'Kenya', value: 102 },
  // { label: 'Kiribati', value: 103 },
  // { label: 'Korea, Democratic People,s Rep. (North Korea)', value: 104 },
  // { label: 'Korea, Republic of (South Korea)', value: 105 },
  // { label: 'Kuwait', value: 106 },
  // { label: 'Kyrgyzstan', value: 107 },
  // { label: 'Laos', value: 108 },
  // { label: 'Latvia', value: 109 },
  {
    label: "Lebanon",
    value: 118,
    code: "lb",
    lat: "33.8707616",
    lng: "36.96878",
  },
  // { label: 'Lesotho', value: 111 },
  // { label: 'Liberia', value: 112 },
  // { label: 'Libya', value: 113 },
  // { label: 'Liechtenstein', value: 114 },
  // { label: 'Lithuania', value: 115 },
  // { label: 'Luxembourg', value: 116 },
  // { label: 'Macau', value: 117 },
  // { label: 'North Macedonia', value: 118 },
  // { label: 'Madagascar', value: 119 },
  // { label: 'Malawi', value: 120 },
  // { label: 'Malaysia', value: 121 },
  // { label: 'Maldives', value: 122 },
  // { label: 'Mali', value: 123 },
  // { label: 'Malta', value: 124 },
  // { label: 'Marshall Islands', value: 125 },
  // { label: 'Martinique', value: 126 },
  // { label: 'Mauritania', value: 127 },
  // { label: 'Mauritius', value: 128 },
  // { label: 'Mayotte', value: 129 },
  // { label: 'Mexico', value: 130 },
  // { label: 'Mikronesien, Federated States of', value: 131 },
  // { label: 'Moldova', value: 132 },
  // { label: 'Monaco', value: 133 },
  // { label: 'Mongolia', value: 134 },
  // { label: 'Montenegro', value: 135 },
  // { label: 'Montserrat', value: 136 },
  {
    label: "Morocco",
    value: 144,
    code: "ma",
    lat: "31.7945",
    lng: "-7.0849",
  },
  // { label: 'Mozambique', value: 138 },
  // { label: 'Myanmar, Burma', value: 139 },
  // { label: 'Namibia', value: 140 },
  // { label: 'Nauru', value: 141 },
  // { label: 'Nepal', value: 142 },
  // { label: 'Netherlands', value: 143 },
  // { label: 'New Caledonia', value: 144 },
  // { label: 'New Zealand', value: 145 },
  // { label: 'Nicaragua', value: 146 },
  // { label: 'Niger', value: 147 },
  // { label: 'Nigeria', value: 148 },
  // { label: 'Northern Mariana Islands', value: 149 },
  // { label: 'Norway', value: 150 },
  // { label: 'Oman', value: 151 },
  // { label: 'Pakistan', value: 152 },
  // { label: 'Palau', value: 153 },
  // { label: 'State of Palestine', value: 154 },
  // { label: 'Panama', value: 155 },
  // { label: 'Papua New Guinea', value: 156 },
  // { label: 'Paraguay', value: 157 },
  // { label: 'Peru', value: 158 },
  // { label: 'Philippines', value: 159 },
  // { label: 'Poland', value: 160 },
  // { label: 'Portugal', value: 161 },
  // { label: 'Puerto Rico', value: 162 },
  // { label: 'Qatar', value: 163 },
  // { label: 'Réunion', value: 164 },
  // { label: 'Romania', value: 165 },
  // { label: 'Russian Federation', value: 166 },
  // { label: 'Rwanda', value: 167 },
  // { label: 'Saint Kitts and Nevis', value: 168 },
  // { label: 'Saint Lucia', value: 169 },
  // { label: 'Saint Vincent and the Grenadines', value: 170 },
  // { label: 'Samoa', value: 171 },
  // { label: 'San Marino', value: 172 },
  // { label: 'Sao Tome and Príncipe', value: 173 },
  {
    label: "Saudi Arabia",
    value: 187,
    code: "sa",
    lat: "24.7253981",
    lng: "47.3830408",
  },
  // { label: 'Senegal', value: 175 },
  // { label: 'Serbia', value: 176 },
  // { label: 'Seychelles', value: 177 },
  // { label: 'Sierra Leone', value: 178 },
  // { label: 'Singapore', value: 179 },
  // { label: 'Slovakia (Slovak Republic)', value: 180 },
  // { label: 'Slovenia', value: 181 },
  // { label: 'Solomon Islands', value: 182 },
  // { label: 'Somalia', value: 183 },
  // { label: 'South Africa', value: 184 },
  // { label: 'South Sudan', value: 185 },
  // { label: 'Spain', value: 186 },
  // { label: 'Sri Lanka', value: 187 },
  // { label: 'Sudan', value: 188 },
  // { label: 'Suriname', value: 189 },
  // { label: 'Swaziland (Eswatini)', value: 190 },
  // { label: 'Sweden', value: 191 },
  // { label: 'Switzerland', value: 192 },
  // { label: 'Syria, Syrian Arab Republic', value: 193 },
  // { label: 'Taiwan (Republic of China)', value: 194 },
  // { label: 'Tajikistan', value: 195 },
  // { label: 'Tanzania', value: 196 },
  // { label: 'Thailand', value: 197 },
  // { label: 'Tibet', value: 198 },
  // { label: 'Timor-Leste (East Timor)', value: 199 },
  // { label: 'Togo', value: 200 },
  // { label: 'Tonga', value: 201 },
  // { label: 'Trinidad and Tobago', value: 202 },
  // { label: 'Tunisia', value: 203 },
  // { label: 'Turkey', value: 204 },
  // { label: 'Turkmenistan', value: 205 },
  // { label: 'Tuvalu', value: 206 },
  // { label: 'Uganda', value: 207 },
  // { label: 'Ukraine', value: 208 },
  // {
  //   label: "United Arab Emirates",
  //   value: 209,
  //   code: "ae",
  //   lat: "24.3424989",
  //   lng: "56.2006893",
  // },
  // { label: 'United Kingdom', value: 210 },
  // { label: 'United States', value: 211 },
  // { label: 'Uruguay', value: 212 },
  // { label: 'Uzbekistan', value: 213 },
  // { label: 'Vanuatu', value: 214 },
  // { label: 'Vatican City State (Holy See)', value: 215 },
  // { label: 'Venezuela', value: 216 },
  // { label: 'Vietnam', value: 217 },
  // { label: 'Virgin Islands (British)', value: 218 },
  // { label: 'Virgin Islands (U.S.)', value: 219 },
  // { label: 'Wallis and Futuna Islands', value: 220 },
  // { label: 'Western Sahara', value: 221 },
  // { label: 'Yemen', value: 222 },
  // { label: 'Zambia', value: 223 },
  // { label: 'Zimbabwe', value: 224 },
];

export const ArabicCountries = [
  // { label: 'أفغانستان', value: 1 },
  // { label: 'ألبانيا', value: 2 },
  // { label: 'الجزائر', value: 3 },
  // { label: 'ساموا-الأمريكي', value: 4 },
  // { label: 'أندورا', value: 5 },
  // { label: 'أنجولا', value: 6 },
  // { label: 'أنجويلا', value: 7 },
  // { label: 'المنطقة القطبية الجنوبية', value: 8 },
  // { label: 'أنتيغوا وباربودا', value: 9 },
  // { label: 'الأرجنتين', value: 10 },
  // { label: 'أرمينيا', value: 11 },
  // { label: 'أستراليا', value: 12 },
  // { label: 'النمسا', value: 13 },
  // { label: 'أذربيجان', value: 14 },
  // { label: 'باهاماس', value: 15 },
  // { label: 'البحرين', value: 16 },
  // { label: 'بنجلاديش', value: 17 },
  // { label: 'باربادوس', value: 18 },
  // { label: 'بيلاروس', value: 19 },
  // { label: 'بلجيكا', value: 20 },
  // { label: 'بيليز', value: 21 },
  // { label: 'بنين', value: 22 },
  // { label: 'برمودا', value: 23 },
  // { label: 'بوتان', value: 24 },
  // { label: 'بوليفيا', value: 25 },
  // { label: 'البوسنة والهرسك', value: 26 },
  // { label: 'بوتسوانا', value: 27 },
  // { label: 'البرازيل', value: 28 },
  // { label: 'بروناي', value: 29 },
  // { label: 'بلغاريا', value: 30 },
  // { label: 'بوركينا فاسو', value: 31 },
  // { label: 'بوروندي', value: 32 },
  // { label: 'كمبوديا', value: 33 },
  // { label: 'كاميرون', value: 34 },
  // { label: 'كندا', value: 35 },
  // { label: 'الرأس الأخضر', value: 36 },
  // { label: 'جزر كايمان', value: 37 },
  // { label: 'جمهورية أفريقيا الوسطى', value: 38 },
  // { label: 'تشاد', value: 39 },
  // { label: 'تشيلي', value: 40 },
  // { label: 'الصين', value: 41 },
  // { label: 'كولومبيا', value: 42 },
  // { label: 'جزر القمر', value: 43 },
  // { label: 'جمهورية الكونغو الديمقراطية', value: 44 },
  // { label: 'جمهورية الكونغو', value: 45 },
  // { label: 'جزر كوك', value: 46 },
  // { label: 'كوستاريكا', value: 47 },
  // { label: 'ساحل العاج', value: 48 },
  // { label: 'كرواتيا', value: 49 },
  // { label: 'كوبا', value: 50 },
  // {
  //   label: 'قبرص',
  //   value: 51,
  //   code: 'cy',
  //   lat: '35.1658799',
  //   lng: '34.5465551',
  // },
  // { label: 'التشيك', value: 52 },
  // { label: 'الدنمارك', value: 53 },
  // { label: 'جيبوتي', value: 54 },
  // { label: 'دومينيكا', value: 55 },
  // { label: 'جمهورية الدومينيكان', value: 56 },
  // { label: 'تيمور الشرقية', value: 57 },
  // { label: 'الإكوادور', value: 58 },
  // { label: 'إستونيا', value: 59 },
  // { label: 'السلفادور', value: 60 },
  // { label: 'غينيا الاستوائية', value: 61 },
  // { label: 'إريتريا', value: 62 },
  { label: "مصر", value: 63, code: "eg", lat: "26.8446991", lng: "35.3740421" },
  // { label: 'إثيوبيا', value: 64 },
  // { label: 'جزر فوكلاند', value: 65 },
  // { label: 'جزر فارو', value: 66 },
  // { label: 'فيجي', value: 67 },
  // { label: 'فنلندا', value: 68 },
  // { label: 'فرنسا', value: 69 },
  // { label: 'غينيا الفرنسية', value: 70 },
  // { label: 'بولينيزيا الفرنسية', value: 71 },
  // { label: 'الغابون', value: 72 },
  // { label: 'غامبيا', value: 73 },
  // { label: 'جورجيا', value: 74 },
  // { label: 'ألمانيا', value: 75 },
  // { label: 'غانا', value: 76 },
  // { label: 'اليونان', value: 77 },
  // { label: 'جرينلاند', value: 78 },
  // { label: 'غرينادا', value: 79 },
  // { label: 'غوادلوب', value: 80 },
  // { label: 'غوام', value: 81 },
  // { label: 'غواتيمال', value: 82 },
  // { label: 'غينيا', value: 83 },
  // { label: 'غينيا بيساو', value: 84 },
  // { label: 'غيانا', value: 85 },
  // { label: 'هايتي', value: 86 },
  // { label: 'هندوراس', value: 87 },
  // { label: 'هونغ كونغ	', value: 88 },
  // { label: 'المجر', value: 89 },
  // { label: 'أيسلندا', value: 90 },
  // { label: 'الهند', value: 91 },
  // { label: 'إندونيسيا', value: 92 },
  // { label: 'إيران', value: 93 },
  // { label: 'العراق', value: 94 },
  // { label: 'جمهورية أيرلندا	', value: 95 },
  // { label: 'إسرائيل', value: 96 },
  // { label: 'إيطاليا', value: 97 },
  // { label: 'جامايكا', value: 98 },
  // { label: 'اليابان', value: 99 },
  // { label: 'الأردن', value: 100 },
  // { label: 'كازاخستان', value: 101 },
  // { label: 'كينيا', value: 102 },
  // { label: 'كيريباتي', value: 103 },
  // { label: 'كوريا الشمالية', value: 104 },
  // { label: 'كوريا الجنوبية', value: 105 },
  // { label: 'الكويت', value: 106 },
  // { label: 'قيرغيزستان', value: 107 },
  // { label: 'لاوس', value: 108 },
  // { label: 'لاتفيا', value: 109 },
  {
    label: "لبنان",
    value: 118,
    code: "lb",
    lat: "33.8707616",
    lng: "36.96878",
  },
  // { label: 'ليسوتو', value: 111 },
  // { label: 'ليبيريا', value: 112 },
  // { label: 'ليبيا', value: 113 },
  // { label: 'ليختنشتاين', value: 114 },
  // { label: 'ليتوانيا', value: 115 },
  // { label: 'لوكسمبورغ', value: 116 },
  // { label: 'ماكاو', value: 117 },
  // { label: 'مقدونيا الشمالية', value: 118 },
  // { label: 'مدغشقر', value: 119 },
  // { label: 'مالاوي', value: 120 },
  // { label: 'ماليزيا', value: 121 },
  // { label: 'المالديف', value: 122 },
  // { label: 'مالي', value: 123 },
  // { label: 'مالطا', value: 124 },
  // { label: 'جزر مارشال', value: 125 },
  // { label: 'مارتينيك', value: 126 },
  // { label: 'موريتانيا', value: 127 },
  // { label: 'موريشيوس', value: 128 },
  // { label: 'مايوت', value: 129 },
  // { label: 'المكسيك', value: 130 },
  // { label: 'ولايات ميكرونيسيا المتحدة', value: 131 },
  // { label: 'مولدوفا', value: 132 },
  // { label: 'موناكو', value: 133 },
  // { label: 'منغوليا', value: 134 },
  // { label: 'الجبل الاسود', value: 135 },
  // { label: 'مونتسرات', value: 136 },
  {
    label: "المغرب",
    value: 144,
    code: "ma",
    lat: "31.7945",
    lng: "-7.0849",
  },
  // { label: 'موزمبيق', value: 138 },
  // { label: '(بورما) ميانمار', value: 139 },
  // { label: 'ناميبيا', value: 140 },
  // { label: 'ناورو', value: 141 },
  // { label: 'نيبال', value: 142 },
  // { label: 'هولندا', value: 143 },
  // { label: 'كاليدونيا الجديدة', value: 144 },
  // { label: 'نيوزيلندا', value: 145 },
  // { label: 'نيكاراجوا', value: 146 },
  // { label: 'النيجر', value: 147 },
  // { label: 'نيجيريا', value: 148 },
  // { label: 'جزر ماريانا الشمالية', value: 149 },
  // { label: 'النرويج', value: 150 },
  // { label: 'عُمان', value: 151 },
  // { label: 'باكستان', value: 152 },
  // { label: 'بالاو', value: 153 },
  // { label: 'دولة فلسطين', value: 154 },
  // { label: 'بنما', value: 155 },
  // { label: 'بابوا غينيا الجديدة', value: 156 },
  // { label: 'باراغواي', value: 157 },
  // { label: 'بيرو', value: 158 },
  // { label: 'الفلبين', value: 159 },
  // { label: 'بولندا', value: 160 },
  // { label: 'البرتغال', value: 161 },
  // { label: 'بورتوريكو', value: 162 },
  // { label: 'قطر', value: 163 },
  // { label: 'ريونيون', value: 164 },
  // { label: 'رومانيا', value: 165 },
  // { label: 'روسيا', value: 166 },
  // { label: 'رواندا', value: 167 },
  // { label: 'سانت كيتس ونيفيس', value: 168 },
  // { label: 'سانت لوسيا', value: 169 },
  // { label: 'سانت فينسنت والغرينادين', value: 170 },
  // { label: 'ساموا', value: 171 },
  // { label: 'سان مارينو', value: 172 },
  // { label: 'ساو تومي وبرينسيب', value: 173 },
  {
    label: "السعودية",
    value: 187,
    code: "sa",
    lat: "24.7253981",
    lng: "47.3830408",
  },
  // { label: 'السنغال', value: 175 },
  // { label: 'صربيا', value: 176 },
  // { label: 'سيشل', value: 177 },
  // { label: 'سيراليون', value: 178 },
  // { label: 'سنغافورة', value: 179 },
  // { label: 'سلوفاكيا', value: 180 },
  // { label: 'سلوفينيا', value: 181 },
  // { label: 'جزر سليمان', value: 182 },
  // { label: 'الصومال', value: 183 },
  // { label: 'جنوب أفريقيا', value: 184 },
  // { label: 'جنوب السودان', value: 185 },
  // { label: 'إسبانيا', value: 186 },
  // { label: 'سريلانكا', value: 187 },
  // { label: 'السودان', value: 188 },
  // { label: 'سورينام', value: 189 },
  // { label: 'سوازيلند (إسواتيني)	', value: 190 },
  // { label: 'السويد', value: 191 },
  // { label: 'سويسرا', value: 192 },
  // { label: 'سوريا', value: 193 },
  // { label: 'تايوان', value: 194 },
  // { label: 'طاجيكستان', value: 195 },
  // { label: 'تنزانيا', value: 196 },
  // { label: 'تايلاند', value: 197 },
  // { label: 'التبت', value: 198 },
  // { label: 'تيمور الشرقية', value: 199 },
  // { label: 'توغو', value: 200 },
  // { label: 'تونغا', value: 201 },
  // { label: 'ترينيداد وتوباغو', value: 202 },
  // { label: 'تونس', value: 203 },
  // { label: 'تركيا', value: 204 },
  // { label: 'تركمانستان', value: 205 },
  // { label: 'توفالو', value: 206 },
  // { label: 'أوغندا', value: 207 },
  // { label: 'أوكرانيا', value: 208 },
  // {
  //   label: "الإمارات العربية المتحدة",
  //   value: 209,
  //   code: "ae",
  //   lat: "24.3424989",
  //   lng: "56.2006893",
  // },
  // { label: 'المملكة المتحدة', value: 210 },
  // { label: 'الولايات المتحدة', value: 211 },
  // { label: 'أوروغواي', value: 212 },
  // { label: 'أوزبكستان', value: 213 },
  // { label: 'فانواتو', value: 214 },
  // { label: 'الفاتيكان', value: 215 },
  // { label: 'فنزويلا', value: 216 },
  // { label: 'فيتنام', value: 217 },
  // { label: 'جزر العذراء البريطانية', value: 218 },
  // { label: 'جزر العذراء الأمريكية', value: 219 },
  // { label: 'واليس وفوتونا', value: 220 },
  // { label: 'الصحراء الغربية', value: 221 },
  // { label: 'اليمن', value: 222 },
  // { label: 'زامبيا', value: 223 },
  // { label: 'زيمبابوي', value: 224 },
];

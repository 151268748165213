import { UPDATE_COUNTRY_PROPS } from './types'
import { EnglishCountries } from '../../General/EnglishCountries'
import { ArabicCountries } from '../../General/ArabicCountries'
import { getLanguage } from '../../Localization/local'

export const fetchCountryForDropDown = () => (dispatch) => {
  let allCountries = EnglishCountries.concat(ArabicCountries)
  dispatch({
    type: UPDATE_COUNTRY_PROPS,
    payload: [{ prop: 'allCountriesOptions', value: allCountries }],
  })

  if (getLanguage() === 'en') {
    dispatch({
      type: UPDATE_COUNTRY_PROPS,
      payload: [{ prop: 'countriesOptions', value: EnglishCountries }],
    })
  } else {
    dispatch({
      type: UPDATE_COUNTRY_PROPS,
      payload: [{ prop: 'countriesOptions', value: ArabicCountries }],
    })
  }
  // Request()
  //   .get('/countries')
  //   .then((response) => {
  //     if (
  //       response.data.status === true &&
  //       response.data.data.Countries !== null
  //     ) {
  //       let countries = []
  //       response.data.data.Countries.map((country) => {
  //         countries.push({ label: country.NiceName, value: country.ID })
  //       })

  //     }
  //   })
  //   .catch((error) => {
  //     console.log(error)
  //   })
  //   .finally()
}

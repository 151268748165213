import { Request, GetShipment } from "./Api";
import {
  UPDATE_SHIPMENTS_PROPS,
  MERGE_SHIPMENTS_PROPS,
  UPDATE_TRACKING_PROPS,
  UPDATE_TRACKING_HISTORY_PROPS,
} from "./types";
import { checkisAuthorized } from "../../General/GeneralFunctions";
import toast from "toasted-notes";
import { local } from "../../Localization/local";
import { ShipmentSUSER } from "../../General/StaticKeys";
export const fetchShipments = (history, filter) => (dispatch) => {
  if (checkisAuthorized() === false) {
    history.push("/customer/login");
  } else {
    if (
      filter.object.client !== undefined ||
      filter.object.status !== undefined ||
      filter.object.due_date !== undefined ||
      filter.object.added_on !== undefined ||
      filter.object.agent !== undefined
    ) {
      dispatch({
        type: UPDATE_SHIPMENTS_PROPS,
        payload: [
          { prop: "isLoadingSearch", value: true },
          { prop: "isSearchable", value: true },
          { prop: "isCreated", value: false },
        ],
      });
    } else {
      dispatch({
        type: UPDATE_SHIPMENTS_PROPS,
        payload: [
          { prop: "isLoading", value: true },
          { prop: "isSearchable", value: false },
          { prop: "isCreated", value: false },
        ],
      });
    }

    let date = new Date();
    date.setDate(date.getDate() + 1);

    filter.duration.to = date.toISOString();

    Request()
      .post("/shipment", filter)
      .then((response) => {
        if (response.data.status === true) {
          if (response.data.data.items !== null) {
            dispatch(getCustoemrs(response.data.data.items));

            dispatch({
              type: UPDATE_SHIPMENTS_PROPS,
              payload: [
                {
                  prop: "customerShipments",
                  value: [],
                },
                { prop: "rtable.shipments", value: response.data.data.items },
                { prop: "count", value: response.data.data.pages },
              ],
            });
          } else {
            if (
              filter.object.client !== undefined ||
              filter.object.status !== undefined ||
              filter.object.due_date !== undefined ||
              filter.object.added_on !== undefined ||
              filter.object.agent !== undefined
            ) {
              toast.notify(local.SearchResult, {
                position: "top-right",
                duration: 2000,
              });
            }
            dispatch({
              type: UPDATE_SHIPMENTS_PROPS,
              payload: [
                {
                  prop: "customerShipments",
                  value: [],
                },
                { prop: "rtable.shipments", value: [] },
              ],
            });
          }
        } else {
          if (
            filter.object.client !== undefined ||
            filter.object.status !== undefined ||
            filter.object.due_date !== undefined ||
            filter.object.added_on !== undefined ||
            filter.object.agent !== undefined
          ) {
            toast.notify(local.SearchResult, {
              position: "top-right",
              duration: 2000,
            });
          }
          dispatch({
            type: UPDATE_SHIPMENTS_PROPS,
            payload: [{ prop: "rtable.shipments", value: [] }],
          });
        }
        dispatch({
          type: UPDATE_SHIPMENTS_PROPS,
          payload: [
            { prop: "isLoading", value: false },
            { prop: "isLoadingSearch", value: false },
          ],
        });
      })
      .catch((error) => {
        // toast(local.InternetConnection)

        console.log(error);
        dispatch({
          type: UPDATE_SHIPMENTS_PROPS,
          payload: [
            { prop: "isLoading", value: false },
            { prop: "isLoadingSearch", value: false },
          ],
        });
        // history.push("/error");
      })
      .finally();
  }
};

export const modifyShipment = (newForm, history, index, ass) => (dispatch) => {
  dispatch({
    type: UPDATE_SHIPMENTS_PROPS,
    payload: [{ prop: "isLoadingSave", value: true }],
  });

  let date = new Date(newForm.due_date);
  date.setDate(date.getDate() + 1);
  newForm.due_date = date.toISOString();
  newForm.phone = newForm.phone.replace(/[()]/g, "");
  newForm.phone = newForm.phone.replace(/"/g, "");
  let url = null;
  if (newForm.id === undefined) {
    url = "/shipment/new";
  } else {
    url = "/shipment/update";
  }

  Request()
    .post(url, newForm)
    .then((response) => {
      if (response.data.status === true) {
        if (newForm.id === undefined) {
          toast.notify(local.Successed, {
            position: "top-right",
            duration: 2000,
          });
          dispatch({
            type: UPDATE_SHIPMENTS_PROPS,
            payload: [{ prop: "isCreated", value: true }],
          });
        } else {
          dispatch({
            type: UPDATE_SHIPMENTS_PROPS,
            payload: [{ prop: "rtable.shipments." + index, value: newForm }],
          });
          if (ass === undefined) {
            toast.notify(local.Edited, {
              position: "top-right",
              duration: 2000,
            });
          } else {
            toast.notify(local.SuccessfullyAssign, {
              position: "top-right",
              duration: 2000,
            });

            dispatch({
              type: UPDATE_SHIPMENTS_PROPS,
              payload: [
                {
                  prop: "message",
                  value: {
                    isOpen: false,
                    sms: "",
                    title: "",
                    agentPhone: "",
                  },
                },
              ],
            });
          }
        }
        history.push("/subdealrs/shipments");
      } else {
        if (response.data.error !== null) {
          // toast.notify(local.ShipmentNumberExist, {
          //   position: 'top-right',
          //   duration: 2000,
          // })

          response.data.error.message.map((mess) => {
            toast.notify(mess, {
              position: "top-right",
              duration: 2000,
            });
          });
        }
      }
      dispatch({
        type: UPDATE_SHIPMENTS_PROPS,
        payload: [{ prop: "isLoadingSave", value: false }],
      });
    })

    .catch((error) => {
      dispatch({
        type: UPDATE_SHIPMENTS_PROPS,
        payload: [{ prop: "isLoadingSave", value: false }],
      });
    })
    .finally();
};

export const fetchCustShipment = (company, date, id, history) => (dispatch) => {
  let newDate = new Date(date);

  let url =
    "/shipment/info/" +
    company +
    "/" +
    newDate.toISOString().split(".")[0] +
    ".00Z" +
    "/" +
    id;
  GetShipment()
    .get(url)
    .then((response) => {
      dispatch({
        type: UPDATE_SHIPMENTS_PROPS,
        payload: [
          { prop: "webView", value: response.data.data },
          { prop: "isLoading", value: false },
          { prop: "agentName", value: response.data.data.agent.info.name },
          {
            prop: "CustomerName",
            value: response.data.data.shipment.info.clientName,
          },
          {
            prop: "ShipmentLocation",
            value: response.data.data.shipment.location,
          },
          {
            prop: "bounds." + 0,
            value: {
              lat: response.data.data.shipment.location
                .split("(")[1]
                .split(" ")[1]
                .split(")")[0],
              lng: response.data.data.shipment.location
                .split("(")[1]
                .split(" ")[1]
                .split(")")[0],
            },
          },
          {
            prop: "webViewlat",
            value: response.data.data.shipment.location
              .split("(")[1]
              .split(" ")[1]
              .split(")")[0],
          },
          {
            prop: "webViewlng",
            value: response.data.data.shipment.location
              .split("(")[1]
              .split(" ")[0],
          },
        ],
      });
      dispatch(
        fetchRate(
          company,
          response.data.data.agent.id,
          id,
          newDate.toISOString().split(".")[0] + ".00Z"
        )
      );

      dispatch(getShipmentLocation(company, id, date));
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: UPDATE_SHIPMENTS_PROPS,
        payload: [{ prop: "isLoading", value: false }],
      });
    })
    .finally();
};

export const getShipmentLocation = (company, id, date) => (dispatch) => {
  let newDate = new Date(date);
  let FilterDate = newDate.toISOString().split(".")[0] + ".00Z";

  var socketMessage = new WebSocket(
    "wss://medical.altawasol.app:1020/shipmenttrack/" +
      company +
      "/" +
      id +
      "/" +
      FilterDate
  );
  socketMessage.addEventListener("open", function (event) {
    console.log("done connect to socket");
  });

  socketMessage.addEventListener("message", function (event) {
    let entry = JSON.parse(event.data);

    if (entry.geometry !== undefined) {
      if (entry.geometry.coordinates !== undefined) {
        dispatch({
          type: UPDATE_SHIPMENTS_PROPS,
          payload: [
            {
              prop: "bounds." + 1,
              value: {
                lat: entry.geometry.coordinates[1],
                lng: entry.geometry.coordinates[0],
              },
            },
            {
              prop: "lat",
              value: "",
            },
            {
              prop: "lng",
              value: "",
            },
            {
              prop: "linesView",
              value: {
                lat: entry.geometry.coordinates[1],
                lng: entry.geometry.coordinates[0],
              },
            },
          ],
        });
      }
    }
  });
};

export const sendSms = (phoneNumber, sms) => (dispatch) => {
  let filter = { phone: phoneNumber, sms_text: sms === undefined ? "" : sms };
  Request()
    .post("/shipment/sms", filter)
    .then((response) => {
      if (response.data.status === true) {
        toast.notify(local.MessageSent, {
          position: "top-right",
          duration: 2000,
        });
        dispatch({
          type: UPDATE_SHIPMENTS_PROPS,
          payload: [
            { prop: "message.isOpen", value: false },
            { prop: "message.sms", value: "" },
            { prop: "link.isOpen", value: true },
          ],
        });

        dispatch({
          type: UPDATE_TRACKING_HISTORY_PROPS,
          payload: [
            { prop: "message.isOpen", value: false },
            { prop: "message.sms", value: "" },
          ],
        });
        dispatch({
          type: UPDATE_TRACKING_PROPS,
          payload: [
            { prop: "message.isOpen", value: false },
            { prop: "message.sms", value: "" },
          ],
        });
      }
    });
};

export const makeRate = (
  agentID,
  company,
  ShipmentID,
  value,
  added_on,
  comment
) => (dispatch) => {
  dispatch({
    type: UPDATE_SHIPMENTS_PROPS,
    payload: [
      { prop: "comment.isOpen", value: false },
      { prop: "comment.message", value: "" },
    ],
  });
  GetShipment()
    .post(
      "/agent/rate/" +
        company +
        "/" +
        agentID +
        "/" +
        ShipmentID +
        "/" +
        added_on +
        "/" +
        value +
        "/" +
        comment
    )
    .then((response) => {
      if (response.data.status === true) {
        toast.notify(local.CommentSent, {
          position: "top-right",
          duration: 2000,
        });
        // dispatch(fetchRate(company, agentID, ShipmentID))
      }
    })
    .catch((error) => console.log(error))
    .finally();
};

export const fetchRate = (companyID, agentID, shipmentID, added_on) => (
  dispatch
) => {
  let date = new Date();
  date.setDate(date.getDate() + 1);

  let url =
    "/agent/israted/" +
    companyID +
    "/" +
    agentID +
    "/" +
    shipmentID +
    "/" +
    added_on;
  GetShipment()
    .post(url)
    .then((response) => {
      if (response.data.status === true) {
        if (response.data.data !== null) {
          dispatch({
            type: UPDATE_SHIPMENTS_PROPS,
            payload: [{ prop: "comment.rate", value: response.data.data }],
          });
        }
      }
    })
    .catch((error) => console.log(error))
    .finally();
};
export const cancelDelivery = (newForm, history) => (dispatch) => {
  newForm.status = 4;

  dispatch({
    type: UPDATE_SHIPMENTS_PROPS,
    payload: [{ prop: "isCnacelDelivery", value: true }],
  });

  Request()
    .post("/shipment/update", newForm)
    .then((response) => {
      if (response.data.status === true) {
        toast.notify(local.ShipmentCancel, {
          position: "top-right",
          duration: 2000,
        });
        dispatch({
          type: UPDATE_SHIPMENTS_PROPS,
          payload: [{ prop: "isCnacelDelivery", value: false }],
        });
        history.push("/shipping/shipments");
      }
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_SHIPMENTS_PROPS,
        payload: [{ prop: "isCnacelDelivery", value: true }],
      });
      console.log(error);
    })
    .finally();
};

export const saveShipmentWithQrcode = (item, history, index) => async (
  dispatch
) => {
  let date = new Date();
  date.setDate(date.getDate() + 1);
  let cleintID = null;
  let due_date = new Date(item.delivery_date);
  due_date.setDate(due_date.getDate() + 1);

  let point = "POINT(" + item.location.lng + " " + item.location.lat + ")";

  let customerForm = {
    name: item.name,
    user: item.user,
    mail: item.mail,
    info: {
      name: item.name,
      // image: null,
      county: { id: 17, name: "Alnaseem" },
      state: { id: 13, name: "Riyadh" },
      country: { id: 174, name: "Saudi Arabia" },

      patient_id: item.shipmentCode,
      notes: "no notes",
      userName: item.userName,
    },
    phones: [item.Phone],
    addresses: [item.address],
    locations: [],
  };
  let clientFilter = {
    user: item.user,
    phones: [item.Phone],
  };

  customerForm.locations.push(point);

  Request()
    .post("/client/byphone", clientFilter)
    .then((response) => {
      if (response.data.status === true) {
        if (response.data.data.length > 0) {
          let shipmentForm = {
            due_date: new Date(due_date).toISOString(),
            address: item.address,
            phone: item.Phone,
            location: point,
            client: response.data.data[0].id,
            company: JSON.parse(localStorage.getItem(ShipmentSUSER)).id,
            user: item.user,
            info: {
              shipment_type: 0,
              clientName: item.name,
              userName: item.userName,
              Preferred_delivery_time: { from: "", to: "" },
              price: item.price,
              payment_status: 0,
              details: "no details",
              shipment_code: item.shipmentCode,
              confirmed: false,
            },
          };

          Request()
            .post("/shipment/new", shipmentForm)
            .then((result) => {
              if (result.data.status === true) {
                dispatch({
                  type: UPDATE_SHIPMENTS_PROPS,
                  payload: [
                    { prop: "qrData." + index + ".isSaved", value: true },
                  ],
                });
                console.log("scuuessed");
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally();
        } else {
          Request()
            .post("/client/new", customerForm)
            .then((res) => {
              if (res.data.status === true) {
                let filterCleint = {
                  user: item.user,
                  phones: [item.Phone],
                };
                Request()
                  .post("/client/byphone", filterCleint)
                  .then((re) => {
                    if (re.data.status === true) {
                      if (re.data.data.length > 0) {
                        let shipmentForm = {
                          due_date: new Date(due_date).toISOString(),
                          address: item.address,
                          phone: item.Phone,
                          location: point,
                          client: re.data.data[0].id,
                          company: JSON.parse(
                            localStorage.getItem(ShipmentSUSER)
                          ).id,
                          user: item.user,
                          info: {
                            shipment_type: 0,
                            clientName: item.name,
                            userName: item.userName,
                            Preferred_delivery_time: { from: "", to: "" },
                            price: item.price,
                            payment_status: 0,
                            details: "no details",
                            confirmed: false,
                            shipment_code: item.shipmentCode,
                          },
                        };

                        Request()
                          .post("/shipment/new", shipmentForm)
                          .then((result) => {
                            if (result.data.status === true) {
                              toast.notify(local.CleintSuccessed, {
                                position: "top-center",
                                duration: 5000,
                              });

                              dispatch({
                                type: UPDATE_SHIPMENTS_PROPS,
                                payload: [
                                  {
                                    prop: "qrData." + index + ".isSaved",
                                    value: true,
                                  },
                                ],
                              });
                              console.log("scuuessed");
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                          })
                          .finally();
                      }
                    }
                  });
              }
            });
        }
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally();
};

export const addNewClientWithShipment = (client, shipments, history) => (
  dispatch
) => {
  dispatch({
    type: UPDATE_SHIPMENTS_PROPS,
    payload: [
      {
        prop: "createModel.isLoading",
        value: true,
      },
    ],
  });
  Request()
    .post("/client/new", client)
    .then((response) => {
      if (response.data.status === true) {
        shipments.map((ship, index) => {
          ship.client = response.data.data;
          Request()
            .post("shipment/new", ship)
            .then((res) => {
              console.log(res);
              if (shipments.length - 1 === index) {
                dispatch({
                  type: UPDATE_SHIPMENTS_PROPS,
                  payload: [
                    {
                      prop: "createModel.isLoading",
                      value: false,
                    },
                  ],
                });
                history.push("/shipping/shipments");
              }
            });
        });
      } else {
        if (response.data.error.message !== null) {
          if (
            response.data.error.message[0].includes("clients_phones") &&
            response.data.error.message[0].includes("_phone_user_idx")
          ) {
            toast.notify(local.UniquePhone, {
              position: "top-right",
              duration: 2000,
            });
          }
          if (response.data.error.message[0].includes("_uname")) {
            toast.notify(local.UserNameExist, {
              position: "top-right",
              duration: 2000,
            });
          }

          if (
            response.data.error.message[0] ===
            "user name should not contain spaces"
          ) {
            toast.notify(local.CusNameValidation, {
              position: "top-right",
              duration: 2000,
            });
          } else {
            response.data.error.message.map((mess) => {
              if (
                !response.data.error.message[0].includes("_uname") &&
                !response.data.error.message[0].includes("clients_phones") &&
                !response.data.error.message[0].includes("_phone_user_idx")
              )
                toast.notify(mess, {
                  position: "top-right",
                  duration: 2000,
                });
            });
            dispatch({
              type: UPDATE_SHIPMENTS_PROPS,
              payload: [
                {
                  prop: "createModel.isLoading",
                  value: false,
                },
              ],
            });
          }
        }
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: UPDATE_SHIPMENTS_PROPS,
        payload: [
          {
            prop: "createModel.isLoading",
            value: false,
          },
        ],
      });
      history.push("/error");
    })
    .finally();
};

export const getCustoemrs = (shipments) => (dispatch) => {
  let date = new Date();
  date.setDate(date.getDate() + 1);

  if (shipments.length > 0) {
    shipments.map((shipment) => {
      let filter = {
        object: {
          id: shipment.client,
        },
        duration: {
          from: "2006-01-02T15:04:05Z",
          to: date.toISOString(),
        },
      };

      Request()
        .post("/client", filter)
        .then((response) => {
          if (response.data.data.items !== null) {
            let shipmentclientObject = {
              shipment: shipment,
              customer: response.data.data.items[0],
            };

            dispatch({
              type: MERGE_SHIPMENTS_PROPS,
              payload: [
                {
                  prop: "customerShipments",
                  value: shipmentclientObject,
                },
              ],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
};

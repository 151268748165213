import {
  UPDATE_CUSTOMERS_PROPS,
  MERGE_CUSTOMERS_PROPS,
  DELETE_CUSTOMERS_PROPS,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  rtable: { customers: [] },
  filter: {
    object: {},
    duration: {
      from: "2006-01-02T15:04:05Z",
      to: null,
    },
    page: 0,
  },
  search: { phone: "", user: null },
  newForm: {
    name: "",
    user: null,
    mail: "",
    info: {
      name: "",
      // image: null,
      county: { id: null, name: "" },
      state: { id: null, name: "" },
      country: { id: null, name: "" },
      patient_id: "",
      notes: "",
      userName: "",
    },
    phones: [],
    addresses: [],
    locations: [],
  },
  AdminCustomer: null,
  errorMessage: {
    errorName: "",
    errorMail: "",
    errorMobile: "",
    errorMobile2: "",
    errorAddress: [],
    errorLocation: "",
    errorMobile1: "",
    errorCountry: "",
    errorState: "",
    errorCounty: "",
    errorOrder: "",
    errorUser: "",
  },
  isLoading: false,
  isLoadingSearch: false,
  isSearchable: false,
  isLoadingSave: false,
  isSuccessed: true,
  isCreated: false,
  lat: "",
  lng: "",
  searchOptions: [],
  customerId: null,
  customerIndex: null,
  customerOptions: [],
  count: 0,
  isEditUserName: false,
  mapCodePoints: { code: "", lat: "", lng: "" },
  countryPhone: "",
  countryPhone1: "",
  countryPhone2: "",
  locationAddress: { isOpen: false, addressProp: "", address: "" },
  addModalOpen: false,
  smsData: { isOpen: false, phone: "", isLoading: false, isUpdate: false },
  fileData: {
    columns: [],
    data: [],
    myFile: null,
    isLoading: false,
    CitiesCounties: [],
  },
  customerShipments: [],

  urlLink: "",
  generateLink: {
    sms: "",
    link: "",
    isOpen: false,
    customer: null,
    isCopyText: false,
    isSendSms: false,
    isGetLink: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CUSTOMERS_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_CUSTOMERS_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_CUSTOMERS_PROPS: {
      return general.deleteProps(state, action);
    }
    default:
      return state;
  }
};

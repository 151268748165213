import React, { Component } from "react";
import CompanyLoginForm from "./CompanyLoginForm";
import { connect } from "react-redux";
import * as actions from "../../Redux/Actions/index";
import { UPDATE_LOGIN_PROPS } from "../../Redux/Actions/types";
import { USERTYPE } from "../../General/StaticKeys";
import { isAuthorized } from "../../General/GeneralFunctions";
class CompanyLogin extends Component {
  setUserType = () => {
    const { general, history } = this.props;
    if (isAuthorized() === false) {
      general(
        [{ prop: "userType", value: "Shipping Company" }],
        UPDATE_LOGIN_PROPS
      );
      localStorage.setItem(USERTYPE, "Shipping Company");
    } else {
      history.push("/subdealrs/dashboard");
    }
  };

  componentDidMount() {
    this.setUserType();
  }

  unsetForm = () => {
    const { general } = this.props;

    general(
      [
        { prop: "companyAuth", value: { name: "", password: "", role: 1 } },
        { prop: "userType", value: "" },
        { prop: "isViewCompanyPassword", value: "password" },

        {
          prop: "ErrorMessage",
          value: {
            ereorUserName: "",
            errorPassword: "",
          },
        },
      ],
      UPDATE_LOGIN_PROPS
    );
  };

  componentWillMount() {
    this.unsetForm();
  }

  render() {
    const {
      ErrorMessage,
      isLoading,
      general,
      history,
      login,
      companyAuth,
      isViewCompanyPassword,
    } = this.props;

    return (
      <CompanyLoginForm
        ErrorMessage={ErrorMessage}
        isLoading={isLoading}
        general={general}
        history={history}
        login={login}
        companyAuth={companyAuth}
        isViewCompanyPassword={isViewCompanyPassword}
      />
    );
  }
}

const mapStateToProps = ({ LoginR }) => {
  return {
    companyAuth: LoginR.companyAuth,
    isViewCompanyPassword: LoginR.isViewCompanyPassword,
    ErrorMessage: LoginR.ErrorMessage,
    isLoading: LoginR.isLoading,
    userType: LoginR.userType,
  };
};
export default connect(mapStateToProps, actions)(CompanyLogin);

import { RequestLocation } from "./Api";
import { HasArabicCharacters, isUrl } from "../../General/GeneralFunctions";
export const general = (payload, key) => {
  return { type: key, payload };
};

export const getQueryString = (obect) => {
  let query = "";

  Object.keys(obect).forEach(function (key, index) {
    if (index === 0) {
      query += "?";
    } else {
      query += "&";
    }
    query += key + "=" + obect[key];
  });
  // console.log(query)
  return query;
};

// export const MapFilter = (entry, action, prop) => (dispatch) => {
//   let url = '/gis/places'
//   let key = getQueryString(entry)
//   RequestLocation()
//     .get(url + key)
//     .then((response) => {
//       let locations = []
//       response.data.data.map((item) => {
//         locations.push({
//           label: item.properties.label,
//           value: item.properties.label,
//           lat: item.geometry.coordinates[1],
//           lng: item.geometry.coordinates[0],
//         })
//       })
//       dispatch({
//         type: action,
//         payload: [
//           {
//             prop: prop,
//             value: locations,
//           },
//         ],
//       })
//     })
//     .catch((error) => {
//       console.log(error)
//     })
//     .finally()
// }

// export const MapFilter = (query, action, prop, mapCodePoints) => (dispatch) => {
//   let lang = "ar";
//   if (HasArabicCharacters(query) === true) {
//     lang = "ar";
//   } else {
//     lang = "en";
//   }
//   let param = {
//     action: "search",
//     country: mapCodePoints.code,
//     deep: false,
//     key: "BfjKqWinHFJRapRSzeUfgDpOLwY0d0Tg",
//     lang: lang,
//     limit: 20,
//     layer: "region,locality",
//     point: {
//       type: "point",
//       coordinates: [Number(mapCodePoints.lng), Number(mapCodePoints.lat)],
//     },
//     query: query,

//     radius: 1000,
//   };

//   RequestLocation()
//     .post("/geocode", param)
//     .then((response) => {
//       let locations = [];
//       response.data.features.map((item) => {
//         if (
//           item.properties.street === undefined &&
//           item.properties.county !== undefined
//         ) {
//           if (HasArabicCharacters(query) === true) {
//             locations.push({
//               label:
//                 item.properties.country +
//                 "," +
//                 item.properties.region +
//                 "," +
//                 item.properties.county +
//                 "," +
//                 item.properties.name,
//               value: 1,

//               lat: item.geometry.coordinates[1],
//               lng: item.geometry.coordinates[0],
//             });
//           } else {
//             locations.push({
//               label:
//                 item.properties.name +
//                 "," +
//                 item.properties.county +
//                 "," +
//                 item.properties.region +
//                 "," +
//                 item.properties.country,
//               value: 1,
//               lat: item.geometry.coordinates[1],
//               lng: item.geometry.coordinates[0],
//             });
//           }
//         } else if (
//           item.properties.street !== undefined &&
//           item.properties.county === undefined
//         ) {
//           if (HasArabicCharacters(query) === true) {
//             locations.push({
//               label:
//                 item.properties.country +
//                 "," +
//                 item.properties.region +
//                 "," +
//                 item.properties.street +
//                 "," +
//                 item.properties.name,
//               value: 2,
//               lat: item.geometry.coordinates[1],
//               lng: item.geometry.coordinates[0],
//             });
//           } else {
//             locations.push({
//               label:
//                 item.properties.name +
//                 "," +
//                 item.properties.street +
//                 "," +
//                 item.properties.region +
//                 "," +
//                 item.properties.country,
//               value: 2,
//               lat: item.geometry.coordinates[1],
//               lng: item.geometry.coordinates[0],
//             });
//           }
//         } else if (
//           item.properties.street === undefined &&
//           item.properties.county === undefined
//         ) {
//           if (HasArabicCharacters(query) === true) {
//             locations.push({
//               label:
//                 item.properties.country +
//                 "," +
//                 item.properties.region +
//                 "," +
//                 item.properties.name,
//               value: 3,
//               lat: item.geometry.coordinates[1],
//               lng: item.geometry.coordinates[0],
//             });
//           } else {
//             locations.push({
//               label:
//                 item.properties.name +
//                 "," +
//                 item.properties.region +
//                 "," +
//                 item.properties.country,
//               value: 3,
//               lat: item.geometry.coordinates[1],
//               lng: item.geometry.coordinates[0],
//             });
//           }
//         } else {
//           if (HasArabicCharacters(query) === true) {
//             locations.push({
//               label:
//                 item.properties.country +
//                 "," +
//                 item.properties.region +
//                 "," +
//                 item.properties.county +
//                 "," +
//                 item.properties.street +
//                 "," +
//                 item.properties.name,
//               value: 4,
//               lat: item.geometry.coordinates[1],
//               lng: item.geometry.coordinates[0],
//             });
//           } else {
//             locations.push({
//               label:
//                 item.properties.name +
//                 "," +
//                 item.properties.street +
//                 "," +
//                 item.properties.county +
//                 "," +
//                 item.properties.region +
//                 "," +
//                 item.properties.country,
//               value: 4,
//               lat: item.geometry.coordinates[1],
//               lng: item.geometry.coordinates[0],
//             });
//           }
//         }
//       });

//       dispatch({
//         type: action,
//         payload: [
//           {
//             prop: prop,
//             value: locations,
//           },
//         ],
//       });
//     })
//     .catch((error) => {
//       console.log(error);
//     })
//     .finally();
// };
var socketMessage = new WebSocket(
  "wss://places.altawasol.app/socket/geocode/BfjKqWinHFJRapRSzeUfgDpOLwY0d0Tg"
);

socketMessage.addEventListener("open", function (event) {
  console.log("done connect to search");
});
export const MapFilter = (query, action, prop, mapCodePoints) => (dispatch) => {
  let lang = "ar";
  if (HasArabicCharacters(query) === true) {
    lang = "ar";
  } else {
    lang = "en";
  }
  let body = {
    country: mapCodePoints.code,
    deep: false,
    key: "BfjKqWinHFJRapRSzeUfgDpOLwY0d0Tg",
    lang: lang,
    limit: 20,
    point: {
      type: "point",
      coordinates: [Number(mapCodePoints.lng), Number(mapCodePoints.lat)],
    },
    query: query,

    radius: 1000,
  };
  socketMessage.send(JSON.stringify(body));
  socketMessage.addEventListener("message", function (event) {
    event.preventDefault();

    let entry = JSON.parse(event.data);
    // entry.features[0].geometry.coordinates[0] = lat;
    // entry.features[0].geometry.coordinates[1] = lng;

    let locations = [];
    entry.features.map((item) => {
      if (
        item.properties.street === undefined &&
        item.properties.county !== undefined
      ) {
        if (HasArabicCharacters(query) === true) {
          locations.push({
            label:
              item.properties.country +
              "," +
              item.properties.region +
              "," +
              item.properties.county +
              "," +
              item.properties.name,
            value: 1,

            lat: item.geometry.coordinates[1],
            lng: item.geometry.coordinates[0],
          });
        } else {
          locations.push({
            label:
              item.properties.name +
              "," +
              item.properties.county +
              "," +
              item.properties.region +
              "," +
              item.properties.country,
            value: 1,
            lat: item.geometry.coordinates[1],
            lng: item.geometry.coordinates[0],
          });
        }
      } else if (
        item.properties.street !== undefined &&
        item.properties.county === undefined
      ) {
        if (HasArabicCharacters(query) === true) {
          locations.push({
            label:
              item.properties.country +
              "," +
              item.properties.region +
              "," +
              item.properties.street +
              "," +
              item.properties.name,
            value: 2,
            lat: item.geometry.coordinates[1],
            lng: item.geometry.coordinates[0],
          });
        } else {
          locations.push({
            label:
              item.properties.name +
              "," +
              item.properties.street +
              "," +
              item.properties.region +
              "," +
              item.properties.country,
            value: 2,
            lat: item.geometry.coordinates[1],
            lng: item.geometry.coordinates[0],
          });
        }
      } else if (
        item.properties.street === undefined &&
        item.properties.county === undefined
      ) {
        if (HasArabicCharacters(query) === true) {
          locations.push({
            label:
              item.properties.country +
              "," +
              item.properties.region +
              "," +
              item.properties.name,
            value: 3,
            lat: item.geometry.coordinates[1],
            lng: item.geometry.coordinates[0],
          });
        } else {
          locations.push({
            label:
              item.properties.name +
              "," +
              item.properties.region +
              "," +
              item.properties.country,
            value: 3,
            lat: item.geometry.coordinates[1],
            lng: item.geometry.coordinates[0],
          });
        }
      } else {
        if (HasArabicCharacters(query) === true) {
          locations.push({
            label:
              item.properties.country +
              "," +
              item.properties.region +
              "," +
              item.properties.county +
              "," +
              item.properties.street +
              "," +
              item.properties.name,
            value: 4,
            lat: item.geometry.coordinates[1],
            lng: item.geometry.coordinates[0],
          });
        } else {
          locations.push({
            label:
              item.properties.name +
              "," +
              item.properties.street +
              "," +
              item.properties.county +
              "," +
              item.properties.region +
              "," +
              item.properties.country,
            value: 4,
            lat: item.geometry.coordinates[1],
            lng: item.geometry.coordinates[0],
          });
        }
      }
    });

    dispatch({
      type: action,
      payload: [
        {
          prop: prop,
          value: locations,
        },
      ],
    });
  });
};

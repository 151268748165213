import React, { Component } from "react";
import {
  checkisAuthorized,
  getUserType,
  logout,
} from "../../General/GeneralFunctions";
class Logout extends Component {
  componentDidMount() {
    console.log();
  }
  isLogin = () => {
    const { history } = this.props;
    if (checkisAuthorized() === false) {
      if (getUserType() === "Admin") {
        history.push("/admin/login");
      } else if (getUserType() === "Shipping Company") {
        history.push("/");
      } else {
        history.push("/customer/login");
      }
    } else {
      logout(history);
    }
  };
  render() {
    return <div>{this.isLogin()}</div>;
  }
}

export default Logout;

import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "react-phone-input-2/lib/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "flatpickr/dist/themes/airbnb.css";
import "toasted-notes/src/styles.css";
import { createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import reducers from "../src/Redux/Reducers/index";
import "./General/general.css";
import { changeLanguage } from "./Localization/local";
import { Provider } from "react-redux";
import "./General/battry.scss";
import "./General/battryCard.scss";
import "react-awesome-lightbox/build/style.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import AdminLogin from "./Components/AdminLogin/AdminLogin";
import CompanyLogin from "./Components/CompanyLogin/CompanyLogin";
import WebView from "./Components/WebView/WebView";
import CreateCustomer from "./Components/CustomerSmS/CreateCustomer";
import "react-circular-progressbar/dist/styles.css";
import Error from "./Components/ErrorPage";
import Logout from "./Components/Logout/Logout";
import "leaflet/dist/leaflet.css";
import "./App.scss";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

const store = createStore(
  reducers,
  {},
  compose(
    // To dispatch our calls asynchronously
    applyMiddleware(ReduxThunk)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        {changeLanguage()}
        <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => <CompanyLogin {...props} />}
              />
              <Route
                exact
                path="/admin/login"
                render={(props) => <AdminLogin {...props} />}
              />
              <Route
                path="/subdealrs"
                name="Home"
                render={(props) => <DefaultLayout {...props} />}
              />
              <Route
                path="/shipments/list"
                name="Agent"
                render={(props) => <WebView {...props} />}
              />
              <Route
                path="/client/add"
                name="Custoemr"
                render={(props) => <CreateCustomer {...props} />}
              />

              <Route
                path="/error"
                name="Error"
                render={(props) => <Error {...props} />}
              />
              <Route
                path="/logout"
                name="Logout"
                render={(props) => <Logout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;

import React, { Component } from "react";
import { Marker } from "react-leaflet";
import marker from "../../assets/img/brand/map.svg";
import L from "leaflet";
import GeneralMap from "../../General/GeneralMap";

class CustomerMap extends Component {
  renderMarker = () => {
    const { points } = this.props;
    const pointerIcon = new L.Icon({
      iconUrl: marker,
      iconAnchor: [5, 55],
      popupAnchor: [10, -44],
      iconSize: [35, 35],
      shadowSize: [68, 95],
      shadowAnchor: [20, 92],
    });
    if (points !== null) {
      if (points.length > 0) {
        return points.map((item, index) => {
          let lat = item.split("(")[1].split(" ")[1].split(")")[0];
          let lng = item.split("(")[1].split(" ")[0];
          return (
            <Marker
              position={[Number(lat), Number(lng)]}
              icon={pointerIcon}
              draggable={false}
              onClick={(marker) =>
                console.warn("marker-click", marker, marker.target.getLatLng())
              }
            >
              {/* <Popup>{markerName}</Popup> */}
            </Marker>
          );
        });
      }
    }
  };

  render() {
    const {
      mapStyles,
      lat,
      lng,
      lngProp,
      latProp,
      isClickable,
      action,
      general,
      points,
      propPoints,
      MapFilter,
      searchOptions,
      counterMap,
      mapCodePoints,
      urlLink,
    } = this.props;

    return (
      <GeneralMap
        mapStyles={mapStyles}
        renderMarker={this.renderMarker}
        renderPolyline={null}
        lat={lat}
        lng={lng}
        latProp={latProp}
        lngProp={lngProp}
        isClickable={isClickable}
        general={general}
        action={action}
        points={points}
        propPoints={propPoints}
        searchOptions={searchOptions}
        MapFilter={MapFilter}
        isCustomer={true}
        counterMap={counterMap}
        isResize={true}
        mapCodePoints={mapCodePoints}
        urlLink={urlLink}
      />
    );
  }
}

export default CustomerMap;

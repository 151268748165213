export const getDefaultItem = (id, Items) => {
  if (id !== null) {
    let item = Items.filter((item) => item.value === id || item.label === id);
    if (item.length > 0) {
      return item[0];
    }
  }
};

export const findDefaultItem = (id, Items) => {
  if (id !== null) {
    let item = Items.find((item) => item.value === id || item.label === id);
    if (item !== undefined) {
      return item;
    }
  }
};

export const filterItems = (Items) => {
  return Items.filter((i) => i.label.toLowerCase());
};

export const promiseOptions = (Items) =>
  new Promise((resolve) => {
    setTimeout(() => {
      return resolve(filterItems(Items));
    }, 1000);
  });

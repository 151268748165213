import {
  UPDATE_COMPANIES_PROPS,
  MERGE_COMPANIES_PROPS,
  DELETE_COMPANIES_PROPS,
} from "../Actions/types";
import * as general from "./methods";
import { getLanguage } from "../../Localization/local";

const INITIAL_STATE = {
  rtable: { companies: [] },

  filter: {
    object: {},
    duration: {
      from: "2006-01-02T15:04:05Z",
      to: null,
    },
    page: 0,
  },
  search: { location: null, country: null, city: null, phone: "" },
  newForm: {
    name: "",
    password: "",
    info: {
      name: "",
      image: null,
      country: { id: null, name: "" },
      state: { id: null, name: "" },
      countyName: "",
    },
    phone: "",
    contact_person: "",
    county: null,
    location: null,
    is_active: "",
  },

  errorMessage: {
    errorName: "",
    errorName2: "",
    errorPhone: "",
    errorPassword: "",
    errorConfirmPassword: "",
    errorCountry: "",
    errorCity: "",
    errorCounties: "",
    errorStatus: "",
    errorContactPerson: "",
    errorPoints: "",
    errorImage: "",
  },
  isLoading: false,
  isLoadingSearch: false,
  isSearchable: false,
  isLoadingSave: false,
  isSuccessed: true,
  companyId: null,
  isCreated: false,
  companyIndex: null,
  statusOptions: [
    { label: getLanguage() === "en" ? "Active" : "نشط", value: "1" },
    { label: getLanguage() === "en" ? "In Active" : "غير نشيط", value: "0" },
  ],
  lat: "",
  lng: "",
  searchOptions: [],
  compainesOptions: [],
  count: 0,
  countryPhone: "",
  mapCodePoints: { code: "", lat: "", lng: "" },
  confirmPassword: "",
  isViewPassword: "password",
  isViewConfirmPassword: "password",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_COMPANIES_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_COMPANIES_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_COMPANIES_PROPS: {
      return general.deleteProps(state, action);
    }
    default:
      return state;
  }
};

import React, { Component } from "react";
import {
  Card,
  Spinner,
  Input,
  Button,
  Label,
  Row,
  Col,
  Form,
  Container,
} from "reactstrap";
import { UPDATE_LOGIN_PROPS } from "../../Redux/Actions/types";
import { local, getLanguage } from "../../Localization/local";
import logo from "../../assets/img/brand/lutfiloc.svg";

import {
  loginCard,
  loginForm,
  labelInput,
  input,
  btn,
  logoLogin,
  generalBtnStyle,
} from "../../General/GeneralStyle";
import { FaEye, FaEyeSlash } from "react-icons/fa";

class CompanyLoginForm extends Component {
  validateInput = () => {
    const { general, companyAuth } = this.props;
    if (companyAuth.name === "") {
      general(
        [
          {
            prop: "ErrorMessage.ereorUserName",
            value: local.UserNameRequired,
          },
        ],
        UPDATE_LOGIN_PROPS
      );
    }

    if (companyAuth.password === "") {
      general(
        [
          {
            prop: "ErrorMessage.errorPassword",
            value: local.PasswordRequired,
          },
        ],
        UPDATE_LOGIN_PROPS
      );
    }

    if (companyAuth.name !== "" && companyAuth.password !== "") {
      return true;
    }
  };

  onLogin = (e) => {
    const { history, login, companyAuth } = this.props;
    e.preventDefault();

    if (this.validateInput() === true) {
      login(companyAuth, history, "shipping");
    }
  };
  render() {
    const {
      isLoading,
      companyAuth,
      general,
      ErrorMessage,
      isViewCompanyPassword,
    } = this.props;

    return (
      <Row
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          WebkitAlignItems: "center",
        }}
      >
        <Col lg={12}>
          <Card style={loginCard} className="login">
            <center>
              <center>
                <div style={logoLogin}>
                  <img src={logo} width="80" alt="img" />
                </div>
              </center>
              <Form style={loginForm} onSubmit={this.onLogin}>
                <Row>
                  <Col>
                    <Label style={labelInput}>{local.UserName}</Label>
                    <Input
                      style={input}
                      type="text"
                      placeholder={local.UserName}
                      value={companyAuth.name}
                      onChange={(e) =>
                        general(
                          [
                            {
                              prop: "companyAuth.name",
                              value: e.target.value,
                            },
                          ],
                          UPDATE_LOGIN_PROPS
                        )
                      }
                    />
                    {companyAuth.name === "" ? (
                      <span style={{ color: "white" }}>
                        {ErrorMessage.ereorUserName}
                      </span>
                    ) : null}
                  </Col>
                </Row>

                <Row style={{ marginTop: "10%" }}>
                  <Col>
                    <Label style={labelInput}>{local.Password}</Label>
                    <div
                      style={{
                        float: getLanguage() === "ar" ? "left" : "right",
                      }}
                    >
                      {isViewCompanyPassword === "text" ? (
                        <Button
                          style={generalBtnStyle}
                          onClick={(e) =>
                            general(
                              [
                                {
                                  prop: "isViewCompanyPassword",
                                  value: "password",
                                },
                              ],
                              UPDATE_LOGIN_PROPS
                            )
                          }
                        >
                          <FaEye style={{ fontSize: "18px" }} />
                        </Button>
                      ) : (
                        <Button
                          style={generalBtnStyle}
                          onClick={(e) =>
                            general(
                              [
                                {
                                  prop: "isViewCompanyPassword",
                                  value: "text",
                                },
                              ],
                              UPDATE_LOGIN_PROPS
                            )
                          }
                        >
                          <FaEyeSlash style={{ fontSize: "18px" }} />
                        </Button>
                      )}
                    </div>
                    <Input
                      style={input}
                      type={isViewCompanyPassword}
                      placeholder={local.Password}
                      value={companyAuth.password}
                      onChange={(e) =>
                        general(
                          [
                            {
                              prop: "companyAuth.password",
                              value: e.target.value,
                            },
                          ],
                          UPDATE_LOGIN_PROPS
                        )
                      }
                    />

                    {companyAuth.password === "" ? (
                      <span style={{ color: "white" }}>
                        {ErrorMessage.errorPassword}
                      </span>
                    ) : null}
                  </Col>
                </Row>

                <center>
                  <Row style={{ marginTop: "10%" }}>
                    <Col>
                      <Button
                        disabled={isLoading}
                        type="submit"
                        className="btn-sc"
                        style={btn}
                      >
                        {isLoading === true ? (
                          <Spinner color="light" />
                        ) : (
                          local.SignIn
                        )}
                      </Button>
                    </Col>
                  </Row>
                </center>
              </Form>
            </center>
          </Card>
          <br />
          <center>
            <p
              style={{
                color: "#1d6aab",
                fontFamily: "cursive",
                fontSize: "17px",
              }}
            >
              {local.PowerBy}
            </p>
          </center>
        </Col>
      </Row>
    );
  }
}

export default CompanyLoginForm;

import { Request } from "./Api";
import { UPDATE_COUNTIES_PROPS, MERGE_COUNTIES_PROPS } from "./types";
import { checkisAuthorized } from "../../General/GeneralFunctions";
import toast from "toasted-notes";
import { local, getLanguage } from "../../Localization/local";
export const fetchCounties = (history, filter) => async (dispatch) => {
  if (checkisAuthorized() === false) {
    history.push("/admin/login");
  } else {
    dispatch({
      type: UPDATE_COUNTIES_PROPS,
      payload: [{ prop: "rtable.counties", value: [] }],
    });
    if (filter.object.state === undefined) {
      dispatch({
        type: UPDATE_COUNTIES_PROPS,
        payload: [
          { prop: "isLoading", value: true },
          { prop: "isSearchable", value: false },
          { prop: "isCreated", value: false },
        ],
      });
    } else {
      dispatch({
        type: UPDATE_COUNTIES_PROPS,
        payload: [
          { prop: "isLoadingSearch", value: true },
          { prop: "isSearchable", value: true },
          { prop: "isCreated", value: false },
        ],
      });
    }

    Request()
      .post("/counties", filter)
      .then((response) => {
        if (response.data.status === true) {
          if (response.data.data.items.Counties !== null) {
            if (filter.object.state !== undefined) {
              dispatch({
                type: UPDATE_COUNTIES_PROPS,
                payload: [{ prop: "rtable.counties", value: [] }],
              });
            }
            response.data.data.items.Counties.map(async (area) => {
              dispatch(await gertCity(area, response.data.data.items.Counties));
            });
            dispatch({
              type: UPDATE_COUNTIES_PROPS,
              payload: [
                {
                  prop: "count",
                  value: response.data.data.pages,
                },
              ],
            });
          } else {
            if (filter.object.state !== undefined) {
              toast.notify(local.SearchResult, {
                position: "top-right",
                duration: 2000,
              });
            }
            dispatch({
              type: UPDATE_COUNTIES_PROPS,
              payload: [
                { prop: "rtable.counties", value: [] },
                { prop: "isLoading", value: false },
                { prop: "isLoadingSearch", value: false },
              ],
            });
          }
        } else {
          dispatch({
            type: UPDATE_COUNTIES_PROPS,
            payload: [
              { prop: "isLoading", value: false },
              { prop: "isLoadingSearch", value: false },
            ],
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_COUNTIES_PROPS,
          payload: [
            { prop: "isLoading", value: false },
            { prop: "isLoadingSearch", value: false },
          ],
        });
        history.push("/error");

        console.log(error);
      })
      .finally();
  }
};

export const gertCity = (area, items) => async (dispatch) => {
  let filter = {
    object: { id: area.state },
    duration: {
      from: "2006-01-02T15:04:05Z",
      to: null,
    },
  };

  await Request()
    .post("/states", filter)
    .then((response) => {
      if (response.data.status === true) {
        if (response.data.data.items.States !== null) {
          area.city = response.data.data.items.States[0];
          dispatch({
            type: MERGE_COUNTIES_PROPS,
            payload: [
              {
                prop: "rtable.counties",
                value: area,
              },
            ],
          });
        }
      }
      items.map((item, index) => {
        if (items.length - 1 === index) {
          dispatch({
            type: UPDATE_COUNTIES_PROPS,
            payload: [
              { prop: "isLoading", value: false },
              { prop: "isLoadingSearch", value: false },
            ],
          });
        }
      });
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_COUNTIES_PROPS,
        payload: [
          { prop: "isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
      console.log(error);
    })
    .finally();
};

export const CreateuOrUpdateCounty = (newForm, history, index) => (
  dispatch
) => {
  dispatch({
    type: UPDATE_COUNTIES_PROPS,
    payload: [
      { prop: "isLoadingSave", value: true },
      { prop: "isCreated", value: true },
    ],
  });

  let url = null;
  if (newForm.id === undefined) {
    url = "/counties/new";
  } else {
    url = "/counties/update";
  }

  Request()
    .post(url, newForm)
    .then((response) => {
      if (response.data.status === true) {
        history.push("/subdealrs/counties");
        if (newForm.id === undefined) {
          toast.notify(local.Successed, {
            position: "top-right",
            duration: 2000,
          });
        } else {
          dispatch({
            type: UPDATE_COUNTIES_PROPS,
            payload: [
              {
                prop: "rtable.counties." + index,
                value: newForm,
              },
            ],
          });

          toast.notify(local.Edited, {
            position: "top-right",
            duration: 2000,
          });
        }
      }
      //  else {
      //   if (
      //     response.data.error.message !== null &&
      //     response.data.error.message[0] ===
      //       'pq: duplicate key value violates unique constraint "company_phone"'
      //   ) {
      //     toast(local.UniquePhone)
      //   }
      // }
      dispatch({
        type: UPDATE_COUNTIES_PROPS,
        payload: [{ prop: "isLoadingSave", value: false }],
      });
    })

    .catch((error) => {
      dispatch({
        type: UPDATE_COUNTIES_PROPS,
        payload: [{ prop: "isLoadingSave", value: false }],
      });
    })
    .finally();
};

export const getCountiesForDropDown = (filter) => (dispatch) => {
  Request()
    .post("/counties", filter)
    .then((response) => {
      // if (response.data.status === true) {
      if (response.data.data.items.Counties !== null) {
        let counties = [];
        response.data.data.items.Counties.map((county) => {
          if (filter.object.ar_name !== "" && filter.object.en_name === "") {
            counties.push({
              label: county.ar_name,
              value: county.id,
              state: county.state,
            });
          } else if (
            filter.object.en_name !== "" &&
            filter.object.ar_name === ""
          ) {
            counties.push({
              label: county.en_name,
              value: county.id,
              state: county.state,
            });
          } else {
            if (getLanguage() === "en") {
              counties.push({
                label: county.en_name,
                value: county.id,
                state: county.state,
              });
            } else {
              counties.push({
                label: county.ar_name,
                value: county.id,
                state: county.state,
              });
            }
          }
        });
        dispatch({
          type: UPDATE_COUNTIES_PROPS,
          payload: [{ prop: "countiesOptions", value: counties }],
        });
      } else {
        dispatch({
          type: UPDATE_COUNTIES_PROPS,
          payload: [{ prop: "countiesOptions", value: [] }],
        });
      }
      // }
    })
    .catch((error) => {
      toast.notify(local.InternetConnection, {
        position: "top-right",
        duration: 2000,
      });
      console.log(error);
    })
    .finally();
};

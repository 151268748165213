import {
  UPDATE_COUNTRY_PROPS,
  MERGE_COUNTRY_PROPS,
  DELETE_COUNTRY_PROPS,
} from '../Actions/types'
import * as general from './methods'

const INITIAL_STATE = {
  countriesOptions: [],
  allCountriesOptions: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_COUNTRY_PROPS: {
      return general.updateProps(state, action)
    }
    case MERGE_COUNTRY_PROPS: {
      return general.mergeProps(state, action)
    }
    case DELETE_COUNTRY_PROPS: {
      return general.deleteProps(state, action)
    }
    default:
      return state
  }
}

import { getLanguage } from "../Localization/local";

export const loginCard = {
  backgroundColor: "#1d6aab",
  borderRadius: "20px",
  marginRight: "25%",
  marginLeft: "25%",
  border: "none",
};

export const logoLogin = {
  width: "60px",
  height: "55px",
  borderRadius: 90,
  marginTop: "6%",
};

export const loginForm = {
  marginTop: "5%",
  marginBottom: "5%",
  marginRight: "15%",
  marginLeft: "15%",
};

export const labelInput = {
  color: "white",
  float: getLanguage() === "ar" ? "right" : "left",
  // fontWeight: 'bold',
  marginBottom: "1%",
};

export const labelInputModal = {
  color: "#1d6aab",
  float: getLanguage() === "ar" ? "right" : "left",
  // fontWeight: 'bold',
};

export const input = {
  color: "white",
  fontSize: "13px",
  fontFamily: "Titillium Web",
  fontWeight: "bold",
  AlignItems: "center",
  BoxAlign: "center",
  msFlexAlign: "center",
  alignItems: "center",
  borderRadius: "4px",
  borderStyle: "solid",
  borderWidth: "1px",
  FlexWrap: "wrap",
  msFlexWrap: "wrap",
  BoxPack: "justify",
  JustifyContent: "space-between",
  msFlexPack: "justify",
  justifyContent: "space-between",
  minHeight: "38px",
  outline: "0 !important",
  position: "relative",
  Transition: "all 100ms",
  transition: "all 100ms",
  boxSizing: "border-box",
  backgroundColor: "transparent",
};

export const inputHilight = {
  color: "#1d6aab",
  fontSize: "13px",
  fontFamily: "Titillium Web",
  fontWeight: "bold",
  AlignItems: "center",
  BoxAlign: "center",
  msFlexAlign: "center",
  alignItems: "center",
  borderRadius: "4px",
  borderStyle: "solid",
  borderWidth: "1px",
  FlexWrap: "wrap",
  msFlexWrap: "wrap",
  BoxPack: "justify",
  JustifyContent: "space-between",
  msFlexPack: "justify",
  justifyContent: "space-between",
  minHeight: "38px",
  outline: "0 !important",
  position: "relative",
  Transition: "all 100ms",
  transition: "all 100ms",
  boxSizing: "border-box",
  backgroundColor: "white",
};

export const btn = {
  width: "106px",
  height: "40px",
  fontSize: "13px",
  fontFamily: "Titillium Web",
  fontWeight: "bold",
  AlignItems: "center",
  BoxAlign: "center",
  msFlexAlign: "center",
  alignItems: "center",
  borderRadius: "4px",
  borderStyle: "solid",
  borderWidth: "1px",
  FlexWrap: "wrap",
  msFlexWrap: "wrap",
  flexWrap: "wrap",
  BoxPack: "justify",
  JustifyContent: "space-between",
  msFlexPack: "justify",
  Transition: "all 100ms",
  transition: "all 100ms",
  boxSizing: "border-box",
};

export const continerCardHome = {
  width: "133px",
  height: "148px",
  backgroundColor: "white",
  alignItems: "center",
  border: "none",
};

export const userCardHome = {
  width: "133px",
  height: "148px",
  backgroundColor: "#1d6aab",
  alignItems: "center",
  border: "none",
};

export const cardViewStyle = {
  backgroundColor: "#1d6aab",
  border: "none",
  width: "80%",
};

export const CardHome = {
  width: "133px",
  height: "126px",
  backgroundColor: "linearGradient(#FA6E3F,#FAB116)",
  borderRadius: "20px",
  alignItems: "center",
  border: "none",
};

export const CardFeedback = {
  width: "133px",
  height: "126px",
  borderRadius: "20px",
  alignItems: "center",
  border: "none",
  backgroundColor: "#1d6aab",
};

export const CardHome2 = {
  width: "150px",
  height: "140px",
  backgroundColor: "linearGradient(#FA6E3F,#FAB116)",

  borderRadius: "20px",
  alignItems: "center",
  border: "none",
};

export const order = {
  position: "absolute",
  backgroundColor: "#4EB862",
  color: "white",
  width: "32px",
  height: "32px",
  borderRadius: "18px",
  padding: 11,
  fontWeight: "bold",
  marginTop: "86%",
};

export const order2 = {
  position: "absolute",
  marginTop: "84%",
  backgroundColor: "#4EB862",
  color: "white",
  width: "32px",
  height: "32px",
  borderRadius: "18px",
  fontWeight: "bold",
};

export const order3 = {
  position: "absolute",
  marginTop: "83%",
  backgroundColor: "white",
  border: "2px solid #0078de",
  color: "#0078de",
  width: "32px",
  height: "32px",
  borderRadius: "18px",
  padding: getLanguage() === "ar" ? 7 : 7.5,
  fontWeight: "bold",
  fontSize: getLanguage() === "ar" ? "20px" : "15px",
};
export const order6 = {
  position: "absolute",
  marginTop: "83%",
  backgroundColor: "white",
  color: "black",
  width: "32px",
  height: "32px",
  borderRadius: "18px",
  padding: getLanguage() === "ar" ? 7 : 7.5,
  fontWeight: "bold",
  fontSize: getLanguage() === "ar" ? "20px" : "15px",
};

export const order4 = {
  position: "absolute",
  backgroundColor: "#4EB862",
  color: "white",
  width: "32px",
  height: "32px",
  borderRadius: "18px",
  padding: 12,
  fontWeight: "bold",
  marginTop: "50%",
};

export const imageCard = {
  width: "70px",
  height: "65px",
  marginTop: 10,
  marginBottom: 10,
  display: "inline",
};

export const imageCard2 = {
  width: "54px",
  height: "50px",
  marginTop: 2,
  marginBottom: 10,
  display: "inline",
};

export const clientName = { fontWeight: "bold", color: "white" };

export const search = {
  width: "160%",
  height: "35px",
  borderColor: "#FA723C",
  fontWeight: "bold",
  color: "#FA723C",
  backgroundColor: "#000000",
  borderRadius: "7px",
  borderWidth: "1px",
  maskBorderMode: "alpha",
  zIndex: 200,
};

export const search2 = {
  width: "160%",
  height: "35px",
  borderColor: "#808080	",
  fontWeight: "bold",
  color: "white",
  backgroundColor: "#808080	",
  borderRadius: "7px",
  borderWidth: "1px",
  maskBorderMode: "alpha",
  zIndex: 200,
};

export const trackingCard = {
  backgroundColor: "transparent	",
  border: 0,
};

export const cardDescMap = {
  // borderRadius: "10px",
  marginLeft: getLanguage() === "en" ? "20%" : null,
  marginRight: getLanguage() === "ar" ? "20%" : null,
  width: "80%",
  maxWidth: "88%",
  height: "24%",
  maxHeight: "24%",
  border: 0,
  alignItems: "center",
  backgroundColor: "rgb(44, 111, 168)",
  border: "1px solid white",
};

export const selectStyle = {
  option: (provided) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: "#3b3b3b",
    padding: 10,
    borderRadius: "10px",
    textAlign: "center",
  }),
  singleValue: (provided) => {
    const color = "white";

    return { ...provided, color };
  },
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#ffffff",
    };
  },
};

export const cardDescMap1 = {
  borderRadius: "10px",
  marginLeft: getLanguage() === "en" ? "20%" : null,
  marginRight: getLanguage() === "ar" ? "20%" : null,
  width: "80%",
  maxWidth: "88%",
  height: "20%",
  maxHeight: "20%",
  border: 0,
  alignItems: "center",
};

export const cardDiv = {
  width: "100%",
  height: "100%",
  position: "relative",
  color: "white",
  borderRadius: "10px",
};

export const cardtitle = {
  display: "inline",
  color: "white",
  fontWeight: "bold",
  fontSize: "12px",
  fontFamily: "Roboto",
};

export const imageTrackingCard = {
  width: "57px",
  maxWidth: "57px",
  height: "57px",
  maxHeight: "57px",
  marginLeft: 10,
  display: "inline",
};

export const battryStyle = {
  width: "18px",
  maxWidth: "18px",
  height: "33px",
  maxHeight: "33px",
  marginLeft: "-190%",
};

export const battryStyle1 = {
  width: "20px",
  maxWidth: "20px",
  height: "34px",
  maxHeight: "34px",
};

export const battryStyle3 = {
  width: "18px",
  maxWidth: "18px",
  height: "33px",
  maxHeight: "33px",
  marginLeft: getLanguage() === "ar" ? "50%" : null,
};

export const battryStyle2 = {
  width: "19.76px",
  maxWidth: "19.19px",
  height: "27px",
  maxHeight: "27pxpx",
  // marginLeft: '',
};

export const markerStyle = {
  width: "23.22px",
  maxWidth: "23.22px",
  height: "33.54px",
  maxHeight: "33.54px",
  marginLeft: getLanguage() === "en" ? "-8%" : null,
  marginRight: getLanguage() === "ar" ? "5%" : null,
};

export const markerStyle2 = {
  width: "16px",
  maxWidth: "16px",
  height: "37px",
  maxHeight: "37px",
  marginLeft: getLanguage() === "en" ? "1%" : null,
  marginRight: getLanguage() === "ar" ? "1.4%" : null,

  marginTop: "-4%",
};
export const battryDiv = {
  position: "absolute",
  width: "12.09px",
  height: "3.72px",
  maxWidth: "12.09px",
  maxHeight: "12.09px",
  marginTop: "-55%",
  marginLeft: getLanguage() === "en" ? "-65.7%" : null,
  marginRight: getLanguage() === "ar" ? "7%" : null,
};

export const battryDivb = {
  position: "absolute",
  width: "12.09px",
  height: "3.72px",
  maxWidth: "12.09px",
  maxHeight: "12.09px",
  marginTop: "-52%",
  marginLeft: getLanguage() === "en" ? "4.0641%" : null,
  marginRight: getLanguage() === "ar" ? "3.2px" : null,
};

export const battryDivone = {
  position: "absolute",
  width: "12.09px",
  height: "3.72px",
  maxWidth: "12.09px",
  maxHeight: "12.09px",
  marginTop: "-15%",
  marginLeft: getLanguage() === "en" ? "-65.7%" : null,
  marginRight: getLanguage() === "ar" ? "7%" : null,
};

export const battryDivone1 = {
  position: "absolute",
  width: "12.09px",
  height: "3.72px",
  maxWidth: "12.09px",
  maxHeight: "12.09px",
  marginTop: "-17%",
  marginLeft: getLanguage() === "en" ? "4%" : null,
  marginRight: getLanguage() === "ar" ? "3.2px" : null,
};

export const battryDivtwo = {
  position: "absolute",
  width: "20%",
  height: "3.72px",
  maxWidth: "20%",
  maxHeight: "12.09px",
  marginTop: "-27%",
  marginLeft: getLanguage() === "en" ? "-65.7%" : null,
  marginRight: getLanguage() === "ar" ? "7%" : null,
};

export const battryDivtwo1 = {
  position: "absolute",
  width: "12.09px",
  height: "3.72px",
  maxWidth: "12.09px",
  maxHeight: "12.09px",
  marginTop: "-25%",
  marginLeft: getLanguage() === "en" ? "4%" : null,
  marginRight: getLanguage() === "ar" ? "3.2px" : null,
};

export const battryDivthree = {
  position: "absolute",
  width: "12.09px",
  height: "3.72px",
  maxWidth: "12.09px",
  maxHeight: "12.09px",
  marginTop: "-35%",
  marginLeft: getLanguage() === "en" ? "-65.7%" : null,
  marginRight: getLanguage() === "ar" ? "7%" : null,
};

export const battryDivthree1 = {
  position: "absolute",
  width: "12.09px",
  height: "3.72px",
  maxWidth: "12.09px",
  maxHeight: "12.09px",
  marginTop: "-35%",
  marginLeft: getLanguage() === "en" ? "4%" : null,
  marginRight: getLanguage() === "ar" ? "3.2px" : null,
};

export const battryDivtfour = {
  position: "absolute",
  width: "12.09px",
  height: "3.72px",
  maxWidth: "12.09px",
  maxHeight: "12.09px",
  marginTop: "-45%",
  marginLeft: getLanguage() === "en" ? "-65.7%" : null,
  marginRight: getLanguage() === "ar" ? "7%" : null,
};

export const battryDivtfour1 = {
  position: "absolute",
  width: "12.09px",
  height: "3.72px",
  maxWidth: "12.09px",
  maxHeight: "12.09px",
  marginTop: getLanguage() === "en" ? "-45%" : "-45%",
  marginLeft: getLanguage() === "en" ? "4.2%" : null,
  marginRight: getLanguage() === "ar" ? "3.2px" : null,
};

export const battryDiv2 = {
  position: "absolute",
  width: "8px",
  height: "3.72px",
  maxWidth: "12.09px",
  maxHeight: "12.09px",
  marginTop: "-35%",
  marginLeft: getLanguage() === "en" ? "4.5%" : null,
  marginRight: getLanguage() === "ar" ? "5.5%" : null,
};

export const battryDiv3 = {
  position: "relative",
  width: "12.09px",
  height: "5px",
  maxWidth: "12.09px",
  maxHeight: "5px",
  marginTop: "-45%",
  direction: "ltr",
  marginLeft: getLanguage() === "ar" ? "55%" : "14%",
};

export const battryDiv3one = {
  position: "relative",
  width: "12.09px",
  height: "5px",
  maxWidth: "12.09px",
  maxHeight: "5px",
  marginTop: "-14%",
  direction: "ltr",
  marginLeft: getLanguage() === "ar" ? "55%" : "4.85%",
};

export const battryDiv3two = {
  position: "relative",
  width: "12.09px",
  height: "5px",
  maxWidth: "12.09px",
  maxHeight: "5px",
  marginTop: "-20%",
  direction: "ltr",
  marginLeft: getLanguage() === "ar" ? "55%" : "4.85%",
};

export const battryDiv3three = {
  position: "relative",
  width: "12.09px",
  height: "5px",
  maxWidth: "12.09px",
  maxHeight: "5px",
  marginTop: "-27%",
  direction: "ltr",
  marginLeft: getLanguage() === "ar" ? "55%" : "4.85%",
};

export const battryDiv3four = {
  position: "relative",
  width: "12.09px",
  height: "5px",
  maxWidth: "12.09px",
  maxHeight: "5px",
  marginTop: "-34%",
  direction: "ltr",
  marginLeft: getLanguage() === "ar" ? "55%" : "4.85%",
};

export const cardBattry = {
  // width: '11px',
  height: "4.5px",
  // margin: 0,
  backgroundColor: "white",
  border: 0,
  borderRadius: 0,
};

export const cardBattry1 = {
  width: "14px",
  height: "3px",
  margin: 0,
  backgroundColor: "white",
  border: 0,
  borderRadius: 0,
};

export const cardBattry2 = {
  // width: '11px',
  height: "4.6px",
  // margin: 0,
  backgroundColor: "white",
  border: 0,
  borderRadius: 0,
  marginBottom: 1.5,
};

export const cardBattry21 = {
  width: "14px",
  height: "3px",
  margin: 0,
  marginBottom: 2,
  backgroundColor: "white",
  border: 0,
  borderRadius: 0,
};

export const cardBattry11 = {
  width: "14px",
  height: "4pxpx",
  margin: 0,
  backgroundColor: "white",
  border: 0,
  borderRadius: 0,
};

export const cardBattry22 = {
  width: "14px",
  height: "4px",
  margin: 0,
  backgroundColor: "white",
  border: 0,
  borderRadius: 0,
  marginBottom: 1,
};

export const nameTrackingStyle = {
  fontSize: "13px",
  fontFamily: "Titillium Web",
  width: "20%",
  marginRight: getLanguage() === "en" ? "5%" : "5%",
  marginLeft: getLanguage() === "en" ? 8 : 20,
  fontWeight: "bold",
  marginTop: "4%",
};

export const nameTrackingStyle2 = {
  fontWeight: "bold",
  fontSize: "10px",
  color: "white",
  fontStyle: "italic",
  width: "50%",
};

export const mobilePhone = {
  fontFamily: "Titillium Web",
  textAlign: "center",
  fontSize: "13px",
};

export const mobilePhone2 = {
  marginTop: "-3%",
  fontFamily: "Titillium Web",
  fontWeight: "bold",
  textAlign: "center",
  fontSize: "10px",
  color: "white",
};

export const agentCreateCard = {
  backgroundColor: "#1d6aab",
  borderColor: "#808080		",
  width: "80%",
  borderRadius: "10px",
};
export const custoemrCreateCard = {
  width: "100%",
  borderRadius: "10px",
  backgroundColor: "#1d6aab",
  border: "none",
};

export const custoemrSmsCreateCard = {
  backgroundColor: "#1d6aab",
  border: "none",
  webkitTransform: "translateY(0.25rem)",
  msTransform: "translateY(0.25rem)",
  transform: "translateY(0.25rem)",
  mozTransition: "opacity 0.325s ease-in-out",
  webkitTransition: "opacity 0.325s ease-in-out",
  webkitTransform: "0.325s ease -in -out",
  msTransition: "opacity 0.325s ease-in-out",
  transition: "opacity 0.325s ease-in-out",
  padding: "4.5rem 2.5rem 1.5rem 2.5rem",
  position: "relative",
  maxWidth: "100%",
  borderRadius: "4px",
};

export const custoemrSms1CreateCard = {
  width: "70%",
  borderRadius: "10px",
  backgroundColor: "#1d6aab",
  border: "none",
  webkitTransform: "translateY(0.25rem)",
  msTransform: "translateY(0.25rem)",
  transform: "translateY(0.25rem)",
  mozTransition: "opacity 0.325s ease-in-out",
  webkitTransition: "opacity 0.325s ease-in-out",
  webkitTransform: "0.325s ease -in -out",
  msTransition: "opacity 0.325s ease-in-out",
  transition: "opacity 0.325s ease-in-out",
  padding: "4.5rem 2.5rem 1.5rem 2.5rem",
  position: "relative",
  maxWidth: "100%",
  borderRadius: "4px",
};

export const agentForm = {
  marginLeft: "10%",
  marginRight: "10%",
  marginTop: "5%",
  marginBottom: "5%",
};

export const customerForm = {
  marginLeft: "10%",
  marginRight: "10%",
  marginTop: "5%",
  marginBottom: "5%",
};

export const searchAgentCard = {
  backgroundColor: "#1d6aab",
  border: "none",
};

export const searchFormAgent = {
  marginLeft: "3%",
  marginTop: "2%",
  marginRight: "3%",
  marginBottom: "2%",
};

export const searchFormCustomer = {
  marginLeft: "3%",
  marginRight: "3%",
  marginBottom: "2%",
  marginTop: "2%",
};

export const searckKeyword = {
  fontWeight: "bold",
  fontSize: "15px",
  color: "white",
  fontFamily: "Roboto",
};

export const agentCardList = {
  height: "100%",
  backgroundColor: "#1d6aab",
  border: "none",
  fontFamily: "Titillium Web",
};

export const trackingCardList = {
  height: "403px",
  backgroundColor: "#2c6fa8",
  border: "none",
  borderRadius: 5,
  fontFamily: "Titillium Web",
  width: "110%",
  overflowY: "scroll",
  zIndex: "1000",
};

export const trackingCardHistory = {
  height: "403px",
  backgroundColor: "#2c6fa8",
  border: "none",
  borderRadius: 5,
  fontFamily: "Titillium Web",
  width: "110%",
  overflowY: "scroll",
  // zIndex: 100,
};

export const trackingCardSearch = {
  backgroundColor: "#2c6fa8",
  border: "none",
  borderRadius: 5,
  fontFamily: "Titillium Web",
  width: "110%",
  // zIndex: 100,
};

export const editIcon = { backgroundColor: "transparent", border: 0 };

export const Edicon = { color: "white", fontSize: "20px" };

export const imgUplaod = {
  backgroundColor: "white",
  border: "none",
  width: "75%",
  height: "90%",
  alignItems: "center",
  boxShadow: "0px 2px 2px rgb(66, 66, 66)",
};

export const imgUplaod1 = {
  backgroundColor: "white",
  border: "1px solid white",
  height: "82%",
};

export const imgUplaod2 = {
  border: "1px solid white",
  width: "65%",
  height: "90%",
};

export const innerImgDiv = {
  width: "100%",
  height: "100%",
  display: "flex",
  webkitAlignItems: "center",
  cursor: "pointer",
};

export const customerImgUplaod = {
  backgroundColor: "#808080		",
  border: "2px dashed white",
  width: "250px",
  height: "220px",
  alignItems: "center",
};

export const iamgeCopy = {
  height: "38px",
  border: 0,
};

export const imgText = {
  color: "#0078de",
  fontWeight: "500px",
  fontSize: "19px",
  fontFamily: "Roboto",
};

export const imageIcon = {
  width: "72px",
  color: "white",
};

export const iamgeCopyThmb = {
  marginTop: "0.5%",
  color: "white",
  fontSize: "13px",
  fontFamily: "Roboto",
};

export const Agentbtn = {
  width: "100px",
  height: "40px",
  fontSize: "15px",
  fontFamily: "Titillium Web",
  fontWeight: "bold",
  webkitAlignItems: "center",
  webkitBoxAlign: "center",
  msFlexAlign: "center",
  alignItems: "center",
  borderRadius: "4px",
  borderStyle: "solid",
  borderWidth: "1px",
  webkitFlexWrap: "wrap",
  msFlexWrap: "wrap",
  flexWrap: "wrap",
  webkitBoxPack: "justify",
  webkitJustifyContent: "space-between",
  msFlexPack: "justify",
  WebkitTransition: "all 100ms",
  transition: "all 100ms",
  boxSizing: "border-box",
  borderColor: "white",
  opacity: 1,
};

export const mapCard = {
  borderColor: "#808080	",
  backgroundColor: "#808080	",
  borderRadius: "10px",
  marginTop: "4%",
};

export const mapCardLine = {
  borderColor: "#808080	",
  borderRadius: "10px",
  float: "center",
};

export const cardItems = {
  backgroundColor: "#1d6aab",
  height: "130.42px",
  borderRadius: 20,
};

export const colPhotoCopy = {
  marginTop: "0.5%",
  color: "white",
  fontSize: "13px",
  fontFamily: "Roboto",
};

export const notifiyText = {
  marginRight: "-5%",
  fontFamily: "Roboto",
  fontSize: "15px",
  color: "white",
  width: "120%",
};

export const notifyInput = {
  width: "50px",
  height: "27px",
  fontWeight: "bodl",
};

export const dimenstion = {
  color: "#232F53",
  position: "relative",
  marginTop: "-19%",
  marginLeft: "21%",
  zIndex: 4,
  fontFamily: "Roboto",
  fontWeight: "bold",
  fontSize: "10px",
};

export const helpCard = {
  backgroundColor: "#808080	",
  border: 0,
  width: "160px",
  height: "770px",
  float: getLanguage() === "en" ? "right" : "left",
  marginRight: getLanguage() === "en" ? "-6%" : null,
  marginLeft: getLanguage() === "ar" ? "-6%" : null,
};

export const cardItemName = {
  fontSize: "16px",
  color: "white",
  fontFamily: "Roboto",
};

export const cardItemCount = {
  fontSize: "16px",
  color: "white",
  fontFamily: "Roboto",
  fontWeight: "bold",
};

export const userCardName = {
  marginTop: "-5%",
  marginRight: getLanguage() === "en" ? "1%" : null,
};

export const percentg = {
  fontSize: "10px",
  fontWeight: "bold",
  fontFamily: "Roboto",
  marginLeft: getLanguage() === "en" ? "2.5%" : null,
  marginRight: getLanguage() === "ar" ? "2.5%" : null,

  marginTop: "10%",
  color: "white",
};

export const removeButton = {
  backgroundColor: "#1d6aab",
  color: "white",
  float: "right",
};

export const viewLink = {
  backgroundColor: "#808080	",
  borderColor: "#808080	",
  color: "white",
  cursor: "pointer",
};

export const generalBtnStyle = {
  backgroundColor: "transparent",
  border: 0,
  color: "white",
};

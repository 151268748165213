import React, { Component } from "react";
import Loader from "react-loader-spinner";
class Spinner extends Component {
  render() {
    const { isWhite, isShowing } = this.props;
    return (
      <div className="row align-items-center justify-content-center">
        <Loader
          visible={isShowing}
          type="Puff"
          color={isWhite !== undefined ? "white" : "#1d6aab"}
          secondaryColor="#1d6aab"
          height={100}
          width={100}
        />
      </div>
    );
  }
}

export default Spinner;

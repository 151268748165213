import { Request } from "./Api";
import { UPDATE_PRICES_PROPS, MERGE_PRICES_PROPS } from "./types";
import { checkisAuthorized, getUserType } from "../../General/GeneralFunctions";
import toast from "toasted-notes";
import { local } from "../../Localization/local";
export const fetchPrices = (history, filter) => async (dispatch) => {
  if (checkisAuthorized() === false) {
    if (getUserType() === "Admin") {
      history.push("/admin/login");
    } else {
      history.push("/");
    }
  } else {
    dispatch({
      type: UPDATE_PRICES_PROPS,
      payload: [{ prop: "rtable.prices", value: [] }],
    });
    if (filter.county === undefined) {
      dispatch({
        type: UPDATE_PRICES_PROPS,
        payload: [
          { prop: "isLoading", value: true },
          { prop: "isSearchable", value: false },
          { prop: "isCreated", value: false },
        ],
      });
    } else {
      dispatch({
        type: UPDATE_PRICES_PROPS,
        payload: [
          { prop: "isLoadingSearch", value: true },
          { prop: "isSearchable", value: true },
          { prop: "isCreated", value: false },
        ],
      });
    }

    Request()
      .post("/price/filter", filter)
      .then((response) => {
        if (response.data.status === true) {
          if (response.data.data !== null) {
            if (filter.county !== undefined) {
              dispatch({
                type: UPDATE_PRICES_PROPS,
                payload: [{ prop: "rtable.prices", value: [] }],
              });
            }
            response.data.data.map(async (county) => {
              dispatch(getCounty(county, response.data.data));
            });
          } else {
            if (filter.county !== undefined) {
              toast.notify(local.SearchResult, {
                position: "top-right",
                duration: 2000,
              });
            }
            dispatch({
              type: UPDATE_PRICES_PROPS,
              payload: [
                { prop: "rtable.prices", value: [] },
                { prop: "isLoading", value: false },
                { prop: "isLoadingSearch", value: false },
              ],
            });
          }
        } else {
          dispatch({
            type: UPDATE_PRICES_PROPS,
            payload: [
              { prop: "isLoading", value: false },
              { prop: "isLoadingSearch", value: false },
            ],
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_PRICES_PROPS,
          payload: [
            { prop: "isLoading", value: false },
            { prop: "isLoadingSearch", value: false },
          ],
        });
        history.push("/error");

        console.log(error);
      })
      .finally();
  }
};

export const getCounty = (myCounty, items) => async (dispatch) => {
  let date = new Date();
  date.setDate(date.getDate() + 1);

  let filter = {
    object: { id: myCounty.county },
    duration: {
      from: "2006-01-02T15:04:05Z",
      to: date.toISOString(),
    },
  };
  await Request()
    .post("/counties", filter)
    .then((response) => {
      if (response.data.data.items.Counties !== null) {
        myCounty.county = response.data.data.items.Counties[0];
        dispatch({
          type: MERGE_PRICES_PROPS,
          payload: [
            {
              prop: "rtable.prices",
              value: myCounty,
            },
          ],
        });
      }

      items.map((item, index) => {
        if (items.length - 1 === index) {
          dispatch({
            type: UPDATE_PRICES_PROPS,
            payload: [
              { prop: "isLoading", value: false },
              { prop: "isLoadingSearch", value: false },
            ],
          });
        }
      });
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_PRICES_PROPS,
        payload: [
          { prop: "isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
      console.log(error);
    })
    .finally();
};

export const CreateuOrUpdatePrice = (newForm, history, index, isEdit) => (
  dispatch
) => {
  dispatch({
    type: UPDATE_PRICES_PROPS,
    payload: [
      { prop: "isLoadingSave", value: true },
      { prop: "isCreated", value: true },
    ],
  });

  let url = null;
  if (isEdit === false) {
    url = "/price/create";
  } else {
    url = "/price/update";
  }

  Request()
    .post(url, newForm)
    .then((response) => {
      if (response.data.status === true) {
        if (isEdit === false) {
          toast.notify(local.Successed, {
            position: "top-right",
            duration: 2000,
          });
        } else {
          toast.notify(local.Edited, {
            position: "top-right",
            duration: 2000,
          });
        }
        history.push("/subdealrs/prices");
      }

      dispatch({
        type: UPDATE_PRICES_PROPS,
        payload: [{ prop: "isLoadingSave", value: false }],
      });
    })

    .catch((error) => {
      dispatch({
        type: UPDATE_PRICES_PROPS,
        payload: [{ prop: "isLoadingSave", value: false }],
      });
    })
    .finally();
};

import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { Input } from 'reactstrap'
import { local, getLanguage } from '../../Localization/local'
import toast from 'toasted-notes'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

// const DialogContent = withStyles((theme) => ({
//   root: {
//     padding: theme.spacing(2),
//   },
// }))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const Message = (props) => {
  const { comment, general, action } = props
  const handleClose = () => {
    general(
      [
        { prop: 'comment.isOpen', value: false },
        { prop: 'comment.message', value: '' },
      ],
      action,
    )
  }

  const onSend = (e) => {
    const { makeRate, history, comment, agentID, general } = props
    e.preventDefault()
    if (comment.message === '') {
      toast.notify(local.MessageRequired, {
        position: 'top-right',
        duration: 2000,
      })
    } else {
      if (comment.rate === 0) {
        general(
          [
            { prop: 'comment.isOpen', value: false },
            { prop: 'comment.message', value: '' },
          ],
          action,
        )
        toast.notify(local.MessageSave, {
          position: 'top-right',
          duration: 2000,
        })
        toast.notify(local.addRate, {
          position: 'top-right',
          duration: 2000,
        })
      } else {
        let url = history.location.pathname.split('list/')[1]
        let shipmentID = url.split('/')[1]
        let companyID = url.split('/')[0]
        let date = url.split('/')[2]
        let newDate = new Date(date)
        makeRate(
          agentID,
          companyID,
          shipmentID,
          comment.rate,
          newDate.toISOString().split('.')[0] + '.00Z',
          comment.message,
        )
      }
    }
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={comment.isOpen}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={getLanguage() === 'en' ? handleClose : null}
          style={{
            color: 'white',
            borderRaduios: '15px',
          }}
          className="notFound"
        >
          {local.Comments}
        </DialogTitle>

        <Input
          type="textarea"
          name="text"
          rows="15"
          cols="125"
          placeholder={local.Message}
          onChange={(e) =>
            general(
              [{ prop: 'comment.message', value: e.target.value }],
              action,
            )
          }
          style={{
            width: '100%',
            height: '100%',
            direction: getLanguage() === 'en' ? 'ltr' : 'rtl',
            fontSize: getLanguage() === 'en' ? '15px' : '18px',
            color: 'black',
            fontFamily: 'cursive',
            border: 0,
          }}
        />
        <hr
          className="notFound"
          style={{ backgroundColor: '#fa6e3f', width: '100%' }}
        />

        <DialogActions className="white">
          <Button
            onClick={(e) => onSend(e)}
            className="notFound"
            style={{ color: 'white', direction: 'rtl' }}
          >
            {local.Save}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default Message

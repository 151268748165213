import React, { Component } from "react";
import {
  Card,
  Spinner,
  Input,
  Button,
  Label,
  Row,
  Col,
  Form,
  Container,
} from "reactstrap";
import { UPDATE_LOGIN_PROPS } from "../../Redux/Actions/types";
import { local, getLanguage } from "../../Localization/local";
import logo from "../../assets/img/brand/lutfiloc.svg";

import {
  loginCard,
  loginForm,
  labelInput,
  input,
  btn,
  generalBtnStyle,
  logoLogin,
} from "../../General/GeneralStyle";
import { FaEye, FaEyeSlash } from "react-icons/fa";

class AdminLoginForm extends Component {
  validateInput = () => {
    const { general, adminAuth } = this.props;
    if (adminAuth.name === "") {
      general(
        [
          {
            prop: "ErrorMessage.ereorUserName",
            value: local.UserNameRequired,
          },
        ],
        UPDATE_LOGIN_PROPS
      );
    }

    if (adminAuth.password === "") {
      general(
        [
          {
            prop: "ErrorMessage.errorPassword",
            value: local.PasswordRequired,
          },
        ],
        UPDATE_LOGIN_PROPS
      );
    }

    if (adminAuth.name !== "" && adminAuth.password !== "") {
      return true;
    }
  };

  onLogin = (e) => {
    const { history, login, adminAuth } = this.props;
    e.preventDefault();

    if (this.validateInput() === true) {
      login(adminAuth, history, "admin");
    }
  };
  render() {
    const {
      isLoading,
      adminAuth,
      general,
      ErrorMessage,
      isViewAdminPassword,
    } = this.props;

    return (
      <Row
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          WebkitAlignItems: "center",
        }}
      >
        <Col lg={12}>
          <Card style={loginCard} className="login">
            <center>
              <div style={logoLogin}>
                <img src={logo} width="80" alt="img" />
              </div>
            </center>
            <Form style={loginForm} onSubmit={this.onLogin}>
              <Row>
                <Col>
                  <Label style={labelInput}>{local.UserName}</Label>
                  <Input
                    style={input}
                    type="text"
                    placeholder={local.UserName}
                    value={adminAuth.name}
                    onChange={(e) =>
                      general(
                        [
                          {
                            prop: "adminAuth.name",
                            value: e.target.value,
                          },
                        ],
                        UPDATE_LOGIN_PROPS
                      )
                    }
                  />
                  {adminAuth.name === "" ? (
                    <span style={{ color: "white" }}>
                      {ErrorMessage.ereorUserName}
                    </span>
                  ) : null}
                </Col>
              </Row>

              <Row style={{ marginTop: "10%" }}>
                <Col>
                  <Label style={labelInput}>{local.Password}</Label>
                  <div
                    style={{
                      float: getLanguage() === "ar" ? "left" : "right",
                    }}
                  >
                    {isViewAdminPassword === "text" ? (
                      <Button
                        style={generalBtnStyle}
                        onClick={(e) =>
                          general(
                            [
                              {
                                prop: "isViewAdminPassword",
                                value: "password",
                              },
                            ],
                            UPDATE_LOGIN_PROPS
                          )
                        }
                      >
                        <FaEye style={{ fontSize: "18px" }} />
                      </Button>
                    ) : (
                      <Button
                        style={generalBtnStyle}
                        onClick={(e) =>
                          general(
                            [{ prop: "isViewAdminPassword", value: "text" }],
                            UPDATE_LOGIN_PROPS
                          )
                        }
                      >
                        <FaEyeSlash style={{ fontSize: "18px" }} />
                      </Button>
                    )}
                  </div>
                  <Input
                    style={input}
                    type={isViewAdminPassword}
                    placeholder={local.Password}
                    value={adminAuth.password}
                    onChange={(e) =>
                      general(
                        [
                          {
                            prop: "adminAuth.password",
                            value: e.target.value,
                          },
                        ],
                        UPDATE_LOGIN_PROPS
                      )
                    }
                  />

                  {adminAuth.password === "" ? (
                    <span style={{ color: "white" }}>
                      {ErrorMessage.errorPassword}
                    </span>
                  ) : null}
                </Col>
              </Row>

              <center>
                <Row style={{ marginTop: "10%" }}>
                  <Col>
                    <Button
                      disabled={isLoading}
                      type="submit"
                      className="btn-sc"
                      style={btn}
                    >
                      {isLoading === true ? (
                        <Spinner color="light" />
                      ) : (
                        local.SignIn
                      )}
                    </Button>
                  </Col>
                </Row>
              </center>
            </Form>
          </Card>
          <center>
            <p
              style={{
                color: "#1d6aab",
                fontFamily: "cursive",
                fontSize: "17px",
              }}
            >
              {local.PowerBy}
            </p>
          </center>
        </Col>
      </Row>
    );
  }
}

export default AdminLoginForm;

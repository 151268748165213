import { Request } from "./Api";
import { UPDATE_AGENTS_PROPS, UPDATE_COMPANIES_PROPS } from "./types";
import {
  checkisAuthorized,
  logoutWithhoutConfirmation,
} from "../../General/GeneralFunctions";
import toast from "toasted-notes";
import { local } from "../../Localization/local";
import { fetchAgents } from "./AgentsActinos";

export const fetchCompany = (history, filter) => (dispatch) => {
  if (checkisAuthorized() === false) {
    history.push("/admin/login");
  } else {
    if (
      filter.object.phone !== undefined ||
      filter.object.county !== undefined
    ) {
      dispatch({
        type: UPDATE_COMPANIES_PROPS,
        payload: [
          { prop: "isSearchable", value: true },
          { prop: "isLoadingSearch", value: true },
          { prop: "isCreated", value: false },
        ],
      });
    } else {
      dispatch({
        type: UPDATE_COMPANIES_PROPS,
        payload: [
          { prop: "isSearchable", value: false },
          { prop: "isLoading", value: true },
          { prop: "isCreated", value: false },
        ],
      });
    }
    let date = new Date();
    date.setDate(date.getDate() + 1);
    filter.duration.to = date.toISOString();
    Request()
      .post("/company", filter)
      .then((response) => {
        if (response.data.status === true) {
          if (response.data.data.items !== null) {
            let companies = [];
            response.data.data.items.map((item) => {
              if (item.info.companyType !== undefined) {
                companies.push(item);
              }
            });
            dispatch({
              type: UPDATE_COMPANIES_PROPS,
              payload: [
                { prop: "rtable.companies", value: companies },
                { prop: "count", value: response.data.data.pages },
              ],
            });
          } else {
            if (
              filter.object.phone !== undefined ||
              filter.object.county !== undefined
            ) {
              toast.notify(local.SearchResult, {
                position: "top-right",
                duration: 2000,
              });
            }
            dispatch({
              type: UPDATE_COMPANIES_PROPS,
              payload: [{ prop: "rtable.companies", value: [] }],
            });
          }
        }

        dispatch({
          type: UPDATE_COMPANIES_PROPS,
          payload: [
            { prop: "isLoading", value: false },
            { prop: "isLoadingSearch", value: false },
          ],
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_COMPANIES_PROPS,
          payload: [
            { prop: "isLoading", value: false },
            { prop: "isLoadingSearch", value: false },
          ],
        });
        history.push("/error");

        console.log(error);
      })
      .finally();
  }
};

export const ModifyCompany = (newForm, history, index) => async (dispatch) => {
  if (newForm.info.companyType === undefined) {
    newForm.info.companyType = "subdealrs";
  }
  dispatch({
    type: UPDATE_COMPANIES_PROPS,
    payload: [{ prop: "isLoadingSave", value: true }],
  });
  if (newForm.info.image !== undefined) {
    if (newForm.info.image !== null && newForm.info.image[0] instanceof File)
      uploadCompanyImage(newForm.info.image);
  }

  let url = null;
  if (newForm.id === undefined) {
    url = "/company/create";
  } else {
    url = "/company/update";
  }

  Request()
    .post(url, newForm)
    .then(async (response) => {
      if (response.data.status === true) {
        if (newForm.id === undefined) {
          newForm.company = response.data.data;
          delete newForm.contact_person;
          Request()
            .post("user/new", newForm)
            .then((res) => {
              console.log(res);
            });
          dispatch({
            type: UPDATE_COMPANIES_PROPS,
            payload: [{ prop: "isCreated", value: true }],
          });
          toast.notify(local.Successed, {
            position: "top-right",
            duration: 2000,
          });

          dispatch({
            type: UPDATE_COMPANIES_PROPS,
            payload: [{ prop: "isLoadingSave", value: false }],
          });
          history.push("/subdealrs/companies");
        } else {
          if (index === -1) {
            if (newForm.password !== "") {
              toast.notify(local.PasswordSucceessed, {
                position: "top-right",
                duration: 2000,
              });
            }
            if (newForm.info.image !== null) {
              toast.notify(local.ImageSucceessed, {
                position: "top-right",
                duration: 2000,
              });
            }

            logoutWithhoutConfirmation(history);
          }
          dispatch({
            type: UPDATE_COMPANIES_PROPS,
            payload: [
              {
                prop: "rtable.companies." + index,
                value: newForm,
              },
              { prop: "isLoadingSave", value: false },
            ],
          });
          history.push("/subdealrs/companies");
          toast.notify(local.Edited, {
            position: "top-right",
            duration: 2000,
          });

          // await dispatch(
          //   update_company_name(newForm.id, newForm.info.name, history),
          // )
        }
      } else {
        if (response.data.error !== null) {
          if (response.data.error.message[0] === "invalid data supplied") {
            toast.notify(local.UserNameID, {
              position: "top-right",
              duration: 2000,
            });
          }
          if (
            response.data.error.message[0].includes("company_name_ctx") ||
            response.data.error.message[0].includes("company_log_on_ctx")
          ) {
            toast.notify(local.UserNameExist, {
              position: "top-right",
              duration: 2000,
            });
          } else {
            response.data.error.message.map((mess) => {
              if (!mess === "invalid data supplied") {
                toast.notify(mess, {
                  position: "top-right",
                  duration: 2000,
                });
              }
            });
          }
          dispatch({
            type: UPDATE_COMPANIES_PROPS,
            payload: [{ prop: "isLoadingSave", value: false }],
          });
        }
      }
    })

    .catch((error) => {
      dispatch({
        type: UPDATE_COMPANIES_PROPS,
        payload: [{ prop: "isLoadingSave", value: false }],
      });
    })
    .finally();
};

export const getCompanyforDropDown = (filter, history, agentFilter) => (
  dispatch
) => {
  let date = new Date();
  date.setDate(date.getDate() + 1);
  filter.duration.to = date.toISOString();
  Request()
    .post("/company", filter)
    .then((response) => {
      if (response.data.status === true) {
        if (response.data.data.items !== null) {
          if (history !== undefined && agentFilter !== undefined) {
            agentFilter.object.company = response.data.data.items[0].id;
            agentFilter.duration.to = date.toISOString();
            dispatch({
              type: UPDATE_AGENTS_PROPS,
              payload: [
                {
                  prop: "search.company",
                  value: response.data.data.items[0].id,
                },
              ],
            });
            dispatch(fetchAgents(history, agentFilter, true));
          }
          let newCompaines = [];
          response.data.data.items.map((comp) => {
            newCompaines.push({
              label: comp.name,
              value: comp.id,
              country: comp.info.country.name,
              countryID: comp.info.country.id,
            });
          });
          dispatch({
            type: UPDATE_COMPANIES_PROPS,
            payload: [{ prop: "compainesOptions", value: newCompaines }],
          });
        }
      }
    })
    .catch((error) => {
      toast.notify(local.InternetConnection, {
        position: "top-right",
        duration: 2000,
      });
      console.log(error);
    })
    .finally();
};

export const uploadCompanyImage = (file) => {
  let formImage = new FormData();
  formImage.append("myFile", file[0]);
  Request()
    .post("/company/upload", formImage)
    .then((response) => {})
    .catch((error) => {
      console.log(error);
    })
    .finally();
};

import { Request } from "./Api";
import {
  UPDATE_TRACKING_HISTORY_PROPS,
  MERGE_TRACKING_HISTORY_PROPS,
} from "./types";
import { checkisAuthorized, getUserType } from "../../General/GeneralFunctions";
import { local } from "../../Localization/local";
import toast from "toasted-notes";
import { ShipmentSUSER } from "../../General/StaticKeys";

export const fetchTrackingHistory = (
  history,
  filter,
  companyId,
  shipmentID
) => async (dispatch) => {
  if (checkisAuthorized() === false) {
    if (getUserType() === "Admin") {
      history.push("/admin/login");
    } else {
      history.push("/");
    }
  } else {
    dispatch({
      type: UPDATE_TRACKING_HISTORY_PROPS,
      payload: [
        { prop: "isLoadingSearch", value: true },
        { prop: "InfoWindowItem.showingInfoWindow", value: false },
        { prop: "card.isView", value: false },
        { prop: "rtable.trackingHistory", value: [] },
        { prop: "lines", value: [] },
        { prop: "lat", value: "" },
        { prop: "lng", value: "" },
        { prop: "customerPoints", value: [] },
      ],
    });

    let company = null;
    if (getUserType() === "Admin") {
      let date = new Date(filter.duration.from);
      company = companyId;
      // let filterCompany = {
      //   object: {
      //     id: companyId,
      //   },
      //   duration: {
      //     from: '2006-01-02T15:04:05Z',
      //     to: date.toISOString(),
      //   },
      // }
      // await Request()
      //   .post('/company', filterCompany)
      //   .then(async (response) => {
      //     if (response.data.status === true) {
      //       company = response.data.data.items[0].id
      //     }
      //   })
    } else {
      company = JSON.parse(localStorage.getItem(ShipmentSUSER)).id;
    }

    let from = null;
    let to = null;

    let myDate = new Date(filter.duration.from);
    let month = myDate.toLocaleDateString().split("/")[0];
    let day = myDate.toLocaleDateString().split("/")[1];
    let year = myDate.toLocaleDateString().split("/")[2];
    let start = new Date(year, month - 1, day, 0, 0, 0, 0);
    let end = new Date(year, month - 1, day, 23, 59, 59, 999);

    from = start;
    to = end;

    let st = new Date(from).toISOString();
    let en = new Date(to).toISOString();

    let fromNew =
      st.split(".")[0] +
      "." +
      st.split(".")[1][0] +
      st.split(".")[1][1] +
      st.split(".")[1][3];

    let toNew =
      en.split(".")[0] +
      "." +
      en.split(".")[1][0] +
      en.split(".")[1][1] +
      en.split(".")[1][3];

    let filterCourier = {
      object: {
        company: company,
      },
      duration: {
        from: fromNew,
        to: toNew,
      },
    };
    if (filter.object.agent !== 0) {
      filterCourier.object.id = filter.object.agent;
    }
    if (shipmentID !== "") {
      filterCourier.object.shipment = Number(shipmentID);
    }

    if (filter.object.agent !== 0 || shipmentID !== "") {
      await Request()
        .post("/agent/gpshist", filterCourier)
        .then((response) => {
          // console.log(JSON.stringify(response.data.data.items.hits.hits[1]))
          if (response.data.status === true) {
            if (
              Object.keys(response.data.data.items.hits).length !== 0 &&
              response.data.data.items.hits.constructor === Object
            ) {
              let points = [];
              let newItems = [];

              response.data.data.items.hits.hits.map((item) => {
                let point = [];
                item._source.geometry.coordinates.map((hit) => {
                  point.push([hit[1], hit[0]]);
                });
                points.push(point);
              });

              response.data.data.items.hits.hits[0].points = points;
              newItems.push({
                agent: response.data.data.items.hits.hits[0],
                shipmentsItem: [],
              });
              dispatch(getShipment(newItems));
            } else {
              toast.notify(local.NoHistory, {
                position: "top-right",
                duration: 2000,
              });

              dispatch({
                type: UPDATE_TRACKING_HISTORY_PROPS,
                payload: [{ prop: "isLoadingSearch", value: false }],
              });
            }
          } else {
            toast.notify(local.SomethingWrong, {
              position: "top-right",
              duration: 2000,
            });

            dispatch({
              type: UPDATE_TRACKING_HISTORY_PROPS,
              payload: [{ prop: "isLoadingSearch", value: false }],
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_TRACKING_HISTORY_PROPS,
            payload: [{ prop: "isLoadingSearch", value: false }],
          });
          console.log(error);
          history.push("/error");
        })
        .finally();
    }

    if (filter.object.agent === 0 && filter.object.client !== 0) {
      let date = new Date();

      let filterClient = {
        object: {
          client: filter.object.client,
        },
        duration: {
          from: "2000-01-02T15:04:05Z",
          to: date.toISOString(),
        },
      };
      Request()
        .post("/shipment", filterClient)
        .then(async (response) => {
          if (response.data.status === true) {
            if (response.data.data.items !== null) {
              let items = [];
              let shipmentsss = response.data.data.items.filter(function (
                item
              ) {
                return (
                  item.status === 2 || item.status === 3 || item.status === 5
                );
              });

              shipmentsss.map((shipm) => {
                let findshipment = shipmentsss.find(function (element) {
                  if (element.agent === shipm.agent) {
                    items.push(shipm);
                  }
                });
              });

              dispatch(fetchTrackingHistorybyClient(items, fromNew, toNew));
            } else {
              toast.notify(local.NoShipments, {
                position: "top-right",
                duration: 2000,
              });

              dispatch({
                type: UPDATE_TRACKING_HISTORY_PROPS,
                payload: [{ prop: "isLoadingSearch", value: false }],
              });
            }
          } else {
            toast.notify(local.SomethingWrong, {
              position: "top-right",
              duration: 2000,
            });

            dispatch({
              type: UPDATE_TRACKING_HISTORY_PROPS,
              payload: [{ prop: "isLoadingSearch", value: false }],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally();
    }
  }
};

export const getShipment = (shipments) => async (dispatch) => {
  let date = new Date();
  Request()
    .post(
      "/agent/retrive/" +
        shipments[0].agent._source.Properties.agent.company +
        "/" +
        shipments[0].agent._source.Properties.agent.id +
        "/" +
        "image",
      {}
    )
    .then((res) => {
      if (res.data !== null) {
        shipments[0].agent._source.Properties.agent.info = { image: res.data };
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally();
  let filter = {
    object: {
      agent: shipments[0].agent._source.Properties.agent.id,
    },
    duration: {
      from: "2000-01-02T15:04:05Z",
      to: date.toISOString(),
    },
  };
  await Request()
    .post("/shipment", filter)
    .then(async (response) => {
      if (response.data.status === true) {
        if (response.data.data.items !== null) {
          shipments[0].shipmentsItem = response.data.data.items;

          shipments.map((item) => {
            dispatch({
              type: MERGE_TRACKING_HISTORY_PROPS,
              payload: [
                {
                  prop: "rtable.trackingHistory",
                  value: item,
                },
              ],
            });
          });

          dispatch({
            type: UPDATE_TRACKING_HISTORY_PROPS,
            payload: [{ prop: "isLoadingSearch", value: false }],
          });
        } else {
          toast.notify(local.NoShipments, {
            position: "top-right",
            duration: 2000,
          });

          dispatch({
            type: UPDATE_TRACKING_HISTORY_PROPS,
            payload: [{ prop: "isLoadingSearch", value: false }],
          });
        }
      } else {
        toast.notify(local.SomethingWrong, {
          position: "top-right",
          duration: 2000,
        });

        dispatch({
          type: UPDATE_TRACKING_HISTORY_PROPS,
          payload: [{ prop: "isLoadingSearch", value: false }],
        });
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally();
};

export const fetchTrackingHistorybyClient = (items, from, to) => (dispatch) => {
  items.map((item) => {
    Request()
      .post(
        "/agent/retrive/" + item.company + "/" + item.agent + "/" + "image",
        {}
      )
      .then((res) => {
        if (res.data !== null) {
          item.info.image = res.data;
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally();
    let filterCourier = {
      object: {
        id: item.agent,
        company: item.company,
      },
      duration: {
        from: from,
        to: to,
      },
    };
    Request()
      .post("/agent/gpshist", filterCourier)
      .then((response) => {
        if (response.data.status === true) {
          if (
            Object.keys(response.data.data.items.hits).length !== 0 &&
            response.data.data.items.hits.constructor === Object
          ) {
            let points = [];
            let newItems = [];
            response.data.data.items.hits.hits.map((hit) => {
              points.push({
                lat: hit._source.point.coordinates[1],
                lng: hit._source.point.coordinates[0],
              });
            });

            response.data.data.items.hits.hits[0].points = points;
            newItems.push({
              agent: response.data.data.items.hits.hits[0],
              shipmentsItem: [],
            });
            dispatch(getShipment(newItems));
          } else {
            toast.notify(local.NoHistory, {
              position: "top-right",
              duration: 2000,
            });

            dispatch({
              type: UPDATE_TRACKING_HISTORY_PROPS,
              payload: [{ prop: "isLoadingSearch", value: false }],
            });
          }
        } else {
          toast.notify(local.SomethingWrong, {
            position: "top-right",
            duration: 2000,
          });

          dispatch({
            type: UPDATE_TRACKING_HISTORY_PROPS,
            payload: [{ prop: "isLoadingSearch", value: false }],
          });
        }
      })
      .catch((error) => {
        toast.notify(local.SomethingWrong, {
          position: "top-right",
          duration: 2000,
        });
        dispatch({
          type: UPDATE_TRACKING_HISTORY_PROPS,
          payload: [{ prop: "isLoadingSearch", value: false }],
        });
        console.log(error);
      })
      .finally();
  });
};

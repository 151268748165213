import { Request } from './Api'
import { MERGE_CUSTOMER_HOME_PROPS, UPDATE_CUSTOMER_HOME_PROPS } from './types'
import { checkisAuthorized } from '../../General/GeneralFunctions'

export const getCustomersId = (history, filter) => async (dispatch) => {
  if (checkisAuthorized() === false) {
    history.push('/')
  } else {
    let date = new Date()
    date.setDate(date.getDate() + 1)
    filter.duration.to = date.toISOString()
    dispatch({
      type: UPDATE_CUSTOMER_HOME_PROPS,
      payload: [
        { prop: 'isLoading', value: true },
        { prop: 'customerHomes', value: [] },
      ],
    })

    let counter = 1
    let pages = 0
    let shipments = []

    if (counter === 1) {
      await Request()
        .post('/shipment', filter)
        .then(async (response) => {
          if (response.data.status === true) {
            if (response.data.data.items !== null) {
              pages = await response.data.data.pages
            }
          } else {
            dispatch({
              type: UPDATE_CUSTOMER_HOME_PROPS,
              payload: [{ prop: 'isLoading', value: false }],
            })
          }
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_CUSTOMER_HOME_PROPS,
            payload: [{ prop: 'isLoading', value: false }],
          })
          history.push('/error')

          console.log(error)
        })
        .finally()
    }

    while (counter <= pages) {
      await Request()
        .post('/shipment', filter)
        .then(async (response) => {
          if (response.data.status === true) {
            if (response.data.data.items !== null) {
              response.data.data.items.map(async (item) => {
                if (item.status !== undefined) {
                  if (item.status === 1) {
                    shipments.push(item)
                  }
                } else {
                  shipments.push(item)
                }
              })
              filter.page = (await filter.page) + 1
              counter = counter + 1
            }
          }
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_CUSTOMER_HOME_PROPS,
            payload: [{ prop: 'isLoading', value: false }],
          })
          history.push('/error')

          console.log(error)
        })
        .finally()
    }

    if (counter > pages) {
      await dispatch(getCustomer(shipments))
    }
  }
}

export const getCustomer = (shipments) => (dispatch) => {
  let clients = []
  clients = shipments.map((item) => item.client)
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
  }

  let uniqueClients = clients.filter(onlyUnique)
  let finalData = []

  uniqueClients.map((unique) => {
    let filterdShipment = shipments.filter((ship) => ship.client === unique)
    if (filterdShipment.length > 0) {
      finalData.push({ client: unique, shipment: filterdShipment })
    }
  })

  if (finalData.length > 0) {
    finalData.map((shipClient, index) => {
      let date = new Date()
      date.setDate(date.getDate() + 1)
      let userFilter = {
        object: {},
        duration: {
          from: '2006-01-02T15:04:05Z',
          to: date.toISOString(),
        },
      }
      let filter = {
        object: {
          id: shipClient.client,
        },
        duration: {
          from: '2006-01-02T15:04:05Z',
          to: date.toISOString(),
        },
      }
      Request()
        .post('/user', userFilter)
        .then((res) => {
          if (res.data.status === true) {
            if (res.data.data.items !== null) {
              filter.object.user = res.data.data.items[0].id
            }
            Request()
              .post('/client', filter)
              .then(async (response) => {
                if (response.data.status === true) {
                  if (response.data.data.items !== null) {
                    shipClient.client = response.data.data.items[0]
                    dispatch({
                      type: MERGE_CUSTOMER_HOME_PROPS,
                      payload: [
                        {
                          prop: 'customerHomes',
                          value: shipClient,
                        },
                      ],
                    })
                  }
                }

                if (index === finalData.length - 1) {
                  await dispatch({
                    type: UPDATE_CUSTOMER_HOME_PROPS,
                    payload: [
                      // {
                      //   prop: 'customerHomes',
                      //   value: finalData,
                      // },
                      { prop: 'isLoading', value: false },
                    ],
                  })
                }
              })
              .catch((error) => {
                console.log(error)
              })
              .finally()
          }
        })
    })
  }
}

export const ShipmentSUSER = " ShipmentSUSER";
export const API_HOST = "https://medical.altawasol.app:1020";
export const API_LOCATION = "https://places.altawasol.app";
export const TOKEN = "TOKEN";
export const AGENT = "AGENT";
export const AGENT_INDEX = "AGENT_INDEX";
export const USERTYPE = "USERTYPE";
export const CUSTOMER = "CUSTOMER";
export const CUSTOMER_INDEX = "CUSTOMER_INDEX";
export const CITY = "CITY";
export const CITY_INDEX = "CITY_INDEX";
export const COUNTY = "COUNTY";
export const COUNTY_INDEX = "COUNTY_INDEX";
export const COUNTRY = "COUNTRY";
export const COMPANY = "COMPANY";
export const COMPANY_INDEX = "COMPANY_INDEX";
export const COURIERASSIGN = "COURIERASSIGN";
export const SHIPMENT_IP_LOCATION = "SHIPMENT_IP_LOCATION";
export const USERITEM = "USERITEM";
export const USER_INDEX = "USER_INDEX";
export const SHIPMENT = "SHIPMENT";
export const SHIPMENT_INDEX = "SHIPMENT_INDEX";
export const USER_SHIPMENT = "USER_SHIPMENT";
export const Card_ITEM = "Card_ITEM";
export const PRICE = "PRICE";
export const PRICE_INDEX = "PRICE_INDEX";

export const COURIER_SHIPMENT = "COURIER_SHIPMENT";
export const CLIENT_SHIPMENT = "CLIENT_SHIPMENT";

export const ShipmentSLANGUAGE = "ShipmentSLANGUAGE";

import {
  UPDATE_COUNTIES_PROPS,
  MERGE_COUNTIES_PROPS,
  DELETE_COUNTIES_PROPS,
} from '../Actions/types'
import * as general from './methods'

const INITIAL_STATE = {
  rtable: {
    counties: [],
  },

  newForm: { state: '', ar_name: '', en_name: '' },
  errorMessage: {
    errorNameAR: '',
    errorNameEn: '',
    errorState: '',
    errorCountry: '',
  },

  isLoading: false,
  isLoadingSave: false,
  isSearchable: false,
  isLoadingSearch: false,
  country: null,
  isCreated: false,
  countiesId: null,
  countiesIndex: null,
  searchOptions: [],
  search: { state: null, country: null },
  filter: {
    object: {},
    duration: {
      from: '2006-01-02T15:04:05Z',
      to: '2021-01-02T15:04:05Z',
    },
    page: 0,
  },

  countiesOptions: [],
  count: 0,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_COUNTIES_PROPS: {
      return general.updateProps(state, action)
    }
    case MERGE_COUNTIES_PROPS: {
      return general.mergeProps(state, action)
    }
    case DELETE_COUNTIES_PROPS: {
      return general.deleteProps(state, action)
    }
    default:
      return state
  }
}

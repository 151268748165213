import React, { Component } from "react";
import MapView from "./MapView";
import { connect } from "react-redux";
import * as actions from "../../Redux/Actions/index";
import Spinner from "../../General/Spinner";
import { UPDATE_SHIPMENTS_PROPS } from "../../Redux/Actions/types";

class WebView extends Component {
  mapStyle = { width: "100%", height: "100%" };

  constructor(props) {
    super(props);
    // this.setAgent();
  }

  // componentDidMount() {
  // }

  setAgent = () => {
    const { history, fetchCustShipment } = this.props;
    // if (newForm.due_date === '') {
    let url = history.location.pathname.split("list/")[1];

    // let plaintext = atob(url)
    let companyID = url.split("/")[0];
    let date = url.split("/")[2];
    let shipmentId = url.split("/")[1];

    // if (date instanceof Date) {
    fetchCustShipment(companyID, date, shipmentId, history);
    // }
  };

  // componentDidUpdate() {
  //   setTimeout(() => {
  //     this.updaetLocation();
  //   }, 10000);
  // }

  updaetLocation = () => {
    const { general, linesView } = this.props;
    // general([{ prop: 'polylines', value: [] }], UPDATE_SHIPMENTS_PROPS)

    if (this.props.linesView.lat !== undefined) {
      general(
        [
          {
            prop: "linesView",
            value: { lat: linesView.lat, lng: linesView.lng },
          },
        ],
        UPDATE_SHIPMENTS_PROPS
      );
    }
  };

  // componentWillUnmount() {
  //   this.props.general(
  //     [
  //       { prop: "linesView", value: {} },
  //       { prop: "polylines", value: [] },
  //     ],
  //     UPDATE_SHIPMENTS_PROPS
  //   );
  // }
  render() {
    const {
      isLoading,
      webView,
      polylines,
      makeRate,
      phoneView,
      general,
      ShipmentLocation,
      customerLat,
      CustomerName,
      agentName,
      InfoWindowItem,
      isOpenPanel,
      webViewlng,
      webViewlat,
      linesView,
      history,
      comment,
      bounds,
      goToAgentLocation,
      setAgent,
    } = this.props;

    return (
      <>
        {isLoading === true ? (
          <Spinner isShowing={isLoading} />
        ) : (
          // <SliderCard general={general} isOpenPanel={isOpenPanel} />

          <MapView
            mapStyle={this.mapStyle}
            webView={webView}
            linesView={linesView}
            makeRate={makeRate}
            phoneView={phoneView}
            general={general}
            customerLat={customerLat}
            ShipmentLocation={ShipmentLocation}
            agentName={agentName}
            CustomerName={CustomerName}
            InfoWindowItem={InfoWindowItem}
            isOpenPanel={isOpenPanel}
            webViewlng={webViewlng}
            webViewlat={webViewlat}
            history={history}
            comment={comment}
            bounds={bounds}
            setAgent={this.setAgent}
            goToAgentLocation={goToAgentLocation}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ ShipmentsR }) => {
  return {
    webView: ShipmentsR.webView,
    goToAgentLocation: ShipmentsR.goToAgentLocation,
    bounds: ShipmentsR.bounds,
    comment: ShipmentsR.comment,
    webViewlat: ShipmentsR.webViewlat,
    webViewlng: ShipmentsR.webViewlng,
    isOpenPanel: ShipmentsR.isOpenPanel,
    phoneView: ShipmentsR.phoneView,
    rate: ShipmentsR.rate,
    isLoading: ShipmentsR.isLoading,
    agentName: ShipmentsR.agentName,
    newForm: ShipmentsR.newForm,
    linesView: ShipmentsR.linesView,
    polylines: ShipmentsR.polylines,
    CustomerName: ShipmentsR.CustomerName,
    InfoWindowItem: ShipmentsR.InfoWindowItem,
    ShipmentLocation: ShipmentsR.ShipmentLocation,
  };
};
export default connect(mapStateToProps, actions)(WebView);

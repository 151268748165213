import {
  UPDATE_ADMIN_HOME_PROPS,
  MERGE_ADMIN_HOME_PROPS,
  DELETE_ADMIN_HOME_PROPS,
} from '../Actions/types'
import * as general from './methods'

const INITIAL_STATE = {
  isLoading: false,
  statistics: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ADMIN_HOME_PROPS: {
      return general.updateProps(state, action)
    }
    case MERGE_ADMIN_HOME_PROPS: {
      return general.mergeProps(state, action)
    }
    case DELETE_ADMIN_HOME_PROPS: {
      return general.deleteProps(state, action)
    }
    default:
      return state
  }
}

import React, { Component } from "react";
import {
  Map,
  GoogleApiWrapper,
  Marker,
  Polyline,
  Circle,
} from "google-maps-react";
import MapCard from "./MapCard";
import marker from "../../assets/img/brand/newMarker.svg";
import man from "../../assets/img/brand/man.png";

import { UPDATE_SHIPMENTS_PROPS } from "../../Redux/Actions/types";
class MapView extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onMoveIt = this.onMoveIt.bind(this);
  }

  onClick(t, map, coord) {
    const { latLng } = coord;
    // let lat = latLng.lat()
    // let lng = latLng.lng()
  }

  getLastElement = (array, n) => {
    if (array == null) return void 0;
    if (n == null) return array[array.length - 1];
    return array.slice(Math.max(array.length - n, 0));
  };

  onMarkerClick = (props, marker, e) => {
    const { general } = this.props;

    general(
      [
        { prop: "InfoWindowItem.selectedPlace", value: props },
        { prop: "InfoWindowItem.activeMarker", value: marker },
        { prop: "InfoWindowItem.showingInfoWindow", value: true },
      ],
      UPDATE_SHIPMENTS_PROPS
    );
  };
  prepareBounds = () => {
    const { bounds } = this.props;
    let b = [{ lat: 26.8446991, lng: 35.3740421 }];
    let bound = this.props.google.maps.LatLngBounds();
    if (bounds.length > 0) {
      for (var i = 0; i < bounds.length; i++) {
        return bound.extend(bounds[i]);
      }
    } else {
      for (var i = 0; i < b.length; i++) {
        return bound.extend(b[i]);
      }
    }
  };
  onMoveIt(mapProps, map) {
    let lat = mapProps.center.lat;
    let lng = mapProps.center.lng;
    const { general } = this.props;

    general(
      [
        {
          prop: "goToAgentLocation",
          value: false,
        },
        { prop: "webViewlat", value: lat },
        { prop: "webViewlng", value: lng },
      ],
      UPDATE_SHIPMENTS_PROPS
    );
  }
  render() {
    const {
      mapStyle,
      webView,
      linesView,
      rate,
      makeRate,
      phoneView,
      general,
      agentName,
      ShipmentLocation,
      CustomerName,
      // InfoWindowItem,
      webViewlat,
      webViewlng,
      isOpenPanel,
      history,
      comment,
      bounds,
      goToAgentLocation,
      setAgent,
    } = this.props;

    return (
      <div
        style={{
          // height: 250,
          width: "100%",
          // overflow: 'hidden',
          // textAlign: 'center',
          // alignItems: 'center',
        }}
      >
        <Map
          onClick={this.onClick}
          onDragend={this.onMoveIt}
          onReady={() => setAgent()}
          google={this.props.google}
          disableDefaultUI={true}
          style={mapStyle}
          initialCenter={{
            lat:
              webViewlat === "" && linesView.lat === undefined
                ? 26.8446991
                : goToAgentLocation === true
                ? linesView.lat
                : webViewlat,
            lng:
              webViewlng === "" && linesView.lng === undefined
                ? 26.8446991
                : goToAgentLocation === true
                ? linesView.lng
                : webViewlng,
          }}
          center={{
            lat:
              webViewlat === "" && linesView.lat === undefined
                ? 26.8446991
                : goToAgentLocation === true
                ? linesView.lat
                : webViewlat,
            lng:
              webViewlng === "" && linesView.lng === undefined
                ? 26.8446991
                : goToAgentLocation === true
                ? linesView.lng
                : webViewlng,
          }}
          zoom={linesView.lat === undefined ? 5 : 14}
          // bounds={bound}
        >
          <MapCard
            webView={webView}
            rate={rate}
            makeRate={makeRate}
            comment={comment}
            phoneView={phoneView}
            general={general}
            isOpenPanel={isOpenPanel}
            webViewlatProp="webViewlat"
            webViewlngProp="webViewlng"
            history={history}
            linesView={linesView}
            customerLat={
              ShipmentLocation !== ""
                ? ShipmentLocation.split("(")[1].split(" ")[1].split(")")[0]
                : ""
            }
            customerLng={
              ShipmentLocation !== ""
                ? ShipmentLocation.split("(")[1].split(" ")[0]
                : ""
            }
            agentLat={linesView.lat !== undefined ? linesView.lat : ""}
            agentLng={linesView.lng !== undefined ? linesView.lng : ""}
          />
          {ShipmentLocation !== "" ? (
            <Marker
              title={CustomerName}
              onClick={this.onMarkerClick}
              position={{
                lat: ShipmentLocation.split("(")[1].split(" ")[1].split(")")[0],

                lng: ShipmentLocation.split("(")[1].split(" ")[0],
              }}
              icon={{
                url: man,
                anchor: new this.props.google.maps.Point(20, 20),
                scaledSize: new this.props.google.maps.Size(40, 40),
              }}
              bootstrapURLKeys={{
                key: "AIzaSyC7ZO1PWmqpBeCwX-zOIZHff2yPHTigS5Y",
                v: "5.31",
              }}
            />
          ) : null}
          {linesView.lat !== undefined && linesView.lng !== undefined ? (
            <Marker
              title={agentName}
              position={{
                lat: linesView.lat !== undefined ? linesView.lat : null,
                lng: linesView.lng !== undefined ? linesView.lng : null,
              }}
              icon={{
                url: marker,
                anchor: new this.props.google.maps.Point(20, 20),
                scaledSize: new this.props.google.maps.Size(45, 45),
              }}
              bootstrapURLKeys={{
                key: "AIzaSyC7ZO1PWmqpBeCwX-zOIZHff2yPHTigS5Y",
                v: "3.31",
              }}
            />
          ) : null}
          {linesView.lat !== undefined && linesView.lng !== undefined ? (
            <Circle
              radius={200}
              center={{ lat: linesView.lat, lng: linesView.lng }}
              onMouseover={() => console.log("mouseover")}
              onClick={() => console.log("click")}
              onMouseout={() => console.log("mouseout")}
              strokeColor="transparent"
              strokeOpacity={0}
              strokeWeight={2}
              fillColor="white"
              fillOpacity={0.1}
            />
          ) : null}

          {bounds !== undefined ? (
            bounds !== null ? (
              bounds.length > 0 ? (
                <Polyline
                  path={[
                    {
                      lat: ShipmentLocation.split("(")[1]
                        .split(" ")[1]
                        .split(")")[0],
                      lng: ShipmentLocation.split("(")[1].split(" ")[0],
                    },

                    { lat: linesView.lat, lng: linesView.lng },
                  ]}
                  strokeColor="#FA6E3E"
                  strokeOpacity={5}
                  strokeWeight={5}
                />
              ) : null
            ) : null
          ) : null}

          {/* {linesView.length > 0 ? ( */}
          {/* <Polyline
            path={linesView}
            strokeColor="#FA6E3E"
            strokeOpacity={5}
            strokeWeight={5}
          /> */}
          {/* ) : null} */}
          {/* {InfoWindowItem.selectedPlace !== null ? (
            <InfoWindow
              onClose={console.log('ad')}
              marker={InfoWindowItem.activeMarker}
              visible={InfoWindowItem.showingInfoWindow}
            >
              <Card
                style={{
                  backgroundColor: '#808080',
                  borderColor: '#808080',
                  width: '100%',
                }}
                className="create"
              >
                <Table
                  responsive="lg"
                  style={{
                    color: 'white',
                    textAlign: 'center',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <tbody>
                    <tr>
                      <th> {local.CustomerName}</th>
                      <td> {InfoWindowItem.selectedPlace.name.name}</td>
                    </tr>
                    <tr>
                      <th> {local.Address}</th>
                      <td> {InfoWindowItem.selectedPlace.name.address}</td>
                    </tr>
                    <tr>
                      <th> {local.Phone}</th>
                      <td> {InfoWindowItem.selectedPlace.name.phone}</td>
                    </tr>

                    <tr>
                      <th> {local.DeliveryStatus}</th>
                      <td>
                        {InfoWindowItem.selectedPlace.name.deliveryStatus ===
                        5 ? (
                          <span>{local.ClientNotFound}</span>
                        ) : InfoWindowItem.selectedPlace.name.deliveryStatus ===
                          2 ? (
                          <span>{local.Delivered}</span>
                        ) : (
                          <span>{local.Rejected}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </InfoWindow>
          ) : null} */}
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyC7ZO1PWmqpBeCwX-zOIZHff2yPHTigS5Y",
})(MapView);

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Rating from '@material-ui/lab/Rating'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import { connect } from 'react-redux'
import * as actions from '../../Redux/Actions/index'
import Box from '@material-ui/core/Box'
import { UPDATE_SHIPMENTS_PROPS } from '../../Redux/Actions/types'

// const StyledRating = withStyles({
//   iconFilled: {
//     color: '#ff6d75',
//   },
//   iconHover: {
//     color: '#ff3d47',
//   },
// })(Rating)

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon />,
    label: 'Very Satisfied',
  },
}

function IconContainer(props) {
  const { value, ...other } = props
  return <span {...other}>{customIcons[value].icon}</span>
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
}

class Rate extends Component {
  saveRate = (value) => {
    const { comment, makeRate, webView, history, general } = this.props

    // if (webView.company !== undefined) {
    //   company = webView.company.id
    // }
    // if (webView.shipment !== undefined) {
    //   shipmentID = webView.shipment.id
    // }
    if (comment.rate === 0) {
      general(
        [{ prop: 'comment.rate', value: Number(value.eventPhase) }],
        UPDATE_SHIPMENTS_PROPS,
      )
      let url = history.location.pathname.split('list/')[1]
      let date = url.split('/')[2]
      let newDate = new Date(date)
      let agentID = null
      if (webView.agent !== undefined) {
        agentID = webView.agent.id
      }
      let shipmentID = url.split('/')[1]
      let companyID = url.split('/')[0]

      if (comment.message === '') {
        general(
          [
            { prop: 'comment.isOpen', value: true },
            { prop: 'isOpenPanel', value: false },
          ],
          UPDATE_SHIPMENTS_PROPS,
        )
      } else {
        makeRate(
          agentID,
          companyID,
          shipmentID,
          Number(value.eventPhase),
          newDate.toISOString().split('.')[0] + '.00Z',
          comment.message,
        )
      }
    }
  }
  render() {
    const { comment } = this.props

    return (
      <div>
        <Box
          component="fieldset"
          mb={3}
          borderColor="transparent"
          style={{ borderRadius: 10 }}
          className={comment.rate === 0 ? 'rate' : ''}
        >
          <Rating
            name="customized-10"
            value={comment.rate === 0 ? 1 : comment.rate}
            disabled={comment.rate === 0 ? false : true}
            onChange={(value) => this.saveRate(value)}
            max={5}
            size="medium"
            style={{
              color: '#fa6e3f',
              opacity: 1,
              marginTop: comment.rate === 0 ? 8 : null,
              direction: 'ltr',
            }}
          />
        </Box>
        {/* <Box
          component="fieldset"
          mb={3}
          borderColor="transparent"
          style={{ borderTopRightRadius: 10, borderBottomLeftRadius: 10 }}
          className="usertype"
        >
          <center>
            <Rating
              name="customized-icons"
              value={rate === 0 ? 1 : rate}
              disabled={rate === 0 ? false : true}
              onChange={(value) => this.saveRate(value)}
              IconContainerComponent={IconContainer}
              style={{
                color: 'white',
                marginTop: 8,
                direction: 'ltr',
              }}
            />
          </center>
        </Box> */}
      </div>
    )
  }
}

const mapStateToProps = ({ ShipmentsR }) => {
  return {
    comment: ShipmentsR.comment,
    webView: ShipmentsR.webView,
  }
}

export default connect(mapStateToProps, actions)(Rate)

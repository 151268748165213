import { Request } from "./Api";
import { UPDATE_TRACKING_PROPS, MERGE_TRACKING_PROPS } from "./types";
import { checkisAuthorized } from "../../General/GeneralFunctions";
import { TOKEN } from "../../General/StaticKeys";

export const fetchTrackings = (history, filter) => (dispatch) => {
  if (checkisAuthorized() === false) {
    history.push("/");
  } else {
    dispatch({
      type: UPDATE_TRACKING_PROPS,
      payload: [
        { prop: "isLoading", value: true },
        { prop: "lines", value: [] },
        { prop: "isLoading", value: true },
        { prop: "rtable.tracking", value: [] },
        { prop: "card.isView", value: false },
        { prop: "lat", value: "" },
        { prop: "lng", value: "" },
        { prop: "meetPoint", value: [] },
        { prop: "trackingID", value: null },
        { prop: "agents", value: [] },
      ],
    });
    let date = new Date();
    // let date4 = new Date()
    // date4.setMinutes(date4.getMinutes() - 2)
    let today = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0
    );
    filter.duration.from = today.toISOString();
    filter.duration.to = date.toISOString();

    Request()
      .post("/agent/activeToday", filter)
      .then((response) => {
        if (response.data.status === true) {
          if (
            response.data.data.items.hits !== null &&
            Object.keys(response.data.data.items.hits).length !== 0 &&
            response.data.data.items.hits.constructor === Object
          ) {
            response.data.data.items.hits.hits = [
              ...new Map(
                response.data.data.items.hits.hits.map((item) => [
                  item._source.id,
                  item,
                ])
              ).values(),
            ];

            dispatch({
              type: UPDATE_TRACKING_PROPS,
              payload: [
                {
                  prop: "rtable.tracking",
                  value: response.data.data.items.hits.hits,
                },
              ],
            });

            dispatch(getLastPoint(response.data.data.items.hits.hits));
            response.data.data.items.hits.hits.map((item, index) => {
              dispatch(
                getAgentUpdatedLocation(
                  item._source,
                  index,
                  UPDATE_TRACKING_PROPS,
                  MERGE_TRACKING_PROPS,
                  "agents."
                )
              );
              // dispatch(getShipmentPoints(item._source.id));
              Request()
                .post(
                  "/agent/retrive/" +
                    item._source.company +
                    "/" +
                    item._source.id +
                    "/" +
                    "image",
                  {}
                )
                .then((res) => {
                  if (res.data !== null) {
                    dispatch({
                      type: UPDATE_TRACKING_PROPS,
                      payload: [
                        {
                          prop:
                            "rtable.tracking." + index + "._source.info.image",
                          value: res.data,
                        },
                      ],
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                })
                .finally();
              dispatch({
                type: UPDATE_TRACKING_PROPS,
                payload: [
                  {
                    prop: "agents." + index + ".tracks",
                    value: [],
                  },
                  {
                    prop: "agents." + index + ".battry",
                    value: "",
                  },
                  {
                    prop: "agents." + index + ".accuracy",
                    value: "",
                  },

                  {
                    prop: "agents." + index + ".shipment",
                    value: [],
                  },
                ],
              });
            });
          } else {
            dispatch({
              type: UPDATE_TRACKING_PROPS,
              payload: [{ prop: "isLoading", value: false }],
            });
          }
          dispatch({
            type: UPDATE_TRACKING_PROPS,
            payload: [{ prop: "isLoading", value: false }],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: UPDATE_TRACKING_PROPS,
          payload: [{ prop: "isLoading", value: false }],
        });
        // history.push('/error')
      })
      .finally();
  }
};

// export const getShipmentPoints = (agentID) => (dispatch) => {
//   let date = new Date();
//   date.setDate(date.getDate() + 1);
//   let filter = {
//     object: {
//       agent: agentID,
//     },
//     duration: {
//       from: "2006-01-02T15:04:05Z",
//       to: date.toISOString(),
//     },
//     page: 0,
//   };
//   Request()
//     .post("/shipment", filter)
//     .then((res) => {
//       if (res.data.status === true) {
//         if (res.data.data.items !== null) {
//           let lat = res.data.data.items[0].location
//             .split("(")[1]
//             .split(" ")[1]
//             .split(")")[0];
//           let lng = res.data.data.items[1].location.split("(")[1].split(" ")[0];
//           dispatch({
//             type: MERGE_TRACKING_PROPS,
//             payload: [
//               {
//                 prop: "bounds",
//                 value: [lat, lng],
//               },
//             ],
//           });
//         }
//       }
//     })
//     .catch((error) => console.log(error))
//     .finally();
// };
export const getLastPoint = (agentsList, agents) => (dispatch) => {
  let date2 = new Date();
  let date3 = new Date();
  date3.setMinutes(date3.getMinutes() - 2);

  // let today2 = new Date(
  //   date2.getFullYear(),
  //   date2.getMonth(),
  //   date2.getDate(),
  //   0,
  //   0,
  //   0,
  // )

  let filter = {
    object: {},
    duration: {
      from: date3.toISOString(),
      to: date2.toISOString(),
    },
  };
  Request()
    .post("/lastpoints", filter)
    .then((response) => {
      if (response.data.status === true) {
        if (
          response.data.data.hits !== null &&
          Object.keys(response.data.data.hits).length !== 0 &&
          response.data.data.hits.constructor === Object
        ) {
          response.data.data.hits.hits = [
            ...new Map(
              response.data.data.hits.hits.map((item) => [
                item._source.id,
                item,
              ])
            ).values(),
          ];
          agentsList.map((age, index) => {
            if (agents !== undefined) {
              if (agents[index].isOpen === undefined) {
                let item = response.data.data.hits.hits.find(
                  (it) => it._source.properties.agent.id === age._source.id
                );

                if (item !== undefined) {
                  if (item._source.properties.shipments.length > 0) {
                    item._source.properties.shipments.map((ship) => {
                      let lat = ship.location
                        .split("(")[1]
                        .split(" ")[1]
                        .split(")")[0];
                      let lng = ship.location.split("(")[1].split(" ")[0];

                      dispatch({
                        type: MERGE_TRACKING_PROPS,
                        payload: [
                          {
                            prop: "meetPoint",
                            value: {
                              name: ship.info.clientName,
                              location: ship.location,
                            },
                          },
                          {
                            prop: "bounds",
                            value: [[lat, lng]],
                          },
                        ],
                      });
                    });
                  }
                  dispatch({
                    type: MERGE_TRACKING_PROPS,
                    payload: [
                      {
                        prop: "bounds",
                        value: [
                          [
                            item._source.geometry.coordinates[1],
                            item._source.geometry.coordinates[0],
                          ],
                        ],
                      },
                    ],
                  });
                  if (index === 0) {
                    dispatch({
                      type: UPDATE_TRACKING_PROPS,
                      payload: [
                        {
                          prop: "lat",
                          value: item._source.geometry.coordinates[1],
                        },
                        {
                          prop: "lng",
                          value: item._source.geometry.coordinates[0],
                        },
                      ],
                    });
                  }
                  dispatch({
                    type: UPDATE_TRACKING_PROPS,
                    payload: [
                      {
                        prop: "agents." + index + ".tracks",
                        value: {
                          lat: item._source.geometry.coordinates[1],
                          lng: item._source.geometry.coordinates[0],
                        },
                      },
                      {
                        prop: "agents." + index + ".shipment",
                        value: item._source.properties.shipments,
                      },
                      {
                        prop: "agents." + index + ".battry",
                        value: item._source.properties.battery,
                      },

                      {
                        prop: "agents." + index + ".accuracy",
                        value: item._source.properties.accuracy,
                      },
                    ],
                  });
                }
              }
            }
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally();
};
let sockets = [];

export const closeAgentConnection = () => (dispatch) => {
  for (var s in sockets) {
    sockets[s].close();

    console.log("closeed");
  }
};

export const getAgentUpdatedLocation = (
  agent,
  index,
  action_update,
  action_merge,
  prop
) => (dispatch) => {
  let token = localStorage.getItem(TOKEN);
  var socketMessage = new WebSocket(
    "wss://medical.altawasol.app:1020/agenttrack/" +
      agent.company +
      "/" +
      agent.id +
      "/" +
      token
  );
  sockets.push(socketMessage);

  socketMessage.addEventListener("open", function (event) {
    dispatch({
      type: action_update,
      payload: [
        {
          prop: prop + index + ".isOpen",
          value: true,
        },
      ],
    });
    console.log("done connect to socket");
  });
  // socketMessage.addEventListener('close', function (event) {
  //   console.log('close connect to socket')
  // })

  socketMessage.addEventListener("message", function (event) {
    event.preventDefault();
    let entry = JSON.parse(event.data);
    dispatch({
      type: action_update,
      payload: [
        {
          prop: prop + index + ".tracks",
          value: {
            name: agent.name,

            lat: entry.geometry.coordinates[1],
            lng: entry.geometry.coordinates[0],
          },
        },
      ],
    });

    dispatch({
      type: action_update,
      payload: [
        {
          prop: prop + index + ".shipment",
          value: entry.properties.shipments,
        },
        {
          prop: prop + index + ".battry",
          value: entry.properties.battery,
        },

        {
          prop: prop + index + ".accuracy",
          value: entry.properties.accuracy,
        },
      ],
    });

    // dispatch({
    //   type: action_update,
    //   payload: [
    //     {
    //       prop: prop + 0 + '.battry',
    //       value: entry.Data[1],
    //     },
    //   ],
    // })
  });
};

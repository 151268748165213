import { getLanguage } from "../../Localization/local";
import {
  UPDATE_STATISTICS_PROPS,
  MERGE_STATISTICS_PROPS,
  DELETE_STATISTICS_PROPS,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  rtable: {
    delivered: [],
    assigned: [],
    noFound: [],
    rejected: [],
  },

  filter: {
    object: { agent: null },
    duration: {
      from: null,
      to: null,
    },
  },
  time: null,
  date: null,

  timeOptions: [
    {
      label: getLanguage() === "en" ? "Previous Month" : "الشهر السابق",
      value: 0,
      from: "",
      to: "",
    },

    {
      label: getLanguage() === "en" ? "Current Month" : "الشهر الحالي",
      value: 1,
      from: "",
      to: "",
    },
    {
      label: getLanguage() === "en" ? "Previous Week" : "الاسبوع السابق",
      value: 2,
      from: "",
      to: "",
    },

    {
      label: getLanguage() === "en" ? "Yesterday" : "امس",
      value: 3,
      from: "",
      to: "",
    },
  ],

  isLoadingSearch: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_STATISTICS_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_STATISTICS_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_STATISTICS_PROPS: {
      return general.deleteProps(state, action);
    }
    default:
      return state;
  }
};

import React, { Component } from 'react'
import { local } from '../Localization/local'
import { Card, Row, Col, Button } from 'reactstrap'
import { loginCard } from '../General/GeneralStyle'
class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null, errorInfo: null }
  }
  static getDerivedStateFromError(error) {
    console.log('here getDerivedStateFromError')
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    console.log('here did catch')
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({
      error: error,
      errorInfo: errorInfo,
      hasError: true,
    })
  }

  render() {
    console.log('Inside Error Boundary render Function')
    const { history } = this.props
    return this.state.error ? (
      <center>
        <br /> <br /> <br />
        <br />
        <div
          style={{
            textAlign: 'center',
            color: 'white',
            marginLeft: '10%',
            marginRight: '10%',
          }}
        >
          <br /> <br />
          <Card style={loginCard} className="login">
            <div style={{ margin: '10%' }}>
              <Row>
                <Col>
                  <h1>{local.PageNotFound}</h1>
                  <p>{local.InternetConnection} </p>
                  <Button
                    onClick={() => history.goBack()}
                    style={{
                      background: 'transparent',
                      color: 'white',
                      textDecoration: 'underline',
                    }}
                  >
                    {local.Reload}
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </center>
    ) : (
      <>{this.props.children}</>
    )
  }
}
export default ErrorBoundary

import axios from 'axios'
import { TOKEN, API_HOST, API_LOCATION } from '../../General/StaticKeys'

export const Request = () => {
  let token = localStorage.getItem(TOKEN)

  return axios.create({
    baseURL: API_HOST,
    headers: {
      Authorization: 'Bearer ' + token,
      // contentType: 'application/json',
    },
  })
}

export const GetShipment = () => {
  return axios.create({
    baseURL: API_HOST,
    headers: {
      // contentType: 'application/json',
    },
  })
}

export const RequestLocation = () => {
  return axios.create({
    baseURL: API_LOCATION,
    headers: {
      // contentType: 'application/json',
    },
  })
}

export const RequestLogin = () => {
  return axios.create({
    baseURL: API_HOST,
  })
}

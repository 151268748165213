import {
  UPDATE_TRACKING_PROPS,
  MERGE_TRACKING_PROPS,
  DELETE_TRACKING_PROPS,
} from "../Actions/types";
import * as general from "./methods";
import { local } from "../../Localization/local";

const INITIAL_STATE = {
  isLoadingCard: false,
  rtable: { tracking: [] },
  isLoading: false,
  filter: {
    object: {},
    duration: {
      from: "2006-01-02T15:04:05Z",
      to: null,
    },
    page: 0,
  },
  agents: [],
  bounds: [],
  isView: false,
  lat: "",
  lng: "",

  card: {
    isView: false,
    date: null,
    percntge: null,
    order: null,
    mobile: null,
    name: null,
    id: null,
    shipments: null,
    image: null,
    lines: [],
    accuracy: 0,
  },
  message: { isOpen: false, sms: "", title: local.SMSMessage },
  agentName: "",
  meetPoint: [],
  trackingID: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_TRACKING_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_TRACKING_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_TRACKING_PROPS: {
      return general.deleteProps(state, action);
    }
    default:
      return state;
  }
};

import { local } from "../Localization/local";
import toast from "toasted-notes";
import { getUserType, validateEmail } from "./GeneralFunctions";
import { ShipmentSUSER } from "./StaticKeys";
import {
  UPDATE_CITIES_PROPS,
  UPDATE_COUNTIES_PROPS,
  UPDATE_CUSTOMERS_PROPS,
} from "../Redux/Actions/types";

export const arrayHasEmptyString = (arr) => {
  let empty = arr.find((a) => a === "");

  if (empty !== undefined) {
    return false;
  } else {
    return true;
  }
};
export const validateInput = (newForm, general, action) => {
  if (
    newForm.name === "" ||
    newForm.user === null ||
    newForm.mail === "" ||
    newForm.info.country.id === null ||
    newForm.info.county.id === null ||
    newForm.info.state.id === null ||
    newForm.info.orderId === "" ||
    newForm.phones.length === 0 ||
    newForm.addresses.length === 0 ||
    newForm.locations.length === 0
  ) {
    toast.notify(local.RequiredDate, {
      position: "top-right",
      duration: 2000,
    });
  }
  if (newForm.name === "") {
    general(
      [{ prop: "errorMessage.errorName", value: local.NameRequired }],
      action
    );
  }

  if (newForm.mail === "") {
    general(
      [{ prop: "errorMessage.errorMail", value: local.EmailRequired }],
      action
    );
  }

  if (newForm.mail !== "" && validateEmail(newForm.mail) === false) {
    general(
      [{ prop: "errorMessage.errorMail", value: local.EmailValidRequired }],
      action
    );
  }

  if (newForm.phones.length === 0) {
    general(
      [{ prop: "errorMessage.errorMobile", value: local.PhoneRequired }],
      action
    );
  }

  if (
    newForm.phones.length !== 0 &&
    newForm.phones[0].startsWith("971") &&
    newForm.phones[0].length !== 12
  ) {
    general(
      [{ prop: "errorMessage.errorMobile", value: local.PhoneEgyptLength }],
      action
    );
  }

  if (
    newForm.phones.length !== 0 &&
    newForm.phones[0].startsWith("20") &&
    newForm.phones[0].length !== 12
  ) {
    general(
      [{ prop: "errorMessage.errorMobile", value: local.PhoneCyLength }],
      action
    );
  }

  if (
    newForm.phones.length !== 0 &&
    newForm.phones[0].startsWith("966") &&
    (newForm.phones[0].length !== 11 || newForm.phones[0].length !== 12)
  ) {
    general(
      [{ prop: "errorMessage.errorMobile", value: local.PhoneSaudiaLength }],
      action
    );
  }

  if (
    newForm.phones.length !== 0 &&
    newForm.phones[0].startsWith("961") &&
    (newForm.phones[0].length !== 10 || newForm.phones[0].length !== 11)
  ) {
    general(
      [{ prop: "errorMessage.errorMobile", value: local.PhoneLbLength }],
      action
    );
  }

  if (
    newForm.phones.length !== 0 &&
    newForm.phones[0].startsWith("357") &&
    newForm.phones[0].length !== 11
  ) {
    general(
      [{ prop: "errorMessage.errorMobile", value: local.PhoneCyLength }],
      action
    );
  }

  // if (newForm.phones.length === 0 || newForm.phones.length === 1) {
  //   general(
  //     [{ prop: "errorMessage.errorMobile2", value: local.PhoneRequired }],
  //     action
  //   );
  // }

  // if (
  //   newForm.phones.length > 1 &&
  //   newForm.phones[1].startsWith("20") &&
  //   newForm.phones[1].length !== 12
  // ) {
  //   general(
  //     [{ prop: "errorMessage.errorMobile2", value: local.PhoneCyLength }],
  //     action
  //   );
  // }

  // if (
  //   newForm.phones.length > 1 &&
  //   newForm.phones[1].startsWith("971") &&
  //   newForm.phones[1].length !== 12
  // ) {
  //   general(
  //     [{ prop: "errorMessage.errorMobile2", value: local.PhoneEgyptLength }],
  //     action
  //   );
  // }

  // if (
  //   newForm.phones.length > 1 &&
  //   newForm.phones[1].startsWith("966") &&
  //   (newForm.phones[1].length !== 11 || newForm.phones[1].length !== 12)
  // ) {
  //   general(
  //     [{ prop: "errorMessage.errorMobile2", value: local.PhoneSaudiaLength }],
  //     action
  //   );
  // }

  // if (
  //   newForm.phones.length > 1 &&
  //   newForm.phones[1].startsWith("961") &&
  //   (newForm.phones[1].length !== 10 || newForm.phones[0].length !== 11)
  // ) {
  //   general(
  //     [{ prop: "errorMessage.errorMobile2", value: local.PhoneLbLength }],
  //     action
  //   );
  // }

  // if (
  //   newForm.phones.length > 1 &&
  //   newForm.phones[1].startsWith("357") &&
  //   newForm.phones[1].length !== 11
  // ) {
  //   general(
  //     [{ prop: "errorMessage.errorMobile2", value: local.PhoneCyLength }],
  //     action
  //   );
  // }

  if (newForm.phones.length === 2 && newForm.phones[0] === newForm.phones[1]) {
    toast.notify(local.PhoneEqual, {
      position: "top-right",
      duration: 2000,
    });
  }

  if (newForm.addresses.length > 0) {
    newForm.addresses.map((item, index) => {
      if (item === "") {
        general(
          [
            {
              prop: "errorMessage.errorAddress." + index,
              value: local.AddressRequired,
            },
          ],
          action
        );
      }
    });
  }

  if (newForm.locations.length === 0) {
    general(
      [{ prop: "errorMessage.errorPoints", value: local.LocationRequired }],
      action
    );
  }

  if (newForm.user === null) {
    general(
      [{ prop: "errorMessage.errorUser", value: local.SalesRequired }],
      action
    );
  }

  if (newForm.info.orderId === "") {
    general(
      [{ prop: "errorMessage.errorOrder", value: local.OrderIDRequired }],
      action
    );
  }

  // if (newForm.info.orderId !== '' && newForm.info.orderId.length < 4) {
  //   general(
  //     [{ prop: 'errorMessage.errorOrder', value: local.OrderIDLength }],
  //     action,
  //   )
  // }

  if (newForm.info.country.id === null) {
    general(
      [{ prop: "errorMessage.errorCountry", value: local.CountryRequired }],
      action
    );
  }

  if (newForm.info.state.id === null) {
    general(
      [{ prop: "errorMessage.errorState", value: local.StateRequired }],
      action
    );
  }

  if (newForm.info.county.id === null) {
    general(
      [{ prop: "errorMessage.errorCounty", value: local.CountyRequired }],
      action
    );
  }

  // if (newForm.info.image !== null && newForm.info.image.length > 1024 * 1024) {
  //   general(
  //     [{ prop: 'errorMessage.errorImage', value: local.ImageLengthRequired }],
  //     action,
  //   )
  // }

  if (
    newForm.name !== "" &&
    newForm.user !== null &&
    newForm.info.orderId !== "" &&
    newForm.info.country.id !== null &&
    newForm.info.state.id !== null &&
    newForm.info.county.id !== null &&
    newForm.mail !== "" &&
    validateEmail(newForm.mail) === true &&
    newForm.locations.length !== 0 &&
    newForm.phones.length > 0 &&
    (((newForm.phones[0].startsWith("20") ||
      newForm.phones[0].startsWith("971")) &&
      newForm.phones[0].length === 12) ||
      (newForm.phones[0].startsWith("966") &&
        (newForm.phones[0].length === 11 || newForm.phones[0].length === 12)) ||
      (newForm.phones[0].startsWith("961") &&
        (newForm.phones[0].length === 10 || newForm.phones[0].length === 11)) ||
      (newForm.phones[0].startsWith("357") && newForm.phones[0].length === 11))

    //     &&
    // (((newForm.phones[1].startsWith("20") ||
    //   newForm.phones[1].startsWith("971")) &&
    //   newForm.phones[1].length === 12) ||
    //   (newForm.phones[1].startsWith("966") &&
    //     (newForm.phones[1].length === 11 || newForm.phones[1].length === 12)) ||
    //   (newForm.phones[1].startsWith("961") &&
    //     (newForm.phones[1].length === 10 || newForm.phones[1].length === 11)) ||
    //   (newForm.phones[1].startsWith("357") && newForm.phones[1].length === 11))
  ) {
    let filterItem = newForm.addresses.filter((add) => add === "");

    if (filterItem.length === 0) {
      if (
        (newForm.phones.length === 2 &&
          newForm.phones[0] !== newForm.phones[1]) ||
        newForm.phones.length === 1
      ) {
        return true;
      }
    }
    // if (
    //   newForm.info.image !== null &&
    //   newForm.info.image.length <= 1024 * 1024
    // ) {
    //   return true
    // }
    // if (newForm.info.image === null) {
    //   return true
    // }
  }
};

export const unsetForm = (general, action) => {
  if (getUserType() === "Admin") {
    general(
      [
        {
          prop: "filter",
          value: {
            object: {},
            duration: {
              from: "2006-01-02T15:04:05Z",
              to: null,
            },
            page: 0,
          },
        },

        { prop: "countryPhone1", value: "" },
        { prop: "countryPhone2", value: "" },
        {
          prop: "locationName",
          value: [],
        },
        { prop: "searchOptions", value: [] },
        {
          prop: "counterNumber",
          value: 1,
        },
        {
          prop: "newForm",
          value: {
            name: "",
            user: null,
            mail: "",
            info: {
              name: "",
              // image: null,
              county: { id: null, name: "" },
              state: { id: null, name: "" },
              country: { id: null, name: "" },
              details: "",
              patient_id: "",
              notes: "",
            },
            phones: [],
            addresses: [],
            locations: [],
          },
        },
        {
          prop: "errorMessage",
          value: {
            errorName: "",
            errorMail: "",
            errorMobile: "",
            errorMobile2: "",
            errorAddress: [],
            errorLocation: "",
            errorMobile1: "",
            errorState: "",
            errorCountry: "",
            errorOrder: "",
            errorUser: "",
          },
        },
      ],
      action
    );
  } else {
    general(
      [
        {
          prop: "filter",
          value: {
            object: {},
            duration: {
              from: "2006-01-02T15:04:05Z",
              to: null,
            },
            page: 0,
          },
        },
        { prop: "countryPhone1", value: "" },
        { prop: "countryPhone2", value: "" },
        {
          prop: "counterNumber",
          value: 1,
        },
        {
          prop: "locationName",
          value: [],
        },
        { prop: "searchOptions", value: [] },

        {
          prop: "newForm",
          value: {
            name: "",
            user: JSON.parse(localStorage.getItem(ShipmentSUSER)).id,
            mail: "",
            info: {
              name: "",
              // image: null,
              county: { id: null, name: "" },
              state: { id: null, name: "" },
              country: { id: null, name: "" },
              details: "",
              patient_id: "",
              notes: "",
              userName: JSON.parse(localStorage.getItem(ShipmentSUSER)).name,
            },
            phones: [],
            addresses: [],
            locations: [],
          },
        },
        {
          prop: "errorMessage",
          value: {
            errorName: "",
            errorName2: "",
            errorMail: "",
            errorMobile: "",
            errorAddress: [],
            errorLocation: "",
            errorMobile1: "",
            errorCountry: "",
            errorState: "",
            errorCounty: "",
            errorOrder: "",
            errorUser: "",
          },
        },
      ],
      action
    );
  }
};

export const onSearchforUser = (e, getUsersForDropDown) => {
  let date = new Date();
  date.setDate(date.getDate() + 1);
  let filter = null;
  if (isNaN(e) === true) {
    filter = {
      object: { name: e },
      duration: {
        from: "2006-01-02T15:04:05Z",
        to: date.toISOString(),
      },
    };
  } else {
    filter = {
      object: { phone: e },
      duration: {
        from: "2006-01-02T15:04:05Z",
        to: date.toISOString(),
      },
    };
  }

  getUsersForDropDown(filter);
};

export const onSelectCountry = (
  e,
  general,
  getCitiesForDropDown,
  newForm,
  citiesOptions,
  countiesOptions,
  onChangeCountry
) => {
  if (e !== null) {
    if (newForm.info.state.id !== null) {
      newForm.info.state = { id: null, name: "" };
    }

    if (citiesOptions.length > 0) {
      general([{ prop: "citiesOptions", value: [] }], UPDATE_CITIES_PROPS);
    }

    if (newForm.info.county.id !== null) {
      newForm.info.county = { id: null, name: "" };
    }
    if (countiesOptions.length > 0) {
      general([{ prop: "countiesOptions", value: [] }], UPDATE_COUNTIES_PROPS);
    }
    general(
      [
        { prop: "mapCodePoints", value: { code: "", lat: "", lng: "" } },
        {
          prop: "lat",
          value: "",
        },
        { prop: "lng", value: "" },
      ],
      UPDATE_CUSTOMERS_PROPS
    );
    onChangeCountry(
      e,
      getCitiesForDropDown,
      general,
      UPDATE_CUSTOMERS_PROPS,
      "newForm.info.country.id"
    );
  } else {
    general([{ prop: "citiesOptions", value: [] }], UPDATE_CITIES_PROPS);
    general([{ prop: "countiesOptions", value: [] }], UPDATE_COUNTIES_PROPS);

    general(
      [
        {
          prop: "newForm.info.country",
          value: { id: null, name: "" },
        },

        {
          prop: "newForm.info.state",
          value: { id: null, name: "" },
        },
        {
          prop: "newForm.info.county",
          value: { id: null, name: "" },
        },
        { prop: "mapCodePoints", value: { code: "", lat: "", lng: "" } },
        {
          prop: "lat",
          value: "",
        },
        { prop: "lng", value: "" },
      ],
      UPDATE_CUSTOMERS_PROPS
    );
  }
};

export const onSelectCity = (
  e,
  general,
  getCountiesForDropDown,
  newForm,
  countiesOptions,
  onChangeCity
) => {
  if (e !== null) {
    if (newForm.info.county.id !== null) {
      newForm.info.county = { id: null, name: "" };
    }

    if (countiesOptions.length > 0) {
      general([{ prop: "countiesOptions", value: [] }], UPDATE_COUNTIES_PROPS);
    }
    onChangeCity(
      e,
      getCountiesForDropDown,
      general,
      UPDATE_CUSTOMERS_PROPS,
      "newForm.info.state.id"
    );
  } else {
    general([{ prop: "countiesOptions", value: [] }], UPDATE_COUNTIES_PROPS);

    general(
      [
        {
          prop: "newForm.info.state",
          value: { id: null, name: "" },
        },
        {
          prop: "newForm.info.county",
          value: { id: null, name: "" },
        },
      ],
      UPDATE_CUSTOMERS_PROPS
    );
  }
};

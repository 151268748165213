import React, { Component } from 'react'
import { local } from '../Localization/local'
import { Card, Row, Col, Button } from 'reactstrap'
import { loginCard } from '../General/GeneralStyle'
class ErrorPage extends Component {
  render() {
    const { history } = this.props

    return (
      <center>
        <br /> <br /> <br />
        <br />
        <div
          style={{
            textAlign: 'center',
            color: 'white',
            marginLeft: '10%',
            marginRight: '10%',
          }}
        >
          <br /> <br />
          <Card style={loginCard} className="create">
            <div style={{ margin: '10%' }}>
              <Row>
                <Col>
                  <h1>{local.PageNotFound}</h1>
                  <p>{local.InternetConnection} </p>
                  <Button
                    onClick={() => history.goBack()}
                    style={{
                      background: 'transparent',
                      color: 'white',
                      textDecoration: 'underline',
                    }}
                  >
                    {local.Reload}
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </center>
    )
  }
}

export default ErrorPage

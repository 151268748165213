export const ar = {
  lang: "ar",
  Settings: "الاعدادات",
  English: "انجليزي",
  Arabic: "عربي",
  Home: "الصفحة الرئيسية",
  Logout: "تسجيل الخروج",
  DeliveryShipments: "شحنات التوصيل",
  Confirmation: "قبول الشحنة من جانب المندوب",
  Confirmed: "تم قبول الشحنة من جانب المندوب",
  NotConfirmed: "رفض المندوب توصيل الشحنة",
  Agents: "مناديب الشحن",
  LinesOrder: "طلبات التوصيل",
  ScanQrcode: "فحص الكود",
  ScuessfullyScanned: "تم الفحص بنجاح",
  LineTrip: "مسار الرحلات",
  Customers: "الزبائن",
  Tracking: "التتبع",
  Salesagents: "مناديب المبيعات",
  Comments: "اكتب التعليق",
  MessageSave: "شكرا علي تعليقك",
  addRate: "اضف التقييم لارسال التعليق",
  CommentSent: "تم ارسال التعليق والتقييم بنجاح",
  Users: "شركات البيع التابعة",
  Help: "المساعدة",
  Feedback: "الشكاوي",
  AddLine: "اضافة مسار",
  AddCustomer: "اضافة عميل",
  FromDateLengthValidation: "من تاريخ اكبر من حتي تاريخ",
  ToDateValidation: "حتي تاريخ مطلوب",
  FromDateValidation: "من تاريخ مطلوب",
  AddAgent: "اضافة مندوب شحن",
  SearchCustomers: "بحث العملاء",
  SearchShipment: "بحث الشحنات",
  ViewCard: "عرض تفاصيل العميل والشحنة",
  Shipment: "الشحنة",
  HomeView: "عرض شركة البيع وتفاصيل الشحنة",
  SearchAgents: "بحث مناديب الشحن",
  Name: "الاسم",
  ID: "الرقم",
  Admin: "ادمن",
  Courier: "عميل",
  Edit: "تعديل",
  Status: "الحالة",
  CreatedIn: "الانشاء",
  SalesAgents: "مناديب المبيعات",
  PhoneExist: "رقم التليفون موجود من قبل",
  AddSalesagent: "اضافة مندوب شحن",
  Company: "الشركة",
  ErrorCompanyLogin: "رقم الموبايل او الرقم السري غير صحيح",
  ErrorAdminLogin: "اسم المستخدم او الرقم السري غير صحيح او غير مفعل",
  SaleCompany: "شركة البيع",
  NotAssigned: "لم يعين",
  AgentRequired: "قم باختيار مندوب التوصيل",
  ErrorCourierLogin: "الرقم القومي او الرقم السري غير صحيح",
  SearchBy: "البحث عن",
  SearchKey: "البحث بواسطة",
  Search: "بحث",
  Reset: "مسح",
  PhoneNumber: "رقم الموبايل",
  ProfilePicture: "الصورة الشخصية",
  NationalIdNumber: "الرقم القومي",
  AddPhotoCopy: "اضاة نسخة للصورة",
  DrivingLicenseNumber: "رقم رخصة القيادة",
  DrivingLicenseandDate: "رقم رخصة القيادة والتاريخ",
  NotifiyBeforexpiration: "حالة العميل",
  Save: "حفظ",
  Lines: "المسارات",
  LineID: "رقم المسار",
  Agent: "مندوب الشحن",
  ChooseYourCity: "اختار المدينة",
  Customer: "العميل",
  Time: "الوقت",
  PatientID: "رقم العميل",
  CustomerLocationExist: "هذا الموقع موجود بالفعل",
  SalesAgent: "العميل",
  Date: "التاريخ",
  CompanyID: "رقم الشركة",
  locationsNotLink: "كل المواقع يجب ان تكون لينكات",
  locationsNotLink: "All Locations must be link",
  Statistics: "الاحصائيات",
  SearchStatistics: "بحث الاحصائيات",
  Time: "الوقت",
  DateRequired: "برجاء اختيار الوقت",
  AddMoreDestinations: "اضافة ابعاد",
  ImportFileData: "استخراج البيانات من الملف",
  SSNFILELengthEgypt: "الرقم القومي يجب ان يساوي 14 رقما للمندوب",
  SSNFILELengthSaudi: "الرقم القومي يجب ان يساوي 10 رقما للمندوب",
  NameDuplicated: "هذا الاسم مكرر",
  PassswordLength: "كل الارقام السرية يجب ان تكون اكير من 7 ارقام",
  SsnDuplicated: "الرقم القومي لا يمكن ان يتكرر",
  WrongActive: "كلمة خطا موجود في عمود المرتبط بتفعيل الحساب",
  InvaldMail: "البريد الالكتروني غير صحيح للعميل",
  PhoneDuplicate: "هذا الرقم",
  PhoneExistIn: " هذا الر قم",
  PhoneExistIn1: "موجود في اعمدة رقم الموبايل1 و2",
  errorInClient: " الخطا موجود عند العميل الذي يحمل الاسم",
  PhoneDuplicated: "مكرر في عمود رقم الموبايل1 ",
  PhoneDuplicated1: "مكرر في عمود رقم الموبايل 2",
  FromDate: "من تاريخ",
  ToDate: "حتي تاريخ",
  CustomerID: "رقم العميل",
  City: "المدينة",
  LogoHere: "الصورة",
  CustomerName: "اسم العميل",
  Documents: "المستندات",
  Email: "البريد الالكتروني",
  Order: "الطلب",
  StartedTime: "البداية",
  Battery: "البطارية",
  PROCESSEDORDERS: "طلبات التوصيل اليوم",
  Loading: "انتظر",
  Password: "الرقم السري",
  SSN: "الرقم القومي",
  UserNameRequired: "اسم المستخدم مطلوب ويجب الا يحتوي علي مساحات فارغة",
  SSNRequired: "برجاء ادخال الرقم القومي",
  SignIn: "تسجيل الدخول",
  Successed: "تم اضافة العنصر بنجاح",
  Edited: "تم تعديل العنصر بنجاح",
  PhoneRequired: "برجاء ادخال رقم الموبايل",
  PowerBy: "تم الانشاء بواسطة توو مواقع",
  AllDocumentsLength: "كل المستندات يجب ان تكون اقل منك 1ميجا بايت",
  PhoneEgyptLength: "رقم التليفون يجب ان يساوي 12 رقم",
  PhoneSaudiaLength: "رقم التليفون يجب ان يساوي 11 او 12 رقم",
  NoShipmentForCustomer: "لا يوجد عميل لديه شحنة مطلوبة للتوصيل",
  NotUrl: "يجب ادخال رابط موقع صحيح",
  CityPhoneRequired: "المدينة او رقم الموبايل مطلوب",
  PlaceNameOrUrl: "ادخل اسم المكان او العنوان",
  EditPassword: "هل انت نتاكد انك تريد تغيير هذه البيانات",
  PhoneLbLength: "رقم التليفون يجب ان يساوي 10 او 11 رقم",
  PageNotFound: "الصفحة غير موجودة",
  Reload: "اعادة تحديث الصفحة",
  PhoneCyLength: "رقم التليفون يجب ان يساوي 11 ارقام",
  PasswordRequired: "برجاء ادخال الرقم السري",
  ConfirmPasswordRequired: "اعد كتابة الرقم السري",
  Companies: "الشركات",
  AddCompany: "اضافة شركة",
  WorkMessage: "لا يوجد مندوبين يعملون حتي الان",
  SomethingWrong: "يوجد خطا",
  NoTracking: "مسارات تتبع المناديب",
  EditCompany: "تعديل بيانات الشركة",
  ContactPersionRequired: "برجاء ادخال اسم مسئول الشركة",
  CompanyRequired: "برجاء اختيار الشركة",
  CountiesRequired: "برجاء اختيار المنطقة",
  CityRequired: "برجاء اختيار المدينة",
  County: "المنطقة",
  CompanyName: "اسم الشركة",
  IdNumber: "الرقم",
  NameRequired: "برجاء ادخال الاسم",
  AddCounty: "اضافة منطقة",
  EditCounty: "تعديل بيانات المنطقة",
  AddCity: "اضافة مدينة",
  Counties: "المناطق",
  Country: "البلد",
  PlaceName: "اسم المكان",
  UserPhone: "رقم تليفون شركة المبيعات",
  CustomerPhone: "رقم تليفون العميل",
  ViewUser: "عرض معلومات شركة المبيعات",
  NoShipments: "لا يوجد شحنات",
  NoShipment: "لا يوج شحنة بهذا الرقم",
  AgentID: "رقم مندوب الشحن",
  ShipmentNumber: "عدد الشحنات",
  ViewCompany: "عرض معلومات الشركة",
  ViewCity: "عرض معلومات المدينة",
  SuccessfullyAssign: "تم بنجاع تعيين الشحنة ",
  ViewShipment: "عرض معلومات الشحنة",
  SelectCourier: "اختار مندوب التوصيل ",
  TrackingLive: "تتبع مباشر",
  TrackingHistory: "تتبع بوقت زمني محدد",

  UserName: "أسم المستخدم",
  SearchUser: "بحث شركات المبيعات",
  RecordValidation1: "قم بادخال بيانات لعنصر واحد علي الاقل",
  Empty: "فارغ",
  AddressFor: "عنوان ",
  SearchCompanies: "بحث الشركات",
  PhoneEqual: "ادخل ارقام تليفون مختلفة",
  CountryValidationRequired: "برجاء اختيا الدولة",
  CityValidationRequired: "برجاء اختيار المدينة",
  PasswordLength: "كلمة السر يجب ات تكون اكبر من اربع ارقام",
  Cities: "المدن",
  CountyID: "رقم الاقليم",
  EnglishNameRequired: "الاسم باللغة الانجليزية مطلوب",
  StateRequired: "برجاء اختيار المدينة",
  CountryRequired: "برجاء اختيار الدولة",
  ArabicName: "الاسم باللغة العربية",
  EnglishName: "الاسم باللغة الانجليزية",
  ArabicNameRequired: "الاسم باللغة العربية مطلوب",
  EditCity: "تعديل بيانات المدينة",
  InternetConnection: "تاكد من اتصالك بالانترنت",
  NoAgents: "لا يوجد مناديب شحن اليوم",
  NoMobile: "لا موبايل",
  NationalIdNumberRequired: "الرقم القومي مطلوب",
  DateRequired: "التاريخ مطلوب",
  EmailRequired: "البريد الالكتروني مطلوب",
  AgentType: "نوع المندوب",
  Inactive: "غيرنشط",
  WorkTime: "وقت العمل",
  Active: "نشط",
  EmailValidRequired:
    "البريد الالكتروني يجب ان يكون علي هذا الشكل (test@email.com)",
  StatusRequired: "الحالة مطلوبة",
  AgentClientRequired: "العميل او المندوب مطلوب",
  AgentClientRequired1: "العميل او المندوب او رقم الشحنة مطلوب",
  EditAgent: "تعديل بيانات مندوب الشحن",
  UniqueID: "الرقم القومي موجود من قبل",
  UniquePhone: "رقم الموبايل موجود من قبل",
  View: "عرض",
  RecordValidation: "ادخل التاريخ او الرقم القومي",
  RecordValidation2: "ادخل التاريخ و اختار اسم المندوب ",

  DateValidation: "ادخل التاريخ كاملا",
  InputValidation: "غير مسموح البحث بارقم القومي والتاريخ معا",
  PhoneNumberRequired: " رقم التليفون يجب ان يكون ارقام انجليزية فقط",
  Address: "العنوان",
  Phone: "الموبايل",
  Mail: "البريد الالكتروني",
  Address1: "العنوان الاول",
  Address2: "العنوان التاني",
  SalesRequired: "اختار شركة المبيعات",
  OrderIDRequired: "رقم المريض مطلوب",
  ShipmentDetails: "تفاصيل الشحنة",
  Notes: "ملاحظات",
  ViewCustomer: "عرض معلومات العميل",
  OrderIDLength: "رقم المريض يجب ان يكون اكبر من اربع ارقام",
  LocationAddress: "ادخل عنوان هذا الموقع",
  EditShipment: "تعديل بيانات الشحنة",
  NoAddress: "لا عنوان",
  DeliveryDate: "المعاد المحدد للتوصيل",
  DeliveryDate1: "تاريخ التسليم",

  AddressRequired: "العنوان مطلوب",
  LocationRequired: "الموقع مطلوب",
  PhoneNumber1: "رقم الموبايل الاول",
  PhoneNumber2: "رقم الموبايل التاني",
  PhoneNumberRequired1: "رقم التليفون يجب ان يكون ارقام انجليزية فقط",
  Location: "الموقع",
  Contact_Person: "الشخص  المسئول",
  Remove: "حذف",
  DeliveryStatus: "حالة التوصيل",
  Language: "اللغة",
  Prices: "اسعار الشحنات",
  AddPrice: "اضافة سعر الشحنة",
  EditPrice: "تعديل بيانات سعر الشحنة",
  ViewPrice: "عرض بيانات سعر الشحنة",
  PriceID: "رقم السعر",
  PriceInKM: "السعر بالكيلو متر",
  PriceAtMinutes: "السعر لكل دقيقة",
  KiloMeterRequired: "السعر لكل كيلو متر مطلوب",
  ImageLengthRequired: "يجب ان يكون حجم الصورة اقل من 1 ميجا",
  SecondRequired: "السعر لكل دقيقة مطلوب",
  Sacurrany: "جنيه مصري",
  imageSize: "حجم الصور اقل من ا ميجا بايت",
  SSNLength: "الرقم القومي يجب ان يساوي 10 ارقام",
  SSNNumberEgypt: "الرقك القومي يجب ان يساوي 14 رقم",
  SSNNumber: "الرقم القومي يجب ان يتكون من ارقام فقط",
  SSNNumberCyprus: "الرقم القومي يجب ان يتكون من 8 ارقام وحرف",
  SSNNumberEmirates: "الرقك القومي يجب ان يساوي 15 رقم",
  IDLogin: "رقم الدخول مطلوب",
  delete: "مسح",
  EditCustomer: "تعديل بيانات العميل",
  SearchResult: "لا توجد نتائج متطابقة",
  ViewAgent: "عرض بيانات مندوب الشحن",
  EditMessage: "هل تريد تعديل هذا العنصر",
  TimeZone: "المنطقة الزمنية",
  TimeZoneRequired: "المنطقة الزمنية مطلوبة",
  Logo: "الصورة هنا",
  Photo: "الصورة",
  Yes: "نعم",
  No: "لا",
  National: "الرقم القومي",
  SelectUser: "اختار نوع المستخدم",
  UserType: "نوع المستخدم",
  CityID: "رقم المدينة",
  CompanySearchValidation: "ادخل رقم التليفون او موقعك",
  Shipments: "الشحنات",
  Price: "السعر",
  Payed: "الدفع",
  NotPaid: "غير مدفوع",
  ShipmentNumber2: "عدد الشحنات",
  LoginID: "رقم تسجيل الدخول",
  SearchShipments: "بحث الشحنات",
  ShipmentID: "رقم الشحنة",
  CodeDelivery: "كود الشحنة",
  shipmentExist: "الشحنة موجودة بالفعل",
  Saved: "تم الحفظ",
  Pending: "... جاري الحفظ",
  CleintSuccessed: "تم حفظ العميل بنجاح",
  IDRequired: "رقم الشحنة مطلوب",
  CodeShipmentRequired: "كود الشحنة مطلوب",
  IDNumber: "رقم الشحنة يجب ان يكون ارقام",
  CodeNumber: "كود الشحنة يجب ان يكون ارقام",
  PhoneLength: "رقم التليفون مطلوب ويجب ان يكون اكبر من ستة ارقام",
  PhoneWithSameCountry: "ارقام التليفونات يجب ان تكون من مصر",
  AddShipment: "اضافة شحنة",
  CustomerRequired: "العميل مطلوب",
  PriceRequired: "السعر مطلوب",
  NumberofShipmentRequired: "عدد الشحنات مطلوب",
  PriceNumber: "السعر يجب ان يكون ارقام فقط",
  User: "شركة المبيعات",
  SearchCities: "بحث المدن",
  shippingCompany: "شركة شحن",
  SmsClient: "ارسال رسالة او خلق رابط للعميل",
  yourClientlink: "لاكمال بياناتك اضغط علي اللينك",
  DataSubmitted: "تم حفظ بياناتك بنجاح",
  WatingClientData: "في انتظار العميل ليقوم باضافة بياناته",
  ThankYou: "شكرا لك",
  ArabicLang: "مسموح اللغة العربية فقط",
  EnglishLang: "مسموح اللغةالانجليزية فقط",
  Egcurrany: " جنيه ",
  NumberOnly: "متاح ارقام فقط",
  ClientComment: "تعليق العميل",
  Rating: "التقييم",
  AddedIn: "تاريخ الاضافة",
  evaluation: "التققيمات",
  DrivingLicensePhoto: "صورة رخصة القيادة",
  DrivingLicensePhotoRequired: "صورة رخصة القيادة مطلوبة",
  DrivinglicenseNo: "رقم رخصة القيادة",
  DrivinglicenseNoRequired: "رقم رخصة القيادة مطلوب",
  DriverPhotowithCar: "صورة السائق مع السيارة ",
  DriverPhotowithCarRequired: "صورة السائق مع السيارة مطلوبة",
  IqamaNumberIDNumber: "رقم الاقامة",
  IqamaNumberIDNumberRequired: "رقم الاقامة مطلوب",
  CarInsurancePhoto: "صورة مستند التامين للسيارة",
  CarInsurancePhotoRequired: "صورة مستند التامين للسيارة مطلوب",
  CarInsuranceNo: "رقم التامين للسيارة",
  CarInsuranceNoRequired: "رقم التامين للسيارة مطلوب",
  CarLicensePhoto: "صورة رخصة القيادة",
  CarLicensePhotoRequired: "صورة رخصة القيادة مطلوبة",
  CarLicenseNo: "رقم رخصة القيادة",
  CarLicenseNoRequired: "رقم رخصة القيادة مطلوب",
  CarSerialNumber: "الرقم التسلسلي للسيارة",
  CarSerialNumberRequired: "الرقم التسلسلي للسيارة مطلوب",
  CarImageWithPlateNumber: "صورة السيارة مع رقم اللوحة",
  CarImageWithPlateNumberRequired: "صورة السيارة مع رقم اللوحة مطلوب",
  HejriBirthDate: "تاريخ الميلاد الهجري",
  HejriBirthDateRequired: "تاريخ الميلاد الهجري مطلوب",
  FileName: "اسم المستند",
  PutLink: "ضع اللينك هنا",
  BirthDateEN: "تاريخ الميلاد بالانجليزي",
  BirthDateENRequired: "السن يجب ان يساوي 18 عاما او اكثر",
  CustomerCompany: "شركة بيع",
  CreateUser: "اضافة شركة المبيعات",
  ShipmentNumberExist: "رقم الشحنة موجود من قبل",
  Assign: "تعيين الشحنة",
  ReAssign: "اعادة تعيين الشحنة",
  Finished: "انتهت",
  AssignShipment: "تعيين شحنة",
  EditUser: "تعديل بيانات شركة المبيعات",
  CountyRequired: "الاقليم مطلوبة",
  UserID: "رقم شركة المبيعات",
  AddUser: "اضافة شركة بيع",
  PasswordRequiredLenth: "الرقم السري يجب ان يكون اكبرمن 8 ارقام وحروف",
  ConfirmPasswordMatch: "غير متطابق مع الرقم السري",
  PasswordSucceessed: "تم تغيير الرقم السري بنجاح",
  ImageSucceessed: "تم اضافة الصورة بنجاح",
  ShipmentCancel: "تم الغاء توصيل الشحنة بنجاح",
  ConfirmPassword: "تاكيد الرقم السري",
  PasswordRequiredStrong:
    "  الرقم السري يجب ان يكون اكبر من 8 ارقام وحروف و يحتوي علي ارقام وحروف وعلامات خاصة(!@#$%^&*)",
  UserRequired: "شركة المبيعات مطلوب",
  Created: "انشئت",
  Assigned: "تم التسليم للمندوب",
  Delivered: "تم توصيل الشحنة",
  Rejected: "تم رفض الشحنة",
  Canceled: "تم الغاء التوصيل",
  ClientNotFound: "العميل غير متوفر",
  DateLengthValidation: "تاريخ الانشاء يجب ان لا يكون اكبر من تاريخ التوصيل",
  UserNameSpace: "اسم المستخدم لا يجب ان يحتوي علي مساحة فارغة",
  ViewAgentCard: "عرض معلومات",
  Started: "بداية التسليم",
  PreferdTimeFrom: "الوقت المفضل لتسليم الشحنة مطلوب",
  PreferdTimeTo: "الوقت المفضل لتسليم الشحنة مطلوب",
  PreferedTime: "الوقت المفضل لتسليم الشحنة للعميل",
  FromPreferedTime: " الوقت المفضل للتسليم (من)",
  ToPreferedTime: "الوقت المفضل للتسليم (الي)",
  Message: "اكتب هنا",
  MessageRequired: "الرسالة مطلوبة",
  LinkID: "لينك الشحنات للعميل",
  Close: "اغلاق",
  Link: "لينك",
  NoHistory: "لا يوجد تتبع مسجل اليوم حتي الان",
  Unpaid: "غير مدفوع",
  Paid: "تم الدفع",
  PaymentStatusRequired: "حالة السعر للشحنة مطلوبة",
  PayedRequired: "سعر الشحنة مطلوب",
  PaymentStatus: "حالة الدفع",
  PricePart: "الجزء المتبقي من السعر",
  NotAllow: "التعديل غير متاح",
  UniqueUserName: "اسم المستخدم موجود من قبل",
  Comment: "تعليق المندوب",
  NoComment: "لا تعليق",
  CancelDelivry: "الغاء توصيل الشحنة",
  NameExist: "ألاسم موجود من قبل",
  FinishedOrders: "الطلبات المنتهية",
  UserNameExist: "اسم المستخدم موجود من قبل",
  tabToOpen: "اضغط للفتح",
  tabToClose: "اضغط للإغلاق",
  NavigateMe: "الذهاب الي موقعي",
  NavigateToAgent: "الذهاب الي موقع المندوب",
  yourshipmentslink: "لينك الدخول لشحنتك",
  Send: "ارسال",
  SMSMessage: "رسالة نصية",
  RequiredDate: "يجب ان تدخل جميع الحقول المطلوبة",
  AgentName: "اسم المندوب",
  ArrivingAt: "ميعاد الوصول",
  ShipmentFrom: "الشحنة من",
  MessageSent: "تم ارسال الرسالة بنجاح",
  LogoutConfirm: "هل تريد الخروح ؟",
  YourVerfiyCode: "رمز التحقق الخاص بك",
  ShipmentTypeRequired: "نوع الشحنة مطلوب",
  ShipmentType: "نوع الشحنة",
  CusNameValidation: "الاسم لا يمكن ان يحتوي علي مساحة فارغة",
  ReturnedPrice: "السعر المسترد",
  PhoneNotExist: "رقم الموبايل غير موجود",
  MeetingPoint: "نقطة الالتقاء",
  PhoneNumberofAgent: "رقم موبايل العميل",
  SelectCustomerLocation: "أختار مكان العميل",
  SearchCounty: "بحث المناطق",
  CompanyNamePhone: "اسم الشركة او الموبايل",
  UserNamePhone: "اسم شركة المبيعات او الموبايل",
  CustomerNamePhone: "اسم العميل او الموبايل",
  newCustomer: "عميل جديد",
  oldCustomer: "عمل قديم",
  LinkSms: "ارسال رسالة او الحصول علي الرابط",
  SendSmS: "ارسال رسالة",
  LinkCopied: "تم نسخ الرابط",
  GenerateLink: "توليد رابط",
  WayChoose: "يجب عليك اختيار ارسال رسالة او توليد رابط او الاتنين معا",
  AddInvoice: "اضافة فاتورة",
  DefaulltInvoice: "استخدام الفاتورة الرسمية",
  uploadInvoice: "تحميل فاتورة",
  FileLength: "حجم الملف يجب ان يكون اقل من 2 ميجا بايت",
  FileType: "مسموح بتحميل pdf فقط",
  PreviewFile: "فتح الملف",
  BillTo: "فاتورة الي",
  Charge: "الشحنة",
  VAT: "ضريبة القيمة المضافة",
  AdditionalTax: "الضريبة العادية",
  Total: "التكلفة الكلية",
  Items: "العناصر",
  Quantity: "الكمية",
  UnitePrice: "السعر الموحد",
  AddItem: "اضافة عنصر",
  CompleteData: "برجاء اكمال البيانات",
  RemoveInvoice: "حذف الفاتورة",
  Invoice: "الفاتورة",
  OrderID: "رقم الطلب",
  ShippingSalesCompany: "شركة شحن وبيع",
  CountyNameArabic: "اسم المنطقة باللغة العربية",
  CountyNameEnglish: "اسم المنطقة باللغة الانجليزية",
  UserNameID: "اسم المستخدم او الرقم السري موجود من قبل",
  CancelShipment: "الغاء التوصيل",
  UserNamePhoneExist: "اسم المستخدم او رقم الموبايل موجود من قبل",
  Viewd: "المعروض",
};

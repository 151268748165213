import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
// import MuiDialogTitle from '@material-ui/core/DialogTitle'
// import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
// import IconButton from '@material-ui/core/IconButton'
// import CloseIcon from '@material-ui/icons/Close'
// import Typography from '@material-ui/core/Typography'
import { local } from '../../Localization/local'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const Message = (props) => {
  const { general, action, phoneView } = props
  const handleClose = () => {
    general(
      [
        { prop: 'phoneView.isOpen', value: false },
        { prop: 'phoneView.phone', value: '' },
        { prop: 'phoneView.title', value: '' },
      ],
      action,
    )
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={phoneView.isOpen}
      >
        {/* <DialogTitle
          id="customized-dialog-title"
          onClose={getLanguage() === 'en' ? handleClose : null}
          style={{
            color: 'white',
            borderRaduios: '15px',
          }}
          className="notFound"
        >
          {phoneView.title}
        </DialogTitle> */}
        <br />

        {/* <center> */}
        <div style={{ padding: '15%', overflow: 'hidden', width: '80%' }}>
          <p style={{ color: 'rgb(250, 110, 62)', fontSize: '25px' }}>
            {phoneView.phone}
          </p>
        </div>
        {/* </center> */}
        <hr
          className="notFound"
          style={{ backgroundColor: '#fa6e3f', width: '100%' }}
        />

        <DialogActions className="white">
          <Button
            onClick={handleClose}
            className="notFound"
            style={{ color: 'white', direction: 'rtl' }}
          >
            {local.Close}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default Message

import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../Redux/Actions/index";
import AsyncSelect from "react-select/async";
import Select from "react-select";

import { Card, Row, Col, Input, Label, Form, Button, Table } from "reactstrap";
import {
  custoemrSmsCreateCard,
  input,
  labelInput,
  Agentbtn,
  mapCard,
  custoemrSms1CreateCard,
  selectStyle,
} from "../../General/GeneralStyle";

import { getDefaultItem } from "../../General/SelectFunctions";
import Spinner from "../../General/Spinner";

import {
  onChangeCountry,
  onSelectSearch,
  onChangeCity,
  onSelectSearch2,
  validateEmail,
  onHandlePhoneNumber,
  setPhoneOfCountry,
} from "../../General/GeneralFunctions";
import toast from "toasted-notes";

import CustomerMap from "../Customers/CustomerMap";
import { local, getLanguage } from "../../Localization/local";
import {
  unsetForm,
  validateInput,
  onSelectCountry,
  onSelectCity,
} from "../../General/CustomersFunctions";

import PhoneInput from "react-phone-input-2";

import startsWith from "lodash.startswith";
import ar from "react-phone-input-2/lang/ar.json";
import Message from "../Customers/Message";

import {
  UPDATE_CUSTOMERS_PROPS,
  DELETE_CUSTOMERS_PROPS,
} from "../../Redux/Actions/types";
class CreateCustomer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    unsetForm(this.props.general, UPDATE_CUSTOMERS_PROPS);

    if (this.props.countriesOptions.length === 0) {
      this.props.fetchCountryForDropDown();
    }
    this.getClientData();
  }

  getClientData = () => {
    const { history, getClientInfo } = this.props;

    let company = history.location.pathname.split("/")[3];
    let user = history.location.pathname.split("/")[4];
    let id = history.location.pathname.split("/")[5];
    getClientInfo(company, user, id);
  };

  componentWillUnmount() {
    unsetForm(this.props.general, UPDATE_CUSTOMERS_PROPS);
  }

  onSave = (e) => {
    const { saveClientData, history, newForm, general } = this.props;
    if (validateInput(newForm, general, UPDATE_CUSTOMERS_PROPS) === true) {
      e.preventDefault();

      let company = history.location.pathname.split("/")[3];
      let user = history.location.pathname.split("/")[4];
      let id = history.location.pathname.split("/")[5];
      saveClientData(company, user, id, newForm);
    }
  };

  filterCity = (inputValue) => {
    return this.props.citiesOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  promiseCity = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.filterCity(inputValue));
      }, 0);
    });

  filterArea = (inputValue) => {
    return this.props.countiesOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  validateUserData = () => {
    const { newForm } = this.props;

    if (newForm.info.isSaved !== undefined) {
      return true;
    } else {
      return false;
    }
  };

  promiseArea = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.filterArea(inputValue));
      }, 0);
    });
  handlePhone2 = (e, country) => {
    const { newForm, general, countryPhone2 } = this.props;

    if (newForm.phones.length === 0) {
      onHandlePhoneNumber(
        e,
        country.name,
        "newForm.phones." + 1,
        UPDATE_CUSTOMERS_PROPS,
        countryPhone2,
        general,
        "countryPhone2",
        "errorMessage.errorMobile2"
      );
      general(
        [
          {
            prop: "newForm.phones." + 0,
            value: "",
          },
        ],
        UPDATE_CUSTOMERS_PROPS
      );
    } else {
      onHandlePhoneNumber(
        e,
        country.name,
        "newForm.phones." + 1,
        UPDATE_CUSTOMERS_PROPS,
        countryPhone2,
        general,
        "countryPhone2",
        "errorMessage.errorMobile2"
      );
    }
  };

  onChangeCustomerCountry = (e) => {
    const {
      general,
      getCitiesForDropDown,
      newForm,
      citiesOptions,
      countiesOptions,
    } = this.props;
    if (e === null) {
      general(
        [
          { prop: "newForm.addresses", value: [] },
          { prop: "newForm.locations", value: [] },
        ],
        UPDATE_CUSTOMERS_PROPS
      );
    }
    onSelectCountry(
      e,
      general,
      getCitiesForDropDown,
      newForm,
      citiesOptions,
      countiesOptions,
      onChangeCountry
    );
  };

  render() {
    const mapStyle = { width: "100%", height: "100%", borderRadius: "10px" };
    const {
      newForm,
      isLoadingSave,
      errorMessage,
      general,
      lat,
      lng,
      MapFilter,
      searchOptions,
      countriesOptions,
      countiesOptions,
      citiesOptions,
      getCitiesForDropDown,
      getCountiesForDropDown,
      mapCodePoints,
      countryPhone1,
      locationAddress,
      urlLink,
      smsData,
    } = this.props;
    return (
      <div>
        {smsData.isLoading === true ? (
          <div
            style={{
              marginTop: "20%",
            }}
          >
            <Spinner />
          </div>
        ) : (
          <center>
            <Message locationAddress={locationAddress} general={general} />
            <Card
              style={custoemrSmsCreateCard}
              className="mt-1 mb-5 createCustomer"
            >
              {this.validateUserData() === true ? (
                <div>
                  <h3 style={{ color: "white" }}>{local.DataSubmitted}</h3>
                  <p style={{ color: "white", fontSize: "15px" }}>
                    {local.ThankYou}
                  </p>
                </div>
              ) : (
                <Form style={{ marginLeft: "10%", marginRight: "10%" }}>
                  <Row>
                    <Col lg={6}>
                      <Label style={labelInput}>{local.Name}</Label>
                      <br />
                      <div style={{ marginTop: "4.5%" }}>
                        <Input
                          disabled={smsData.isUpdate}
                          type="text"
                          placeholder={local.Name}
                          style={input}
                          value={newForm.name}
                          onChange={(e) =>
                            general(
                              [
                                {
                                  prop: "newForm.name",
                                  value: e.target.value,
                                },
                              ],
                              UPDATE_CUSTOMERS_PROPS
                            )
                          }
                        />
                      </div>
                      <span style={{ color: "white", fontFamily: "Roboto" }}>
                        {newForm.name === "" ? errorMessage.errorName : null}
                      </span>
                    </Col>
                    <br />
                    <br />
                    <br />
                    <br /> <br />
                    <Col lg={6}>
                      <Label style={labelInput}>{local.PatientID}</Label>
                      <br />
                      <div style={{ marginTop: "4.5%" }}>
                        <Input
                          disabled={smsData.isUpdate}
                          type="text"
                          placeholder={local.PatientID}
                          style={input}
                          value={newForm.info.patient_id}
                          onChange={(e) =>
                            general(
                              [
                                {
                                  prop: "newForm.info.patient_id",
                                  value: e.target.value,
                                },
                              ],
                              UPDATE_CUSTOMERS_PROPS
                            )
                          }
                        />
                      </div>
                      <span style={{ color: "white", fontFamily: "Roboto" }}>
                        {newForm.info.patient_id === ""
                          ? errorMessage.errorOrder
                          : null}
                      </span>
                    </Col>
                    <br />
                    <br />
                    <br />
                    <br /> <br />
                  </Row>
                  <br />
                  <Row>
                    <Col lg={6}>
                      <Label style={labelInput}>{local.Country}</Label>
                      <br />
                      <div style={{ marginTop: "4.5%" }}>
                        <Select
                          style={selectStyle}
                          isDisabled={smsData.isUpdate}
                          isClearable={true}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,

                              neutral80: "white",
                            },
                          })}
                          key={newForm.info.country.id}
                          placeholder={local.Country}
                          style={input}
                          value={getDefaultItem(
                            newForm.info.country.id,
                            countriesOptions
                          )}
                          onChange={(e) => this.onChangeCustomerCountry(e)}
                          defaultOptions
                          options={countriesOptions}
                        />
                      </div>
                      <span style={{ color: "white", fontFamily: "Roboto" }}>
                        {newForm.info.country.id === null
                          ? errorMessage.errorCountry
                          : null}
                      </span>
                    </Col>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Col lg={6}>
                      <Label style={labelInput}>{local.City}</Label>
                      <br />
                      <div style={{ marginTop: "4.5%" }}>
                        <AsyncSelect
                          style={selectStyle}
                          isDisabled={smsData.isUpdate}
                          key={newForm.info.state.id}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,

                              neutral80: "white",
                            },
                          })}
                          isClearable={true}
                          placeholder={local.City}
                          onFocus={() =>
                            newForm.info.country.id === null
                              ? toast.notify(local.CountryValidationRequired, {
                                  position: "top-right",
                                  duration: 2000,
                                })
                              : null
                          }
                          onInputChange={(e) =>
                            e.length > 2 && newForm.info.country.id !== null
                              ? onSelectSearch(
                                  newForm.info.country.id,
                                  e,
                                  getCitiesForDropDown
                                )
                              : null
                          }
                          defaultOptions={citiesOptions}
                          loadOptions={this.promiseCity}
                          style={input}
                          value={getDefaultItem(
                            newForm.info.state.id,
                            citiesOptions
                          )}
                          onChange={(e) =>
                            onSelectCity(
                              e,
                              general,
                              getCountiesForDropDown,
                              newForm,
                              countiesOptions,
                              onChangeCity
                            )
                          }
                        />
                      </div>
                      <span style={{ color: "white", fontFamily: "Roboto" }}>
                        {newForm.info.state.id === null
                          ? errorMessage.errorState
                          : null}
                      </span>
                    </Col>
                    <br />
                    <br />
                    <br />
                    <br />
                  </Row>
                  <br />
                  <Row>
                    <Col lg={6}>
                      <Label style={labelInput}>{local.County}</Label>
                      <br />

                      <div style={{ marginTop: "4.5%" }}>
                        <AsyncSelect
                          style={selectStyle}
                          isDisabled={smsData.isUpdate}
                          key={newForm.info.county.id}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,

                              neutral80: "white",
                            },
                          })}
                          isClearable={true}
                          placeholder={local.County}
                          onFocus={() =>
                            newForm.info.state.id === null
                              ? toast.notify(local.CityValidationRequired, {
                                  position: "top-right",
                                  duration: 2000,
                                })
                              : null
                          }
                          onInputChange={(e) =>
                            e.length > 2 && newForm.info.state.id !== null
                              ? onSelectSearch2(
                                  newForm.info.state.id,
                                  e,
                                  getCountiesForDropDown
                                )
                              : null
                          }
                          defaultOptions={countiesOptions}
                          loadOptions={this.promiseArea}
                          style={input}
                          value={getDefaultItem(
                            newForm.info.county.id,
                            countiesOptions
                          )}
                          onChange={(e) =>
                            e !== null
                              ? general(
                                  [
                                    {
                                      prop: "newForm.info.county",
                                      value: { id: e.value, name: e.label },
                                    },
                                  ],
                                  UPDATE_CUSTOMERS_PROPS
                                )
                              : general(
                                  [
                                    {
                                      prop: "newForm.info.county",
                                      value: { id: null, name: "" },
                                    },
                                  ],
                                  UPDATE_CUSTOMERS_PROPS
                                )
                          }
                        />
                      </div>
                      <span style={{ color: "white", fontFamily: "Roboto" }}>
                        {newForm.info.county.id === null
                          ? errorMessage.errorCounty
                          : null}
                      </span>
                    </Col>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Col lg={6}>
                      <Label style={labelInput}>{local.Mail}</Label>
                      <br />
                      <div style={{ marginTop: "4.5%" }}>
                        <Input
                          disabled={smsData.isUpdate}
                          type="email"
                          placeholder={local.Mail}
                          style={input}
                          value={newForm.mail}
                          onChange={(e) =>
                            general(
                              [
                                {
                                  prop: "newForm.mail",
                                  value: e.target.value,
                                },
                              ],
                              UPDATE_CUSTOMERS_PROPS
                            )
                          }
                        />
                      </div>
                      <span style={{ color: "white", fontFamily: "Roboto" }}>
                        {newForm.mail === "" ||
                        (newForm.mail !== "" &&
                          validateEmail(newForm.mail) === false)
                          ? errorMessage.errorMail
                          : null}
                      </span>
                    </Col>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </Row>
                  <br />
                  <Row>
                    <Col lg={6}>
                      <Label style={labelInput}>{local.PhoneNumber1}</Label>
                      <br />
                      <div style={{ marginTop: "4.5%" }}>
                        <PhoneInput
                          disabled={smsData.isUpdate}
                          country={setPhoneOfCountry(newForm.info.country.name)}
                          onlyCountries={["eg", "lb", "sa", "ae", "cy"]}
                          disableDropdown={true}
                          value={
                            newForm.phones !== undefined
                              ? newForm.phones[0]
                              : null
                          }
                          isValid={(inputNumber, country, countries) => {
                            return countries.some((country) => {
                              return (
                                startsWith(inputNumber, country.dialCode) ||
                                startsWith(country.dialCode, inputNumber)
                              );
                            });
                          }}
                          localization={getLanguage() === "ar" ? ar : false}
                          enableAreaCodes={true}
                          searchPlaceholder={local.Search}
                          enableAreaCodeStretch
                          countryCodeEditable={false}
                          autoFormat={"on"}
                          enableSearch={true}
                          disableSearchIcon={false}
                          placeholder={local.PhoneNumber}
                          onChange={(e, country) =>
                            onHandlePhoneNumber(
                              e,
                              country.name,
                              "newForm.phones." + 0,
                              UPDATE_CUSTOMERS_PROPS,
                              countryPhone1,
                              general,
                              "countryPhone1",
                              "errorMessage.errorMobile"
                            )
                          }
                        />
                      </div>

                      <span style={{ color: "white", fontFamily: "Roboto" }}>
                        {newForm.phones !== undefined
                          ? newForm.phones.length === 0 ||
                            (newForm.phones.length > 0 &&
                              newForm.phones[0].length !== 12 &&
                              newForm.phones[0].startsWith("20") === true) ||
                            (newForm.phones.length > 0 &&
                              newForm.phones[0].length !== 12 &&
                              newForm.phones[0].startsWith("971") === true) ||
                            (newForm.phones.length > 0 &&
                              newForm.phones[0].length !== 11 &&
                              newForm.phones[0].length !== 12 &&
                              newForm.phones[0].startsWith("966") === true) ||
                            (newForm.phones.length > 0 &&
                              newForm.phones[0].length !== 10 &&
                              newForm.phones[0].length !== 11 &&
                              newForm.phones[0].startsWith("961") === true) ||
                            (newForm.phones.length > 0 &&
                              newForm.phones[0].length !== 11 &&
                              newForm.phones[0].startsWith("357") === true)
                            ? errorMessage.errorMobile
                            : null
                          : null}
                      </span>
                    </Col>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <Col lg={6}>
                      <Label style={labelInput}>{local.PhoneNumber2}</Label>
                      <br />
                      <div style={{ marginTop: "4.5%" }}>
                        <PhoneInput
                          disabled={smsData.isUpdate}
                          country={setPhoneOfCountry(newForm.info.country.name)}
                          preferredCountries={["eg", "sa", "lb", "ma"]}
                          value={
                            newForm.phones !== undefined
                              ? newForm.phones[1]
                              : null
                          }
                          countryCodeEditable={false}
                          isValid={(inputNumber, country, countries) => {
                            return countries.some((country) => {
                              return (
                                startsWith(inputNumber, country.dialCode) ||
                                startsWith(country.dialCode, inputNumber)
                              );
                            });
                          }}
                          localization={getLanguage() === "ar" ? ar : false}
                          enableAreaCodes={true}
                          searchPlaceholder={local.Search}
                          disableDropdown={true}
                          enableAreaCodeStretch
                          autoFormat={"on"}
                          enableSearch={true}
                          disableSearchIcon={false}
                          placeholder={local.PhoneNumber}
                          onChange={(e, country) =>
                            this.handlePhone2(e, country)
                          }
                        />
                      </div>

                      <span style={{ color: "white", fontFamily: "Roboto" }}>
                        {newForm.phones !== undefined
                          ? newForm.phones.length === 0 ||
                            newForm.phones.length === 1 ||
                            (newForm.phones.length > 1 &&
                              newForm.phones[1].length !== 12 &&
                              newForm.phones[1].startsWith("20") === true) ||
                            (newForm.phones[1].length !== 12 &&
                              newForm.phones[1].startsWith("971") === true) ||
                            (newForm.phones[1].length !== 11 &&
                              newForm.phones[1].length !== 12 &&
                              newForm.phones[1].startsWith("966") === true) ||
                            (newForm.phones[1].length !== 10 &&
                              newForm.phones[1].length !== 11 &&
                              newForm.phones[1].startsWith("961") === true) ||
                            (newForm.phones[1].length !== 11 &&
                              newForm.phones[1].startsWith("357") === true)
                            ? errorMessage.errorMobile2
                            : null
                          : null}
                      </span>
                    </Col>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Label style={labelInput}>{local.Notes}</Label>
                      <br />
                      <div style={{ marginTop: "3.3%" }}>
                        <Input
                          disabled={smsData.isUpdate}
                          type="textarea"
                          rows="6"
                          placeholder={local.Notes}
                          style={input}
                          value={newForm.info.notes}
                          onChange={(e) =>
                            general(
                              [
                                {
                                  prop: "newForm.info.notes",
                                  value: e.target.value,
                                },
                              ],
                              UPDATE_CUSTOMERS_PROPS
                            )
                          }
                        />
                      </div>
                    </Col>
                    <br />
                    <br />
                    <br />
                    <br />
                  </Row>
                  <br /> <br />
                  {newForm.info.country.id !== null ||
                  smsData.isUpdate === true ? (
                    <Card style={mapCard}>
                      <CustomerMap
                        mapStyles={mapStyle}
                        lat={lat}
                        lng={lng}
                        latProp="lat"
                        lngProp="lng"
                        isClickable="true"
                        general={general}
                        action={UPDATE_CUSTOMERS_PROPS}
                        propPoints="newForm.locations"
                        points={
                          newForm.locations !== undefined
                            ? newForm.locations
                            : null
                        }
                        searchOptions={searchOptions}
                        MapFilter={MapFilter}
                        mapCodePoints={mapCodePoints}
                        urlLink={urlLink}
                      />
                    </Card>
                  ) : null}
                  <br /> <br /> <br /> <br />
                  <span style={{ color: "white", fontFamily: "Roboto" }}>
                    {newForm.locations !== undefined
                      ? newForm.locations.length === 0 &&
                        newForm.info.country.id !== null
                        ? errorMessage.errorPoints
                        : null
                      : null}
                  </span>
                  {newForm.locations !== undefined ? (
                    newForm.locations.length > 0 ? (
                      <Row>
                        <Col>
                          <Table
                            responsive="lg"
                            style={{
                              color: "white",
                              textAlign: "center",
                              height: "80%",
                            }}
                          >
                            <tbody>
                              <tr>
                                <th> {local.Address}</th>
                                <th>{local.Edit}</th>
                                <th>{local.Remove}</th>
                              </tr>
                              {newForm.addresses.length > 0
                                ? newForm.addresses.map((add, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <Button
                                            style={{
                                              backgroundColor: "transparent",
                                              border: 0,
                                              color: "white",
                                            }}
                                            onClick={(e) =>
                                              general(
                                                [
                                                  {
                                                    prop: "lat",
                                                    value: newForm.locations[
                                                      index
                                                    ]
                                                      .split("(")[1]
                                                      .split(" ")[1]
                                                      .split(")")[0],
                                                  },
                                                  {
                                                    prop: "lng",
                                                    value: newForm.locations[
                                                      index
                                                    ]
                                                      .split("(")[1]
                                                      .split(" ")[0],
                                                  },
                                                ],
                                                UPDATE_CUSTOMERS_PROPS
                                              )
                                            }
                                          >
                                            {add === "" ? local.NoAddress : add}
                                          </Button>
                                          <br />
                                          <span
                                            style={{
                                              color: "white",
                                              fontFamily: "Roboto",
                                            }}
                                          >
                                            {add === ""
                                              ? errorMessage.errorAddress[index]
                                              : null}
                                          </span>
                                        </td>
                                        <td>
                                          <Button
                                            style={{
                                              backgroundColor: "transparent",
                                              color: "white",
                                              border: "1px solid white",
                                            }}
                                            onClick={(e) =>
                                              general(
                                                [
                                                  {
                                                    prop:
                                                      "locationAddress.addressProp",
                                                    value:
                                                      "newForm.addresses." +
                                                      index,
                                                  },
                                                  {
                                                    prop:
                                                      "locationAddress.isOpen",
                                                    value: true,
                                                  },
                                                ],
                                                UPDATE_CUSTOMERS_PROPS
                                              )
                                            }
                                          >
                                            {local.Edit}
                                          </Button>
                                        </td>

                                        <td>
                                          <Button
                                            style={{
                                              backgroundColor: "transparent",
                                              color: "white",
                                              border: "1px solid white",
                                            }}
                                            onClick={(e) =>
                                              general(
                                                [
                                                  {
                                                    prop:
                                                      "newForm.locations." +
                                                      index,
                                                  },

                                                  {
                                                    prop:
                                                      "newForm.addresses." +
                                                      index,
                                                  },
                                                ],
                                                DELETE_CUSTOMERS_PROPS
                                              )
                                            }
                                          >
                                            {local.Remove}
                                          </Button>
                                        </td>
                                      </tr>
                                    );
                                  })
                                : null}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    ) : null
                  ) : null}
                  <Row style={{ marginTop: "5%" }}>
                    <Col>
                      <Button
                        disabled={isLoadingSave}
                        style={Agentbtn}
                        style={{
                          backgroundColor: "transparent",
                          padding: "1%",
                          paddingLeft: "6%",
                          paddingRight: "6%",
                          color: "white",
                          border: "1px solid white",
                          fontSize: "15px",
                        }}
                        onClick={(e) => this.onSave(e)}
                      >
                        {isLoadingSave === true ? (
                          <Spinner color="light" />
                        ) : (
                          local.Save
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Card>
          </center>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  CustomerR,
  CountriesR,
  CityR,
  CountiesR,
  UsersR,
}) => {
  return {
    newForm: CustomerR.newForm,
    urlLink: CustomerR.urlLink,
    locationAddress: CustomerR.locationAddress,
    countryPhone1: CustomerR.countryPhone1,
    countryPhone2: CustomerR.countryPhone2,
    mapCodePoints: CustomerR.mapCodePoints,
    isLoadingSave: CustomerR.isLoadingSave,
    errorMessage: CustomerR.errorMessage,
    lng: CustomerR.lng,
    lat: CustomerR.lat,
    searchOptions: CustomerR.searchOptions,
    countriesOptions: CountriesR.countriesOptions,
    citiesOptions: CityR.citiesOptions,
    countiesOptions: CountiesR.countiesOptions,
    usersOptions: UsersR.usersOptions,
    filterUser: UsersR.filter,
    smsData: CustomerR.smsData,
  };
};

export default connect(mapStateToProps, actions)(CreateCustomer);

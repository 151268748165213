import LocalizedStrings from "react-localization";
import { ShipmentSLANGUAGE } from "../General/StaticKeys";
import { en } from "./en";
import { ar } from "./ar";

export const local = new LocalizedStrings({
  en: en,
  ar: ar,
});

export const toogleLanguage = (lang, history) => {
  if (lang === "en") {
    localStorage.removeItem(ShipmentSLANGUAGE);

    local.setLanguage("en");
    localStorage.setItem(ShipmentSLANGUAGE, "en");
  } else {
    localStorage.removeItem(ShipmentSLANGUAGE);

    local.setLanguage("ar");
    localStorage.setItem(ShipmentSLANGUAGE, "ar");
  }
  window.location.href = "";
  window.location.reload();
  // history.push('./subdealrs')
  // history.goBack('./subdealrs')
};

export const changeLanguage = () => {
  let lang = localStorage.getItem(ShipmentSLANGUAGE);
  if (lang !== null) {
    if (lang === "en") {
      local.setLanguage("en");
      document.documentElement.setAttribute("dir", "ltr");
      document.documentElement.setAttribute("lang", "en");
      document.title = "Too Shipping";
    } else {
      local.setLanguage("ar");
      document.documentElement.setAttribute("dir", "rtl");
      document.documentElement.setAttribute("lang", "ar");
      document.title = "شحنات توو";
    }
  } else {
    local.setLanguage("ar");
    localStorage.setItem(ShipmentSLANGUAGE, "ar");
    document.documentElement.setAttribute("dir", "rtl");
    document.documentElement.setAttribute("lang", "ar");
    document.title = "شحنات توو";
  }
};

export const getLanguage = () => {
  let lang = localStorage.getItem(ShipmentSLANGUAGE);
  if (lang !== null) {
    return lang;
  }
};

export const changeDirection = () => {
  let dir = getLanguage() === "en" ? "ltr" : "rtl";
  return dir;
};

import {
  UPDATE_PRICES_PROPS,
  MERGE_PRICES_PROPS,
  DELETE_PRICES_PROPS,
} from '../Actions/types'
import * as general from './methods'

const INITIAL_STATE = {
  rtable: {
    prices: [],
  },

  newForm: { company: null, county: null, meter_price: '', second_price: '' },
  errorMessage: {
    errorCompany: '',
    errorCounty: '',
    errorMeter: '',
    errorSecond: '',
    errorCountry: '',
    errorState: '',
  },

  isLoading: false,
  isLoadingSave: false,
  isSearchable: false,
  isLoadingSearch: false,
  state: null,
  isCreated: false,
  priceId: null,
  priceIndex: null,
  searchOptions: [],
  search: { company: null, city: null, county: null, country: null },
  filter: {},
  country: null,
  pricesOptions: [],
  count: 0,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_PRICES_PROPS: {
      return general.updateProps(state, action)
    }
    case MERGE_PRICES_PROPS: {
      return general.mergeProps(state, action)
    }
    case DELETE_PRICES_PROPS: {
      return general.deleteProps(state, action)
    }
    default:
      return state
  }
}

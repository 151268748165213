import {
  UPDATE_HOME_PROPS,
  MERGE_HOME_PROPS,
  DELETE_HOME_PROPS,
} from '../Actions/types'
import * as general from './methods'

const INITIAL_STATE = {
  isLoading: false,
  usersHome: [],
  usersShipments: null,
  shipment: null,
  isView: false,
  filter: {
    object: {},
    duration: {
      from: '2006-01-02T15:04:05Z',
      to: null,
    },
    page: 0,
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_HOME_PROPS: {
      return general.updateProps(state, action)
    }
    case MERGE_HOME_PROPS: {
      return general.mergeProps(state, action)
    }
    case DELETE_HOME_PROPS: {
      return general.deleteProps(state, action)
    }
    default:
      return state
  }
}

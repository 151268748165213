// // HOME
export const UPDATE_HOME_PROPS = "UPDATE_HOME_PROPS";
export const MERGE_HOME_PROPS = "MERGE_HOME_PROPS";
export const DELETE_HOME_PROPS = "DELETE_HOME_PROPS";

// // CUSTOMERS
export const UPDATE_CUSTOMERS_PROPS = "UPDATE_CUSTOMERS_PROPS";
export const MERGE_CUSTOMERS_PROPS = "MERGE_CUSTOMERS_PROPS";
export const DELETE_CUSTOMERS_PROPS = "DELETE_CUSTOMERS_PROPS";

// // AGENTS
export const UPDATE_AGENTS_PROPS = "UPDATE_AGENTS_PROPS";
export const MERGE_AGENTS_PROPS = "MERGE_AGENTS_PROPS";
export const DELETE_AGENTS_PROPS = "DELETE_AGENTS_PROPS";

// // TRACKING
export const UPDATE_TRACKING_PROPS = "UPDATE_TRACKING_PROPS";
export const MERGE_TRACKING_PROPS = "MERGE_TRACKING_PROPS";
export const DELETE_TRACKING_PROPS = "DELETE_TRACKING_PROPS";

// // LOGIN
export const UPDATE_LOGIN_PROPS = "UPDATE_LOGIN_PROPS";
export const MERGE_LOGIN_PROPS = "MERGE_LOGIN_PROPS";
export const DELETE_LOGIN_PROPS = "DELETE_LOGIN_PROPS";

// // COMPANIES
export const UPDATE_COMPANIES_PROPS = "UPDATE_COMPANIES_PROPS";
export const MERGE_COMPANIES_PROPS = "MERGE_COMPANIES_PROPS";
export const DELETE_COMPANIES_PROPS = "DELETE_COMPANIES_PROPS";

// // COUNTIES

export const UPDATE_COUNTIES_PROPS = "UPDATE_COUNTIES_PROPS";
export const MERGE_COUNTIES_PROPS = "MERGE_COUNTIES_PROPS";
export const DELETE_COUNTIES_PROPS = "DELETE_COUNTIES_PROPS";

// // CITIES
export const UPDATE_CITIES_PROPS = "UPDATE_CITIES_PROPS";
export const MERGE_CITIES_PROPS = "MERGE_CITIES_PROPS";
export const DELETE_CITIES_PROPS = "DELETE_CITIES_PROPS";

// // COUNTRY
export const UPDATE_COUNTRY_PROPS = "UPDATE_COUNTRY_PROPS";
export const MERGE_COUNTRY_PROPS = "MERGE_COUNTRY_PROPS";
export const DELETE_COUNTRY_PROPS = "DELETE_COUNTRY_PROPS";

// // SHIPMENTS
export const UPDATE_SHIPMENTS_PROPS = "UPDATE_SHIPMENTS_PROPS";
export const MERGE_SHIPMENTS_PROPS = "MERGE_SHIPMENTS_PROPS";
export const DELETE_SHIPMENTS_PROPS = "DELETE_SHIPMENTS_PROPS";

// // USERS
export const UPDATE_USERS_PROPS = "UPDATE_USERS_PROPS";
export const MERGE_USERS_PROPS = "MERGE_USERS_PROPS";
export const DELETE_USERS_PROPS = "DELETE_USERS_PROPS";

// // ADMIN_HOME
export const UPDATE_ADMIN_HOME_PROPS = "UPDATE_ADMIN_HOME_PROPS";
export const MERGE_ADMIN_HOME_PROPS = "MERGE_ADMIN_HOME_PROPS";
export const DELETE_ADMIN_HOME_PROPS = "DELETE_ADMIN_HOME_PROPS";

// // CUSTOMER_HOME
export const UPDATE_CUSTOMER_HOME_PROPS = "UPDATE_CUSTOMER_HOME_PROPS";
export const MERGE_CUSTOMER_HOME_PROPS = "MERGE_CUSTOMER_HOME_PROPS";
export const DELETE_CUSTOMER_HOME_PROPS = "DELETE_CUSTOMER_HOME_PROPS";

// // TRACKING_HISTORY
export const UPDATE_TRACKING_HISTORY_PROPS = "UPDATE_TRACKING_HISTORY_PROPS";
export const MERGE_TRACKING_HISTORY_PROPS = "MERGE_TRACKING_HISTORY_PROPS";
export const DELETE_TRACKING_HISTORY_PROPS = "DELETE_TRACKING_HISTORY_PROPS";

// // PRICES
export const UPDATE_PRICES_PROPS = "UPDATE_PRICES_PROPS";
export const MERGE_PRICES_PROPS = "MERGE_PRICES_PROPS";
export const DELETE_PRICES_PROPS = "DELETE_PRICES_PROPS";

// // STATISTICS
export const UPDATE_STATISTICS_PROPS = "UPDATE_STATISTICS_PROPS";
export const MERGE_STATISTICS_PROPS = "MERGE_STATISTICS_PROPS";
export const DELETE_STATISTICS_PROPS = "DELETE_STATISTICS_PROPS";

import React, { Component } from "react";
import AdminLoginForm from "./AdminLoginForm";
import { connect } from "react-redux";
import * as actions from "../../Redux/Actions/index";
import { UPDATE_LOGIN_PROPS } from "../../Redux/Actions/types";
import { USERTYPE } from "../../General/StaticKeys";
import { isAuthorized } from "../../General/GeneralFunctions";
class AdminLogin extends Component {
  setUserType = () => {
    const { general, history } = this.props;
    if (isAuthorized() === false) {
      general([{ prop: "userType", value: "Admin" }], UPDATE_LOGIN_PROPS);
      localStorage.setItem(USERTYPE, "Admin");
    } else {
      history.push("/subdealrs/home");
    }
  };

  componentDidMount() {
    this.setUserType();
  }

  unsetForm = () => {
    const { general } = this.props;

    general(
      [
        { prop: "adminAuth", value: { name: "", password: "", role: 0 } },
        { prop: "userType", value: "" },
        { prop: "isViewAdminPassword", value: "password" },

        {
          prop: "ErrorMessage",
          value: {
            ereorUserName: "",
            errorPassword: "",
          },
        },
      ],
      UPDATE_LOGIN_PROPS
    );
  };

  componentWillMount() {
    this.unsetForm();
  }

  render() {
    const {
      ErrorMessage,
      isLoading,
      general,
      history,
      login,
      adminAuth,
      isViewAdminPassword,
    } = this.props;

    return (
      <AdminLoginForm
        ErrorMessage={ErrorMessage}
        isLoading={isLoading}
        general={general}
        history={history}
        login={login}
        adminAuth={adminAuth}
        isViewAdminPassword={isViewAdminPassword}
      />
    );
  }
}

const mapStateToProps = ({ LoginR }) => {
  return {
    adminAuth: LoginR.adminAuth,
    isViewAdminPassword: LoginR.isViewAdminPassword,
    ErrorMessage: LoginR.ErrorMessage,
    isLoading: LoginR.isLoading,
    userType: LoginR.userType,
  };
};
export default connect(mapStateToProps, actions)(AdminLogin);

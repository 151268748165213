import {
  UPDATE_USERS_PROPS,
  MERGE_USERS_PROPS,
  DELETE_USERS_PROPS,
} from '../Actions/types'
import * as general from './methods'
import { getLanguage } from '../../Localization/local'

const INITIAL_STATE = {
  rtable: {
    users: [],
  },

  search: { phone: '', country: null, state: null, county: null },

  filter: {
    object: {},
    duration: {
      from: '2006-01-02T15:04:05Z',
      to: null,
    },
    page: 0,
  },
  newForm: {
    name: '',
    password: '',
    info: {
      name: '',
      address: '',
      image: null,
      country: { id: null, name: '' },
      state: { id: null, name: '' },
      countyName: '',
    },
    phone: '',
    company: null,
    county: null,
    location: '',
    is_active: '',
  },
  isViewPassword: 'password',

  errorMessage: {
    errorName: '',
    errorName2: '',
    errorPhone: '',
    errorCounty: '',
    errorConfirmPassword: '',
    errorAddress: '',
    errorLocation: '',
    errorPassword: '',
    errorCountry: '',
    errorCity: '',
    errorStatus: '',
    errorImage: '',
  },

  statusOptions: [
    { label: getLanguage() === 'en' ? 'Active' : 'نشط', value: '1' },
    { label: getLanguage() === 'en' ? 'In Active' : 'غير نشيط', value: '0' },
  ],
  mapCodePoints: { code: '', lat: '', lng: '' },
  user: null,
  isLoading: false,
  isLoadingSave: false,
  isLoadingSearch: false,
  isSearchable: false,
  isCreated: false,
  userId: null,
  userIndex: null,
  usersOptions: [],
  count: 0,
  lat: '',
  lng: '',
  searchOptions: [],
  countryPhone: '',
  countrySearchPhone: '',
  isViewConfirmPassword: 'password',
  confirmPassword: '',
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_USERS_PROPS: {
      return general.updateProps(state, action)
    }
    case MERGE_USERS_PROPS: {
      return general.mergeProps(state, action)
    }
    case DELETE_USERS_PROPS: {
      return general.deleteProps(state, action)
    }
    default:
      return state
  }
}

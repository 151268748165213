import { Request } from "./Api";
import {
  UPDATE_AGENTS_PROPS,
  UPDATE_SHIPMENTS_PROPS,
  MERGE_AGENTS_PROPS,
} from "./types";
import { checkisAuthorized } from "../../General/GeneralFunctions";
import toast from "toasted-notes";
import { local } from "../../Localization/local";
import { ShipmentSUSER } from "../../General/StaticKeys";
import { findDefaultItem } from "../../General/SelectFunctions";
export const fetchAgents = (history, filter, isFetchAdmin) => async (
  dispatch
) => {
  if (checkisAuthorized() === false) {
    history.push("/");
  } else {
    if (
      filter.object.ssn !== undefined ||
      filter.object.company !== undefined ||
      filter.object.name !== undefined
    ) {
      if (filter.duration.to !== null && isFetchAdmin === undefined) {
        dispatch({
          type: UPDATE_AGENTS_PROPS,
          payload: [
            { prop: "isLoading", value: true },
            { prop: "isCreated", value: false },
            { prop: "isEditCompanyName", value: false },
          ],
        });
      }
      if (filter.duration.to === null && isFetchAdmin === undefined) {
        dispatch({
          type: UPDATE_AGENTS_PROPS,
          payload: [
            { prop: "isLoadingSearch", value: true },
            { prop: "isLoading", value: false },
            { prop: "isSearchable", value: true },
            { prop: "isCreated", value: false },
            { prop: "isEditCompanyName", value: false },
          ],
        });
      }
    } else {
      dispatch({
        type: UPDATE_AGENTS_PROPS,
        payload: [
          { prop: "isLoading", value: true },
          { prop: "isSearchable", value: false },
          { prop: "isCreated", value: false },
          { prop: "isEditCompanyName", value: false },
        ],
      });
    }
    if (filter.object.ssn === "") {
      delete filter.object.ssn;
    }
    let date = new Date();
    date.setDate(date.getDate() + 1);

    filter.duration.to = date.toISOString();
    Request()
      .post("/agent", filter)
      .then(async (response) => {
        // if (response.data.status === true) {
        if (
          response.data.data !== null &&
          Object.keys(response.data.data).length !== 0 &&
          response.data.data.constructor === Object
        ) {
          response.data.data.items.map(async (entry) => {
            Request()
              .post(
                "/agent/retrive/" +
                  entry.company +
                  "/" +
                  entry.id +
                  "/" +
                  "image",
                {}
              )
              .then((res) => {
                if (res.data !== null) {
                  entry.info.image = res.data;
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally();
            Request()
              .post(
                "/agent/retrive/" +
                  entry.company +
                  "/" +
                  entry.id +
                  "/" +
                  "driving_license_photo",
                {}
              )
              .then((res) => {
                if (res.data !== null) {
                  entry.info.driving_license_photo = {
                    value: res.data,
                    type: "image",
                  };
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally();

            Request()
              .post(
                "/agent/retrive/" +
                  entry.company +
                  "/" +
                  entry.id +
                  "/" +
                  "driver_photo_with_car",
                {}
              )
              .then((res) => {
                if (res.data !== null) {
                  entry.info.driver_photo_with_car = {
                    value: res.data,
                    type: "image",
                  };
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally();

            Request()
              .post(
                "/agent/retrive/" +
                  entry.company +
                  "/" +
                  entry.id +
                  "/" +
                  "car_insurance_photo",
                {}
              )
              .then((res) => {
                if (res.data !== null) {
                  entry.info.car_insurance_photo = {
                    value: res.data,
                    type: "image",
                  };
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally();

            Request()
              .post(
                "/agent/retrive/" +
                  entry.company +
                  "/" +
                  entry.id +
                  "/" +
                  "driving_license_photo",
                {}
              )
              .then((res) => {
                if (res.data !== null) {
                  entry.info.driving_license_photo.value = res.data;
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally();

            Request()
              .post(
                "/agent/retrive/" +
                  entry.company +
                  "/" +
                  entry.id +
                  "/" +
                  "car_image_with_plate_number",
                {}
              )
              .then((res) => {
                if (res.data !== null) {
                  entry.info.car_image_with_plate_number = {
                    value: res.data,
                    type: "image",
                  };
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally();

            Request()
              .post(
                "/agent/retrive/" +
                  entry.company +
                  "/" +
                  entry.id +
                  "/" +
                  "car_license_photo",
                {}
              )
              .then((res) => {
                if (res.data !== null) {
                  entry.info.car_license_photo = {
                    value: res.data,
                    type: "image",
                  };
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally();
          });
          await dispatch({
            type: UPDATE_AGENTS_PROPS,
            payload: [
              { prop: "rtable.agents", value: response.data.data.items },
              { prop: "count", value: response.data.data.pages },
            ],
          });
        } else {
          if (
            filter.object.ssn !== undefined ||
            filter.object.company !== undefined ||
            filter.object.name !== undefined
          ) {
            toast.notify(local.SearchResult, {
              position: "top-right",
              duration: 2000,
            });
          }
          dispatch({
            type: UPDATE_AGENTS_PROPS,
            payload: [{ prop: "rtable.agents", value: [] }],
          });
        }
        // } else {
        //   if (
        //     filter.object.ssn !== undefined ||
        //     filter.object.company !== undefined ||
        //     filter.object.name !== undefined
        //   ) {
        //     toast.notify(local.SearchResult, {
        //       position: "top-right",
        //       duration: 2000,
        //     });
        //   }
        //   dispatch({
        //     type: UPDATE_AGENTS_PROPS,
        //     payload: [{ prop: "rtable.agents", value: [] }],
        //   });
        // }
        dispatch({
          type: UPDATE_AGENTS_PROPS,
          payload: [
            { prop: "isLoading", value: false },
            { prop: "isLoadingSearch", value: false },
          ],
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: UPDATE_AGENTS_PROPS,
          payload: [
            { prop: "isLoading", value: false },
            { prop: "isLoadingSearch", value: false },
          ],
        });
      })
      .finally();
  }
};

export const modifyAgent = (newForm, history, index) => (dispatch) => {
  dispatch({
    type: UPDATE_AGENTS_PROPS,
    payload: [{ prop: "isLoadingSave", value: true }],
  });

  let url = null;
  if (newForm.id === undefined && newForm.info.image !== undefined) {
    if (newForm.info.image !== null) {
      if (newForm.info.image[0] instanceof File) {
        uploadAgentImage(
          newForm.info.image,
          newForm.id,
          JSON.parse(localStorage.getItem(ShipmentSUSER)).id,
          "image"
        );
      }
    }
    url = "/agent/new";
  } else {
    if (
      newForm.info.image !== undefined &&
      newForm.info.image !== null &&
      newForm.info.image !== ""
    ) {
      if (newForm.info.image.constructor === Array) {
        if (newForm.info.image[0] instanceof File) {
          delete newForm.county_detail;
          uploadAgentImage(
            newForm.info.image,
            newForm.id,
            newForm.company,
            "image"
          );
        }
      }
    }

    if (newForm.info.image !== undefined) {
      delete newForm.info.image;
    }

    if (newForm.info.driving_license_photo !== undefined) {
      if (newForm.info.driving_license_photo.value[0] instanceof File) {
        uploadAgentImage(
          newForm.info.driving_license_photo.value,
          newForm.id,
          newForm.company,
          "driving_license_photo"
        );
        delete newForm.info.driving_license_photo;
      } else {
        delete newForm.info.driving_license_photo;
      }
    }

    if (newForm.info.car_license_photo !== undefined) {
      if (newForm.info.car_license_photo.value[0] instanceof File) {
        uploadAgentImage(
          newForm.info.car_license_photo.value,
          newForm.id,
          newForm.company,
          "car_license_photo"
        );
        delete newForm.info.car_license_photo;
      } else {
        delete newForm.info.car_license_photo;
      }
    }

    if (newForm.info.driver_photo_with_car !== undefined) {
      if (newForm.info.driver_photo_with_car.value[0] instanceof File) {
        uploadAgentImage(
          newForm.info.driver_photo_with_car.value,
          newForm.id,
          newForm.company,
          "driver_photo_with_car"
        );
        delete newForm.info.driver_photo_with_car;
      } else {
        delete newForm.info.driver_photo_with_car;
      }
    }

    if (newForm.info.car_insurance_photo !== undefined) {
      if (newForm.info.car_insurance_photo.value[0] instanceof File) {
        uploadAgentImage(
          newForm.info.car_insurance_photo.value,
          newForm.id,
          newForm.company,
          "car_insurance_photo"
        );
        delete newForm.info.car_insurance_photo;
      } else {
        delete newForm.info.car_insurance_photo;
      }
    }

    if (newForm.info.car_image_with_plate_number !== undefined) {
      if (newForm.info.car_image_with_plate_number.value[0] instanceof File) {
        uploadAgentImage(
          newForm.info.car_image_with_plate_number.value,
          newForm.id,
          newForm.company,
          "car_image_with_plate_number"
        );
        delete newForm.info.car_image_with_plate_number;
      } else {
        delete newForm.info.car_image_with_plate_number;
      }
    }

    if (newForm.info.car_license_photo !== undefined) {
      if (newForm.info.car_license_photo.value[0] instanceof File) {
        uploadAgentImage(
          newForm.info.car_license_photo.value,
          newForm.id,
          newForm.company,
          "car_license_photo"
        );
        delete newForm.info.car_license_photo;
      } else {
        delete newForm.info.car_license_photo;
      }
    }

    url = "agent/update";
  }
  Request()
    .post(url, newForm)
    .then(async (response) => {
      if (response.data.status === true) {
        if (index === null) {
          await dispatch({
            type: UPDATE_AGENTS_PROPS,
            payload: [{ prop: "isCreated", value: true }],
          });
          toast.notify(local.Successed, {
            position: "top-right",
            duration: 2000,
          });
        } else {
          dispatch({
            type: UPDATE_AGENTS_PROPS,
            payload: [
              {
                prop: "rtable.agents." + index,
                value: newForm,
              },
            ],
          });
          toast.notify(local.Edited, {
            position: "top-right",
            duration: 2000,
          });
        }
        history.push("/subdealrs/agents");
      } else {
        if (response.data.error.message !== null) {
          if (response.data.error.message[0] === "invalid data supplied") {
            toast.notify(local.UserNameID, {
              position: "top-right",
              duration: 2000,
            });
          }
          if (
            response.data.error.message[0].includes(
              "agents_31_ssn_password_company_idx"
            )
          ) {
            toast.notify(local.UniqueID, {
              position: "top-right",
              duration: 2000,
            });
          }
          if (
            response.data.error.message[0].includes("agents_unique_name_37_ctx")
          ) {
            toast.notify(local.UniqueUserName, {
              position: "top-right",
              duration: 2000,
            });
          }

          if (
            response.data.error.message[0].includes(
              "Request body must not be larger than 1MB"
            )
          ) {
            toast.notify(local.AllDocumentsLength, {
              position: "top-right",
              duration: 2000,
            });
          } else {
            response.data.error.message.map((mess) => {
              toast.notify(mess, {
                position: "top-right",
                duration: 2000,
              });
            });
          }
        }
      }
      dispatch({
        type: UPDATE_AGENTS_PROPS,
        payload: [{ prop: "isLoadingSave", value: false }],
      });
    })

    .catch((error) => {
      dispatch({
        type: UPDATE_AGENTS_PROPS,
        payload: [{ prop: "isLoadingSave", value: false }],
      });
    })
    .finally();
};

export const getAgentsforDropDown = (filter) => (dispatch) => {
  let date = new Date();
  date.setDate(date.getDate() + 1);

  filter.duration.to = date.toISOString();
  Request()
    .post("/agent", filter)
    .then((response) => {
      // if (response.data.status === true) {
      if (response.data.data.items !== null) {
        let agents = [];
        let NonActives = [];
        response.data.data.items.map((item) => {
          agents.push({
            label: item.name,
            value: item.id,
            phone: item.phone,
            company: item.company,
          });

          if (item.is_active === 1) {
            NonActives.push({
              label: item.name,
              value: item.id,
              phone: item.phone,
              company: item.company,
            });
          }
        });

        dispatch({
          type: UPDATE_AGENTS_PROPS,
          payload: [
            { prop: "agentsOptions", value: agents },
            { prop: "inActiveAgents", value: NonActives },
          ],
        });
      }
      // }
    })
    .catch((error) => {
      // toast.notify(local.InternetConnection, {
      //   position: 'top-right',
      //   duration: 2000,
      // })
      console.log(error);
    })
    .finally();
};

export const getAgentById = (id, history, index) => (dispatch) => {
  dispatch({
    type: UPDATE_SHIPMENTS_PROPS,
    payload: [{ prop: "isLoadingCustomer", value: index }],
  });

  let date = new Date();
  date.setDate(date.getDate() + 1);

  let filter = {
    object: {
      id: id,
    },
    duration: {
      from: "2006-01-02T15:04:05Z",
      to: date.toISOString(),
    },
  };
  Request()
    .post("/agent", filter)
    .then((response) => {
      // if (response.data.status === true) {
      if (response.data.data.items !== null) {
        if (response.data.data.items[0].info.image === undefined) {
          response.data.data.items[0].info.image = null;
        }
        if (response.data.data.items[0].info.country === undefined) {
          response.data.data.items[0].info.country = { id: null, name: "" };
        }
        if (response.data.data.items[0].info.state === undefined) {
          response.data.data.items[0].info.state = { id: null, name: "" };
        }
        if (response.data.data.items[0].info.county === undefined) {
          response.data.data.items[0].info.county = { id: null, name: "" };
        }

        if (response.data.data.items[0].info.details === undefined) {
          response.data.data.items[0].info.details = "";
        }

        if (response.data.data.items[0].info.orderId === undefined) {
          response.data.data.items[0].info.orderId = "";
        }

        if (response.data.data.items[0].info.notes === undefined) {
          response.data.data.items[0].info.notes = "";
        }

        if (response.data.data.items[0].info.userName === undefined) {
          response.data.data.items[0].info.userName = "";
        }

        if (response.data.data.items[0].info.agentName === undefined) {
          response.data.data.items[0].info.agentName = "";
        }

        dispatch({
          type: UPDATE_AGENTS_PROPS,
          payload: [{ prop: "newForm", value: response.data.data.items[0] }],
        });

        history.push("agents/viewagent");
      }
      // }
      dispatch({
        type: UPDATE_SHIPMENTS_PROPS,
        payload: [{ prop: "isLoadingCustomer", value: null }],
      });
    })
    .catch((error) => {
      console.log(error);
    })
    .finally();
};

export const getEvaluation = (filter, action, prop) => (dispatch) => {
  Request()
    .post("/agent/getComments", filter)
    .then((response) => {
      if (response.data.status === true) {
        if (response.data.data !== null) {
          dispatch({
            type: action,
            payload: [{ prop: prop, value: response.data.data }],
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally();
};

export const retriveImage = (id, company, key) => {
  Request()
    .post("/agent/retrive/" + company + "/" + id + "/" + key, {})
    .then((response) => {
      if (response.data !== null) {
        return response.data;
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally();
};

export const uploadAgentImage = (file, agentID, companyID, key) => {
  let formImage = new FormData();

  formImage.append("myFile", file[0]);
  Request()
    .post("/agent/upload/" + companyID + "/" + agentID + "/" + key, formImage)
    .then((response) => {})
    .catch((error) => {
      console.log(error);
    })
    .finally();
};

export const createِAgentsFormFile = (agents, CitiesCounties, history) => (
  dispatch
) => {
  dispatch({
    type: UPDATE_AGENTS_PROPS,
    payload: [{ prop: "fileData.isLoading", value: true }],
  });
  agents.map((agent, index) => {
    let newForm = {
      name: agent.username,
      company: JSON.parse(localStorage.getItem(ShipmentSUSER)).id,
      ssn: agent.ssn,
      county: findDefaultItem(agent.county, CitiesCounties).value,
      info: {
        name: agent.name,
        address: agent.address,
        phone: agent.phone,
        image: null,
        companyName: JSON.parse(localStorage.getItem(ShipmentSUSER)).name,
      },
      password: agent.password,
      is_active:
        agent.is_active === "yes" ||
        agent.is_active === "Yes" ||
        agent.is_active === "YES"
          ? 1
          : 0,
    };
    Request()
      .post("/agent/new", newForm)
      .then((response) => {
        if (response.data.status === true) {
          if (agents.length - 1 === index) {
            toast.notify(local.Successed, {
              position: "top-right",
              duration: 2000,
            });

            history.push("/subdealrs/agents");
            dispatch({
              type: UPDATE_AGENTS_PROPS,
              payload: [
                {
                  prop: "fileData",
                  value: {
                    columns: [],
                    data: [],
                    myFile: null,
                    isLoading: false,
                    CitiesCounties: [],
                  },
                },
              ],
            });
          }
        } else {
          if (response.data.error.message !== null) {
            response.data.error.message.map((mess) => {
              toast.notify(mess + local.errorInClient + " " + newForm.name, {
                position: "top-right",
                duration: 2000,
              });
            });
          }
        }
        dispatch({
          type: UPDATE_AGENTS_PROPS,
          payload: [{ prop: "fileData.isLoading", value: false }],
        });
      })

      .catch((error) => {
        dispatch({
          type: UPDATE_AGENTS_PROPS,
          payload: [{ prop: "fileData.isLoading", value: false }],
        });
      })
      .finally();
  });
};

export const getCityandCounty = (filterCity, filterCounty) => (dispatch) => {
  Request()
    .post("/states", filterCity)
    .then((response) => {
      if (response.data.status === true) {
        if (response.data.data.items.States !== null) {
          response.data.data.items.States.map((city) => {
            dispatch({
              type: MERGE_AGENTS_PROPS,
              payload: [
                {
                  prop: "fileData.CitiesCounties",
                  value: {
                    label: filterCity.object.en_name,
                    value: city.id,
                    country: city.country,
                  },
                },
              ],
            });
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally();

  Request()
    .post("/counties", filterCounty)
    .then((response) => {
      if (response.data.data.items.Counties !== null) {
        response.data.data.items.Counties.map((county) => {
          dispatch({
            type: MERGE_AGENTS_PROPS,
            payload: [
              {
                prop: "fileData.CitiesCounties",
                value: {
                  label: filterCounty.object.en_name,
                  value: county.id,
                  state: county.state,
                },
              },
            ],
          });
        });
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally();
};

import React, { Component } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import ship from "../../assets/img/brand/ship.png";
import call from "../../assets/img/brand/call.png";
import { local, getLanguage } from "../../Localization/local";
import Rate from "./Rate";
import { numberToArabic } from "number-to-arabic";
import SlidingPanel from "react-sliding-side-panel";
import Message from "./Message";

import MobileView from "./MobileView";
import { UPDATE_SHIPMENTS_PROPS } from "../../Redux/Actions/types";
// const Wrapper = {
//   position: 'fixed',
//   bottom: 0,
//   left: 0,
//   // width: '',
//   width: '100%',
//   maxWidth: '100%',
//   backgroundColor: 'red',
//   height: this.props.isOpenPanel === false ? '10%' : null,
// }

const WrapperDiv = {
  position: "relative",
  bottom: 0,
  left: 0,
  width: "100%",
  height: "35%",
  float: "left",
  maxHeight: "50%",
  backgroundColor: "#FA6E3E",
  borderTopLeftRadius: "5%",
  borderTopRightRadius: "5%",
};

const WrapperDiv2 = {
  position: "relative",
  bottom: 0,
  left: 0,
  width: "100%",
  height: "100%",
  float: "left",
  // maxHeight: '40%',
  backgroundColor: "#FA6E3E",
  borderTopLeftRadius: "15px",
  borderTopRightRadius: "15px",
  marginTop: "-5%",
};

const CardStyle = {
  // top: 4,
  left: 0,
  bottom: 0,
  width: "100%",
  backgroundColor: "#121212",
  border: 0,
  height: "280%",
  borderTopLeftRadius: "4%",
  borderTopRightRadius: "4%",
};

const CardTabStyle = {
  // top: ,
  left: 0,
  width: "100%",
  bottom: 0,
  backgroundColor: "#121212",
  border: 0,
  height: "100%",
  borderTopLeftRadius: "15px",
  borderTopRightRadius: "15px",
};

class MapCard extends Component {
  onViewPhone = (e) => {
    const { general, webView } = this.props;
    e.preventDefault();
    if (webView.agent !== undefined) {
      general(
        [
          {
            prop: "isOpenPanel",
            value: false,
          },
          {
            prop: "phoneView",
            value: {
              title: local.PhoneNumberofAgent,
              phone: webView.agent.info.phone,
              isOpen: true,
            },
          },
        ],
        UPDATE_SHIPMENTS_PROPS
      );
    }
  };

  onOpen = (e) => {
    const { general } = this.props;
    e.preventDefault();
    general([{ prop: "isOpenPanel", value: true }], UPDATE_SHIPMENTS_PROPS);
  };

  onClose = () => {
    const { general } = this.props;
    general([{ prop: "isOpenPanel", value: false }], UPDATE_SHIPMENTS_PROPS);
  };

  onUpdateAgentloc = (e) => {
    const { general, agentLat, agentLng } = this.props;
    e.preventDefault();
    general(
      [
        {
          prop: "webViewlat",
          value: agentLat,
        },
        {
          prop: "webViewlng",
          value: agentLng,
        },
      ],
      UPDATE_SHIPMENTS_PROPS
    );
  };

  onUpdateCustomertloc = (e) => {
    const { general, customerLat, customerLng, linesView } = this.props;
    e.preventDefault();

    general(
      [
        {
          prop: "goToAgentLocation",
          value: true,
        },
      ],
      UPDATE_SHIPMENTS_PROPS
    );
  };
  render() {
    const {
      webView,
      phoneView,
      general,
      isOpenPanel,
      history,
      comment,
      makeRate,
    } = this.props;
    return (
      <>
        <center>
          <Message
            comment={comment}
            action={UPDATE_SHIPMENTS_PROPS}
            general={general}
            agentID={webView.agent !== undefined ? webView.agent.id : 0}
            makeRate={makeRate}
            history={history}
          />
          <MobileView
            phoneView={phoneView}
            action={UPDATE_SHIPMENTS_PROPS}
            general={general}
          />

          <div
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              maxWidth: "100%",
              height: "10%",
            }}
          >
            <Row>
              <Col lg={12} xs={12}>
                <a
                  href
                  style={{ cursor: "pointer" }}
                  onClick={(e) => this.onUpdateCustomertloc(e)}
                >
                  <Card
                    className="notFound"
                    style={{
                      borderRadius: "180%",
                      direction: "ltr",
                      marginLeft: "1%",
                      bottom: 10,
                      position: "fixed",
                      padding: 10,
                      paddingLeft: 14,
                      paddingRight: 14,
                      zIndex: 1000,
                      left: getLanguage() === "en" ? 0 : null,
                      marginTop: "-13%",
                    }}
                  >
                    <center>
                      <l
                        className="fa fa-crosshairs"
                        style={{
                          marginTop: "1px",
                          width: "100%",
                          height: "100%",
                          fontSize: "25px",
                          color: "white",
                        }}
                      ></l>
                    </center>
                  </Card>
                </a>
              </Col>
            </Row>

            <Row>
              <Col lg={5} md={6} sm={8} xs={12}>
                <br /> <br /> <br /> <br />
                <div style={WrapperDiv2}>
                  {isOpenPanel === false ? (
                    <a
                      href={true}
                      onClick={(e) => this.onOpen(e)}
                      style={{ cursor: "pointer" }}
                    >
                      <Card style={CardTabStyle} className="tabCard">
                        <p
                          style={{
                            color: "white",
                            marginTop: "3%",
                            WebkitAlignItems: "center",
                            textAlign: "center",
                            fontSize: "16px",
                            fontFamily: "cursive",
                          }}
                        >
                          {local.tabToOpen}
                        </p>
                      </Card>
                    </a>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </center>
        <SlidingPanel type={"bottom"} isOpen={isOpenPanel} size={30}>
          <center>
            <div
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                // width: '',
                width: "100%",
                maxWidth: "100%",
              }}
            >
              <Row>
                <Col lg={5} md={6} sm={8} xs={12}>
                  <div style={WrapperDiv}>
                    <a
                      href={true}
                      style={{ cursor: "pointer" }}
                      onClick={this.onClose}
                    >
                      <div
                        style={{
                          width: "transparent",
                          top: 0,
                          borderTopLeftRadius: "4%",
                          borderTopRightRadius: "4%",
                        }}
                      >
                        <p
                          style={{
                            width: "transparent",
                            padding: 3,
                            color: "white",
                            borderTopLeftRadius: "4%",
                            borderTopRightRadius: "4%",
                            WebkitAlignItems: "center",
                            textAlign: "center",
                            fontSize: "16px",
                            fontFamily: "cursive",
                          }}
                        >
                          {local.tabToClose}
                        </p>
                      </div>
                    </a>
                    <Card style={CardStyle}>
                      <div style={{ marginLeft: "5%", marginRight: "5%" }}>
                        <Row style={{ marginTop: "4%", fontFamily: "Roboto" }}>
                          <Col xs={11}>
                            <img
                              src={ship}
                              width="80"
                              height="80"
                              alt=""
                              style={{ position: "fixed" }}
                            />
                            <a
                              href
                              style={{ cursor: "pointer" }}
                              onClick={(e) => this.onViewPhone(e)}
                            >
                              <img
                                alt=""
                                src={call}
                                width="45"
                                height="45"
                                style={{
                                  position: "relative",
                                  top: 50,
                                  left: 0,
                                }}
                              />
                            </a>
                          </Col>
                          <br /> <br /> <br /> <br />
                          <Col xs={6}>
                            <p style={{ color: "white" }}>{local.AgentName}</p>
                            <p style={{ color: "white" }}>{local.ArrivingAt}</p>
                            <p style={{ color: "white" }}>
                              {local.shippingCompany}
                            </p>
                            <p style={{ color: "white" }}>{local.User}</p>

                            {webView.shipment !== undefined
                              ? (
                                  <p style={{ color: "white" }}>
                                    {local.ShipmentDetails}
                                  </p>
                                ) !== undefined
                                ? webView.shipment.info.details
                                : null
                              : null}
                          </Col>
                          <Col>
                            <p style={{ color: "#FA6E3E" }}>
                              {webView.agent !== undefined
                                ? webView.agent.name
                                : null}
                            </p>
                            <p style={{ color: "#FA6E3E" }}>
                              {webView.shipment !== undefined
                                ? getLanguage() === "ar"
                                  ? new Date(
                                      webView.shipment.delivery_date
                                    ).toLocaleTimeString("ar-eg")
                                  : new Date(
                                      webView.shipment.delivery_date
                                    ).toLocaleTimeString()
                                : null}
                            </p>
                            <p style={{ color: "#FA6E3E" }}>
                              {webView.company !== undefined
                                ? webView.company.name
                                : null}
                            </p>
                            <p style={{ color: "#FA6E3E" }}>
                              {webView.shipment !== undefined
                                ? webView.shipment.info.userName
                                : null}
                            </p>
                            <p style={{ color: "#FA6E3E" }}>
                              {webView.shipment !== undefined
                                ? webView.shipment.info.details !== undefined
                                  ? webView.shipment.info.details
                                  : null
                                : null}
                            </p>
                          </Col>
                        </Row>
                        <hr
                          style={{
                            backgroundColor: "#FAB116",
                          }}
                        />
                        <Row>
                          <Col
                            style={{
                              fontFamily: "Roboto",
                              fontSize: "20px",
                            }}
                          >
                            <Rate history={history} />
                          </Col>
                          <Col>
                            <span style={{ color: "white" }}>
                              {local.YourVerfiyCode + " "}
                            </span>
                            <span
                              style={{
                                color: "#FA6E3E",
                                fontSize:
                                  getLanguage() === "ar" ? "19px" : null,
                              }}
                            >
                              {webView.shipment !== undefined
                                ? getLanguage() === "en"
                                  ? webView.shipment.code
                                  : numberToArabic(webView.shipment.code)
                                : null}
                            </span>
                          </Col>
                        </Row>
                        {comment.rate === 0 ? (
                          <Row>
                            <Col lg={6}>
                              <a
                                href
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  general(
                                    [
                                      { prop: "comment.isOpen", value: true },
                                      { prop: "isOpenPanel", value: false },
                                    ],
                                    UPDATE_SHIPMENTS_PROPS
                                  )
                                }
                              >
                                <p
                                  style={{
                                    color: "white",
                                    fontFamily: "Tajawal",
                                    fontSize: "18px",
                                  }}
                                >
                                  {local.Comments + " " + "..."}
                                </p>
                              </a>
                            </Col>
                          </Row>
                        ) : null}
                      </div>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
          </center>
        </SlidingPanel>
      </>
    );
  }
}

export default MapCard;

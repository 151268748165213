import React, { Component } from 'react'
import { Map, Polygon, Rectangle, TileLayer, Popup } from 'react-leaflet'
import FullscreenControl from 'react-leaflet-fullscreen'
import Search from 'react-leaflet-search'
import 'leaflet/dist/leaflet.css'
import 'react-leaflet-fullscreen/dist/styles.css'
import {
  MERGE_CUSTOMERS_PROPS,
  UPDATE_SHIPMENTS_PROPS,
  UPDATE_CUSTOMERS_PROPS,
} from '../Redux/Actions/types'
import { local } from '../Localization/local'
import SearchLink from './SearchMap'
export default class GeneralMap extends Component {
  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this)
    this.onClick2 = this.onClick2.bind(this)
  }

  onClick = (e) => {
    const {
      general,
      action,
      latProp,
      lngProp,
      propPoints,
      shipment,
      isAdminCustomer,
      points,
    } = this.props

    let lat = e.latlng.lat
    let lng = e.latlng.lng

    if (shipment !== undefined) {
      general(
        [
          { prop: propPoints, value: '' },
          { prop: 'newForm.address', value: '' },
        ],
        UPDATE_SHIPMENTS_PROPS,
      )
      let point =
        'POINT(' + JSON.stringify(lng) + ' ' + JSON.stringify(lat) + ')'

      general(
        [
          { prop: latProp, value: lat },
          { prop: lngProp, value: lng },
          { prop: propPoints, value: point },
        ],
        UPDATE_SHIPMENTS_PROPS,
      )
    } else {
      if (isAdminCustomer === undefined) {
        general(
          [
            { prop: latProp, value: lat },
            { prop: lngProp, value: lng },
          ],
          action,
        )

        let point =
          'POINT(' + JSON.stringify(lng) + ' ' + JSON.stringify(lat) + ')'

        general([{ prop: propPoints, value: point }], MERGE_CUSTOMERS_PROPS)
        if (points.length === 0) {
          general(
            [
              {
                prop: 'locationAddress.addressProp',
                value: 'newForm.addresses.' + 0,
              },

              {
                prop: 'newForm.addresses.' + points.length,
                value: '',
              },
              {
                prop: 'locationAddress.isOpen',
                value: true,
              },
            ],
            UPDATE_CUSTOMERS_PROPS,
          )
        }
        if (points.length > 0) {
          general(
            [
              {
                prop: 'locationAddress.addressProp',
                value: 'newForm.addresses.' + points.length,
              },
              {
                prop: 'newForm.addresses.' + points.length,
                value: '',
              },
              {
                prop: 'locationAddress.isOpen',
                value: true,
              },
            ],
            UPDATE_CUSTOMERS_PROPS,
          )
        }
      }
    }
  }

  onClick2 = (e) => {
    const { general, action, latProp, lngProp, propPoints } = this.props

    let lat = e.latlng.lat
    let lng = e.latlng.lng
    let point = 'POINT(' + JSON.stringify(lng) + ' ' + JSON.stringify(lat) + ')'

    general(
      [
        { prop: latProp, value: lat },
        { prop: lngProp, value: lng },
        { prop: propPoints, value: point },
      ],
      action,
    )
  }

  onChange = (SearchInfo) => {
    const {
      general,
      action,
      latProp,
      lngProp,
      propPoints,
      isCustomer,
      points,
    } = this.props
    let e = {
      lat: SearchInfo.latLng.lat,
      lng: SearchInfo.latLng.lng,
    }
    if (e !== null) {
      let point =
        'POINT(' + JSON.stringify(e.lng) + ' ' + JSON.stringify(e.lat) + ')'
      if (isCustomer !== undefined && isCustomer === true) {
        general(
          [
            { prop: latProp, value: e.lat },
            { prop: lngProp, value: e.lng },
          ],
          action,
        )

        general([{ prop: propPoints, value: point }], MERGE_CUSTOMERS_PROPS)
        if (points.length === 0) {
          general(
            [
              {
                prop: 'locationAddress.addressProp',
                value: 'newForm.addresses.' + 0,
              },

              {
                prop: 'newForm.addresses.' + points.length,
                value: '',
              },
              {
                prop: 'locationAddress.isOpen',
                value: true,
              },
            ],
            action,
          )
        }
        if (points.length > 0) {
          general(
            [
              {
                prop: 'locationAddress.addressProp',
                value: 'newForm.addresses.' + points.length,
              },
              {
                prop: 'newForm.addresses.' + points.length,
                value: '',
              },
              {
                prop: 'locationAddress.isOpen',
                value: true,
              },
            ],
            action,
          )
        }
      } else {
        general(
          [
            { prop: latProp, value: e.lat },
            { prop: lngProp, value: e.lng },
            { prop: propPoints, value: point },
          ],
          action,
        )
      }
    }
  }

  myPopup = (SearchInfo) => {
    return (
      <Popup>
        <div>
          <p>{local.Location}</p>
          <p>{SearchInfo.info}</p>
        </div>
      </Popup>
    )
  }
  prepareLatLng = () => {
    const { lat, lng, mapCodePoints } = this.props
    if (lat === '' && lng === '') {
      if (mapCodePoints !== undefined) {
        return [Number(mapCodePoints.lat), Number(mapCodePoints.lng)]
      } else {
        return [29.76870772300693, 29.76870772300693]
      }
    } else {
      return [Number(lat), Number(lng)]
    }
  }

  render() {
    // const polyline = [
    //   [51.505, -0.09],
    //   [51.51, -0.1],
    //   [51.51, -0.12],
    // ]

    const {
      mapStyles,
      renderMarker,
      renderPolyline,
      lng,
      lat,
      isCountyClick,
      general,
      action,
      searchOptions,
      latProp,
      lngProp,
      notSearchable,
      MapFilter,
      propPoints,
      isCustomer,
      renderMarker2,
      isResize,
      mapCodePoints,
      renderCircle,
      points,
      urlLink,
    } = this.props
    return (
      <>
        {notSearchable === undefined ? (
          <SearchLink
            MapFilter={MapFilter}
            general={general}
            action={action}
            searchOptions={searchOptions}
            latProp={latProp}
            LngProp={lngProp}
            propPoints={propPoints}
            isCustomer={isCustomer}
            mapCodePoints={mapCodePoints}
            points={points}
            urlLink={urlLink}
          />
        ) : null}
        <div
          style={{
            height: '100vh',
            width: '100%',
            overflow: 'hidden',
            textAlign: 'center',
            alignItems: 'center',
            borderColor: 'white',
            zIndex: 0,
          }}
        >
          <Map
            zoomControl={true}
            onClick={
              this.props.isClickable !== undefined
                ? isCountyClick === undefined
                  ? this.onClick
                  : this.onClick2
                : null
            }
            center={this.prepareLatLng()}
            zoom={
              lat === '' && lng === '' ? 5 : isResize === undefined ? 16 : 14
            }
            style={mapStyles}
          >
            <FullscreenControl
              position="topleft"
              title="full"
              titleCancel="cancel"
              forceSeparateButton={true}
            />
            {notSearchable === undefined ? (
              <>
                <Search
                  position="topleft"
                  popUp={this.myPopup}
                  zoom={
                    lat === '' && lng === ''
                      ? 10
                      : isResize === undefined
                      ? 10
                      : 10
                  }
                  inputPlaceholder={local.PlaceName}
                  showPopup={false}
                  closeResultsOnClick={true}
                  openSearchOnLoad={false}
                  showMarker={false}
                  keepResult={false}
                  searchBounds={[
                    [Number(mapCodePoints.lat), Number(mapCodePoints.lng)],
                  ]}
                  providerOptions={{ region: mapCodePoints.code }}
                  providerKey="BfjKqWinHFJRapRSzeUfgDpOLwY0d0Tg"
                ></Search>
              </>
            ) : null}
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {renderMarker()}
            {renderMarker2 !== undefined ? renderMarker2() : null}
            {renderPolyline !== null ? renderPolyline() : null}
            {renderCircle !== undefined ? renderCircle() : null}
          </Map>
        </div>
      </>
    )
  }
}

import {
  UPDATE_TRACKING_HISTORY_PROPS,
  MERGE_TRACKING_HISTORY_PROPS,
  DELETE_TRACKING_HISTORY_PROPS,
} from '../Actions/types'
import * as general from './methods'
import { local } from '../../Localization/local'
const INITIAL_STATE = {
  isLoadingCard: false,
  rtable: { trackingHistory: [] },
  isLoading: false,
  filter: {
    object: {},
    duration: {
      from: '2006-01-02T15:04:05Z',
      to: null,
    },
    page: 0,
  },

  search: {
    agent: null,
    client: null,
    date: null,
    company: null,
    shipmentID: '',
  },
  isLoadingSearch: false,
  isSearchable: false,
  lines: [],
  markers: [],
  lat: '',
  lng: '',
  agentName: '',
  card: {
    isView: false,
    date: null,
    percntge: null,
    order: null,
    mobile: null,
    name: null,
    id: null,
    shipments: null,
    image: null,
    performance: null,
  },
  message: { isOpen: false, sms: '', title: local.SMSMessage },
  count: 0,
  bounds: [],
  customerPoints: [],
  InfoWindowItem: {
    selectedPlace: null,
    activeMarker: null,
    showingInfoWindow: false,
    deliveryStatus: '',
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_TRACKING_HISTORY_PROPS: {
      return general.updateProps(state, action)
    }
    case MERGE_TRACKING_HISTORY_PROPS: {
      return general.mergeProps(state, action)
    }
    case DELETE_TRACKING_HISTORY_PROPS: {
      return general.deleteProps(state, action)
    }
    default:
      return state
  }
}

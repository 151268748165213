import {
  UPDATE_SHIPMENTS_PROPS,
  MERGE_SHIPMENTS_PROPS,
  DELETE_SHIPMENTS_PROPS,
} from "../Actions/types";
import * as general from "./methods";
import { getLanguage } from "../../Localization/local";

const INITIAL_STATE = {
  rtable: { shipments: [] },
  filter: {
    object: {},
    duration: {
      from: "2006-01-02T15:04:05Z",
      to: null,
    },
    page: 0,
  },

  search: {
    status: null,
    client: null,
    due_date: "",
    added_on: "",
    agent: null,
  },

  shipment: null,

  assignShipment: { country: null, county: null, state: null },
  errorAssign: { errorAgent: "" },
  qrData: [],
  newForm: {
    due_date: "",
    address: "",
    phone: "",
    location: "",
    client: null,
    company: null,
    user: null,
    info: {
      shipment_type: 0,
      clientName: "",
      userName: "",
      Preferred_delivery_time: { from: "", to: "" },
      price: "",
      payment_status: 0,
      details: "",
      confirmed: false,
      work_time: "",
    },
  },

  errorMessage: {
    errorClient: "",
    errorAddress: "",
    errorLocation: "",
    errorPhone: "",
    errorUser: "",
    errorDate: "",
    errorCompany: "",
    errorFrom: "",
    errorTo: "",
    errorPayed: "",
    errorPayment_Status: "",
    errorType: "",
  },

  multiShipment: [],

  webView: { rate: 0 },

  isLoading: false,
  isLoadingSave: false,
  isAsignLoading: false,
  isLoadingSearch: false,
  isCreated: false,
  isSearchable: false,
  isSuccessed: true,
  shipmentId: null,
  shipmentIndex: null,
  isLoadingCustomer: null,
  lat: "",
  lng: "",
  searchOptions: [],
  message: { isOpen: false, sms: "", title: "test", agentPhone: "" },
  link: { isOpen: false },
  createModel: {
    phoneOptions: [],
    addressOptions: [],
    isLoading: false,
    isOpen: false,
    newForm: {
      due_date: "",
      address: "",
      phone: "",
      location: "",
      client: null,
      company: null,
      user: null,
      info: {
        shipment_type: 0,
        clientName: "",
        userName: "",
        Preferred_delivery_time: { from: "", to: "" },
        price: "",
        payment_status: 0,
        details: "",
        confirmed: false,
        work_time: "",
      },
    },
  },

  statusType: [
    {
      label: getLanguage() === "en" ? "Delivery shipment" : "تسليم شحنة",
      value: 0,
    },
    {
      label: getLanguage() === "en" ? "Retrieve a shipment" : "استرجاع شحنة",
      value: 1,
    },
  ],

  statusPayment: [
    {
      label: getLanguage() === "en" ? "Paid" : "تم الدفع",
      value: 0,
    },
    {
      label: getLanguage() === "en" ? "Unpaid" : "غير مدفوع",
      value: 1,
    },

    {
      label: getLanguage() === "en" ? "Part Payment" : "جزء مدفوع من الشحنة",
      value: 2,
    },
  ],

  statusPayment2: [
    {
      label: getLanguage() === "en" ? "All" : "السعر كامل",
      value: 3,
    },
    {
      label: getLanguage() === "en" ? "Part retriever" : "السعر المسترد",
      value: 4,
    },
  ],

  statusOptions: [
    { label: getLanguage() === "en" ? "Created" : "انشئت", value: 0 },
    {
      label: getLanguage() === "en" ? "Assigned" : "تم التسليم للمندوب",
      value: 1,
    },
    {
      label: getLanguage() === "en" ? "Delivered" : "تم توصيل الشحنة",
      value: 2,
    },
    { label: getLanguage() === "en" ? "Rejected" : "تم رفض الشحنة", value: 3 },
    {
      label: getLanguage() === "en" ? "Canceled" : "تم الغاء التوصيل",
      value: 4,
    },
    {
      label: getLanguage() === "en" ? "Client Not Found" : "العميل غير متوفر",
      value: 5,
    },
  ],

  mapView: { location: null, mapStyles: null, isOpenDialog: false },
  linesView: {},
  bounds: [],
  polylines: [],
  count: 0,
  comment: { isOpen: false, message: "", rate: 0 },
  phoneView: { title: "", phone: "", isOpen: false },
  customerPlaces: [],
  addresses: [],
  agentName: "",
  CustomerName: "",
  ShipmentLocation: "",
  mapCodePoints: { code: "", lat: "", lng: "" },
  InfoWindowItem: {
    selectedPlace: null,
    activeMarker: null,
    showingInfoWindow: false,
    deliveryStatus: "",
  },
  webViewlat: "",
  webViewlng: "",
  goToAgentLocation: true,
  countryPhone: "",
  isOpenPanel: false,
  evaluation: [],
  country_name: "",
  isNewCustomer: false,
  customerShipments: [],

  invoice: {
    isOpen: false,
    useDeafult: false,
    uploadInvoice: false,
    invoiceFile: null,
    invoiceDate: {
      customerName: "",
      items: [],
      date: "",
      time: "",
      orderID: "",
      charge: "",
      vat: "",
      additionalTax: "",
      total: "",
    },
    openFinalInvoice: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SHIPMENTS_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_SHIPMENTS_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_SHIPMENTS_PROPS: {
      return general.deleteProps(state, action);
    }
    default:
      return state;
  }
};

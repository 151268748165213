import {
  TOKEN,
  ShipmentSUSER,
  USERTYPE,
  SHIPMENT_IP_LOCATION,
} from "./StaticKeys";
import { local } from "../Localization/local";
import { confirmAlert } from "react-confirm-alert";
import { numberToArabic } from "number-to-arabic";

export const isAuthorized = (history) => {
  let token = localStorage.getItem(TOKEN);
  if (token !== null) {
    return true;
  } else {
    return false;
  }
};

export const hexToBase64 = (str) => {
  return btoa(
    String.fromCharCode.apply(
      null,
      str
        .replace(/\r|\n/g, "")
        .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
        .replace(/ +$/, "")
        .split(" ")
    )
  );
};

export const DateToArabic = (date1) => {
  if (date1 !== undefined && date1 !== "") {
    let date = new Date(date1);
    date = date.toGMTString();
    let day = date.split(",")[0];
    let result = null;
    if (day === "Sat") {
      result = "السبت";
    } else if (day === "Sun") {
      result = "الاحد";
    } else if (day === "Mon") {
      result = "الاتنين";
    } else if (day === "Tue") {
      result = "الثلاثاء";
    } else if (day === "Wed") {
      result = "الاربعاء";
    } else if (day === "Thu") {
      result = "الخميس";
    } else {
      result = "الجمعة";
    }

    let month = date.split(",")[1].split(" ")[2];

    let arabicMonth = null;
    if (month === "Jan") {
      arabicMonth = "يناير";
    } else if (month === "Feb") {
      arabicMonth = "فبراير";
    } else if (month === "Mar") {
      arabicMonth = "مارس";
    } else if (month === "Apr") {
      arabicMonth = "ابريل";
    } else if (month === "May") {
      arabicMonth = "مايو";
    } else if (month === "June") {
      arabicMonth = "يونيو";
    } else if (month === "Jul") {
      arabicMonth = "يوليو";
    } else if (month === "Aug") {
      arabicMonth = "أغسطس";
    } else if (month === "Sep") {
      arabicMonth = "سبتمبر";
    } else if (month === "Oct") {
      arabicMonth = "اكتوبر";
    } else if (month === "Nov") {
      arabicMonth = "نوفمبر";
    } else {
      arabicMonth = "ديسمبر";
    }
    result =
      result +
      " ," +
      numberToArabic(Number(date.split(",")[1].split(" ")[1])) +
      " " +
      arabicMonth +
      " " +
      numberToArabic(Number(date.split(",")[1].split(" ")[3]));

    return result;
  }
};

export const getCountryPhone = (phone) => {
  if (phone !== undefined) {
    if (phone !== "") {
      if (phone.startsWith("20")) {
        return "Egypt";
      } else if (phone.startsWith("961")) {
        return "Lebanon";
      } else if (phone.startsWith("966")) {
        return "Saudi Arabia";
      } else {
        return "Morocco";
      }
    }
  } else {
    return "Saudi Arabia";
  }
};

export const onHandlePhoneNumber = (
  e,
  country,
  prop,
  action,
  countryPhone,
  general,
  propPhone,
  errorProp
) => {
  if (countryPhone === "") {
    general(
      [
        { prop: prop, value: e },
        { prop: propPhone, value: country },
      ],
      action
    );
  }
  if (country === countryPhone) {
    general([{ prop: prop, value: e }], action);
  }

  if (
    country !== countryPhone &&
    (country === "Morocco" ||
      country === "المغرب" ||
      country === "Lebanon" ||
      country === "لبنان" ||
      country === "Saudi Arabia" ||
      country === "السعودية")
  ) {
    general(
      [
        // { prop: prop, value: e[0] + e[1] + e[2] },
        { prop: propPhone, value: country },
        { prop: errorProp, value: "" },
      ],
      action
    );
  }
  if (country !== countryPhone && (country === "Egypt" || country === "مصر")) {
    general(
      [
        // { prop: prop, value: e[0] + e[1] },
        { prop: propPhone, value: country },
        { prop: errorProp, value: "" },
      ],
      action
    );
  }
};

export const checkHasSpace = (name) => {
  if (name !== "") {
    const reg = /\s/;
    return reg.test(String(name).toLowerCase());
  }
};

export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getUserData = () => {
  let item = JSON.parse(localStorage.getItem(ShipmentSUSER));
  if (item !== null) {
    return item;
  }
};

export const setCountryusingPhone = (phoneCountry, options) => {
  if (phoneCountry === "") {
    if (options.length === 0) {
      return "sa";
    } else {
      let item = options.filter(
        (item) => item.label === "Egypt" || item.label === "مصر"
      );

      return item[0].code;
    }
  } else {
    let entry = options.filter(
      (item) => item.label === phoneCountry || item.value === phoneCountry
    );

    if (entry.length > 0) {
      return entry[0].code;
    } else {
      return "sa";
    }
  }
};

export const checkisAuthorized = () => {
  let token = localStorage.getItem(TOKEN);

  if (token === null) {
    return false;
  } else return true;
};

export const logout = (history) => {
  let url = null;
  if (getUserType() === "Admin") {
    url = "/admin/login";
  } else {
    url = "/";
  }

  confirmAlert({
    message: local.LogoutConfirm,
    buttons: [
      {
        label: local.Yes,
        onClick: () => removeDate(history, url),
      },
      {
        onClick: () => history.goBack(),
        label: local.No,
      },
    ],
    closeOnEscape: false,
    closeOnClickOutside: false,
  });
};

export const logoutWithhoutConfirmation = (history) => {
  let url = null;
  if (getUserType() === "Admin") {
    url = "/admin/login";
  } else {
    url = "/";
  }
  removeDate(history, url);
};

export const removeDate = (history, url) => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(ShipmentSUSER);
  localStorage.removeItem(USERTYPE);
  localStorage.removeItem(SHIPMENT_IP_LOCATION);

  history.push(url);
  history.go();
};

export const convertByteToObject = (item) => {
  var response = item;
  function byteToString(bytes) {
    var result = "";
    for (var i = 0; i < bytes.length; i++) {
      result += String.fromCharCode(parseInt(bytes[i], 10));
    }
    return result;
  }

  let data = response.info.replace("[", "");
  data = data.replace("]", "");
  let getArrayOfBytes = data.split(" ");
  let getObjectString = byteToString(getArrayOfBytes);
  let finalObject = JSON.parse(getObjectString);
  return finalObject;
};

export const HasEnglishCharactor = (text) => {
  let english = /^[A-Za-z0-9]*$/;
  if (english.test(text) === true) {
    return true;
  } else {
    return false;
  }
};

// export const englishFieldText = (e, general, action, prop) => {
//   if (checkLanguageIsEnglish(e.target.value) === false) {
//     alert(local.EnglishLang)
//   } else {
//     if (checkLanguageIsEnglish(e.target.value) === true && e !== null) {
//       general(
//         [
//           {
//             prop: prop,
//             value: e.target.value,
//           },
//         ],
//         action,
//       )
//     } else {
//       general(
//         [
//           {
//             prop: prop,
//             value: '',
//           },
//         ],
//         action,
//       )
//     }
//   }
// }

export const HasArabicCharacters = (text) => {
  let arregex = /[\u0600-\u06FF]/;
  if (arregex.test(text) === true) {
    return true;
  } else return false;
};

export const setPhoneOfCountry = (country_name) => {
  if (country_name === "") {
    return "eg";
  } else {
    if (country_name === "Egypt" || country_name === "مصر") {
      return "eg";
    } else if (country_name === "Saudi Arabia" || country_name === "السعودية") {
      return "sa";
    } else if (country_name === "Morocco" || country_name === "المغرب") {
      return "ma";
    } else {
      return "lb";
    }
  }
};

export const arabicFieldText = (e, general, action, prop) => {
  if (checkLanguageIsArabic(e.target.value) === false) {
    alert(local.ArabicLang);
  } else {
    if (checkLanguageIsArabic(e.target.value) === true && e !== null) {
      general(
        [
          {
            prop: prop,
            value: e.target.value,
          },
        ],
        action
      );
    } else {
      general(
        [
          {
            prop: prop,
            value: "",
          },
        ],
        action
      );
    }
  }
};

export const englishFieldText = (e, general, action, prop) => {
  if (
    checkLanguageIsArabic(e.target.value) === false ||
    e.target.value === ""
  ) {
    if (checkLanguageIsArabic(e.target.value) === false && e !== null) {
      general(
        [
          {
            prop: prop,
            value: e.target.value,
          },
        ],
        action
      );
    } else {
      general(
        [
          {
            prop: prop,
            value: "",
          },
        ],
        action
      );
    }
  } else {
    alert(local.EnglishLang);
  }
};

export const checkLanguageIsEnglish = (value) => {
  if (HasEnglishCharactor(value) === false && value !== "") {
    return false;
  } else {
    return true;
  }
};

// export const checkKInputIsNumber = (value) => {
//   if (isNaN(value) === true && value !== '') {
//     return false
//   }
//   // else if (value.length - 1 === ' ' || value.includes(' ')) {
//   //   return true
//   // }
//   else {
//     return true
//   }
// }

export const checkLanguageIsArabic = (value) => {
  if (HasArabicCharacters(value) === false && value !== "") {
    return false;
  } else {
    return true;
  }
};
// export const getMapConfig = () => {
//   let mapConfig = JSON.parse(localStorage.getItem(IP_LOCATION))
//   if (mapConfig !== null) {
//     return mapConfig
//   }
// }

export const getUserType = () => {
  let type = localStorage.getItem(USERTYPE);
  if (type != null) {
    return type;
  }
};

export const ArrayBufferToBinary = (buffer) => {
  var uint8 = new Uint8Array(buffer);
  return uint8.reduce((binary, uint8) => binary + uint8.toString(2), "");
};

export const prepareCompanyRoute = (
  AdminLogin,
  CompanyLogin,
  TrackingLive,
  TrackingHistory,
  Home,
  Customers,
  CreateAgent,
  CreateCustomer,
  Agents,
  EditAgent,
  EditCustomer,
  ViewAgent,
  Companies,
  CreateCompany,
  EditCompany,
  Counties,
  CreateCounty,
  EditCounty,
  Cities,
  CreateCity,
  EditCity,
  Shipments,
  CreateShipment,
  EditShipment,
  Users,
  CreateUser,
  EditUser,
  AssignShipment,
  ViewShipment,
  ViewUser,
  ViewCompany,
  ViewCounty,
  AdminHome,
  ViewCustomer,
  HomeView,
  CompanySetting,
  CustomerHome,
  VirewCard,
  QrScanner,
  Prices,
  CreatePrices,
  EditPrices,
  ViewPrice,
  FileData,
  AgentFile,
  Statistics
) => {
  let route = [];
  if (getUserType() === "Shipping Company") {
    route = [
      { path: "/", exact: true, component: CompanyLogin },

      { path: "/subdealrs/dashboard", component: Home },

      {
        path: "/subdealrs/shipmentscustomer",
        exact: true,
        component: CustomerHome,
      },

      {
        path: "/subdealrs/statistics",
        exact: true,
        component: Statistics,
      },

      {
        path: "/subdealrs/shipmentscustomer/view",
        component: VirewCard,
        name: local.ViewCard,
        exact: true,
      },

      {
        path: "/subdealrs/view",
        component: HomeView,
        name: local.HomeView,
        exact: true,
      },

      {
        path: "/subdealrs/tracking/live",
        name: local.TrackingLive,
        exact: true,
        component: TrackingLive,
      },

      {
        path: "/subdealrs/tracking/history",
        name: local.TrackingHistory,
        exact: true,
        component: TrackingHistory,
      },

      {
        path: "/subdealrs/agents",
        exact: true,
        name: local.Agents,
        component: Agents,
      },

      {
        path: "/subdealrs/agents/addagent",
        name: local.AddAgent,
        component: CreateAgent,
      },

      {
        path: "/subdealrs/agents/editagent",
        name: local.EditAgent,
        component: EditAgent,
      },
      {
        path: "/subdealrs/agents/viewagent",
        name: local.ViewAgent,
        component: ViewAgent,
      },

      {
        path: "/subdealrs/users",
        exact: true,
        name: local.Users,
        component: Users,
      },

      {
        path: "/subdealrs/users/adduser",
        name: local.AddUser,
        component: CreateUser,
      },

      {
        path: "/subdealrs/users/edituser",
        name: local.EditUser,
        component: EditUser,
      },
      {
        path: "/subdealrs/users/viewuser",
        name: local.ViewUser,
        component: ViewUser,
      },

      {
        path: "/subdealrs/prices",
        exact: true,
        name: local.Prices,
        component: Prices,
      },

      {
        path: "/subdealrs/prices/addprice",
        name: local.AddPrice,
        component: CreatePrices,
      },
      {
        path: "/subdealrs/prices/editprice",
        name: local.EditPrice,
        component: EditPrices,
      },
      {
        path: "/subdealrs/prices/viewprice",
        name: local.ViewPrice,
        component: ViewPrice,
      },

      {
        path: "/subdealrs/settings",
        name: local.Settings,
        component: CompanySetting,
      },

      {
        path: "/subdealrs/shipments",
        name: local.Shipments,
        exact: true,
        component: Shipments,
      },

      {
        path: "/subdealrs/shipments/addshipment",
        name: local.AddShipment,
        exact: true,
        component: CreateShipment,
      },

      {
        path: "/subdealrs/shipments/newshipment",
        name: local.AddShipment,
        exact: true,
        component: QrScanner,
      },

      {
        path: "/subdealrs/shipments/editshipment",
        name: local.EditShipment,
        exact: true,
        component: EditShipment,
      },
      {
        path: "/subdealrs/shipments/assignshipment",
        name: local.AssignShipment,
        exact: true,
        component: AssignShipment,
      },
      {
        path: "/subdealrs/shipments/viewshipment",
        name: local.ViewShipment,
        exact: true,
        component: ViewShipment,
      },

      {
        path: "/subdealrs/customers",
        exact: true,
        name: local.Customers,
        component: Customers,
      },

      {
        path: "/subdealrs/customers/addcustomer",
        name: local.AddCustomer,
        exact: true,
        component: CreateCustomer,
      },

      {
        path: "/subdealrs/customers/importcustomer",
        name: local.AddCustomer,
        exact: true,
        component: FileData,
      },
      {
        path: "/subdealrs/agents/importagent",
        name: local.AddAgent,
        exact: true,
        component: AgentFile,
      },

      {
        path: "/subdealrs/customers/editcustomer",
        name: local.EditCustomer,
        component: EditCustomer,
      },

      {
        path: "/subdealrs/customers/viewcustomer",
        name: local.ViewCustomer,
        component: ViewCustomer,
      },
      {
        path: "/subdealrs/counties",
        exact: true,
        name: local.Counties,
        component: Counties,
      },

      {
        path: "/subdealrs/counties/addcounty",
        name: local.AddCounty,
        component: CreateCounty,
      },
    ];
  } else {
    route = [
      { path: "/admin/login", exact: true, component: AdminLogin },

      { path: "/subdealrs/dashboard", component: AdminHome },
      {
        path: "/subdealrs/statistics",
        exact: true,
        component: Statistics,
      },
      {
        path: "/subdealrs/companies",
        exact: true,
        name: local.Companies,
        component: Companies,
      },
      {
        path: "/subdealrs/agents/importagent",
        name: local.AddAgent,
        exact: true,
        component: AgentFile,
      },

      {
        path: "/subdealrs/companies/addcompany",
        name: local.AddCompany,
        component: CreateCompany,
      },

      {
        path: "/subdealrs/companies/editcompany",
        name: local.EditCompany,
        component: EditCompany,
      },
      {
        path: "/subdealrs/companies/viewcompany",
        name: local.ViewCompany,
        component: ViewCompany,
      },

      {
        path: "/subdealrs/agents",
        exact: true,
        name: local.Agents,
        component: Agents,
      },

      {
        path: "/subdealrs/agents/addagent",
        name: local.AddAgent,
        component: CreateAgent,
      },

      {
        path: "/subdealrs/agents/editagent",
        name: local.EditAgent,
        component: EditAgent,
      },
      {
        path: "/subdealrs/agents/viewagent",
        name: local.ViewAgent,
        component: ViewAgent,
      },

      {
        path: "/subdealrs/customers",
        name: local.Customers,
        exact: true,
        component: Customers,
      },
      {
        path: "/subdealrs/customers/addcustomer",
        name: local.AddCustomer,
        exact: true,
        component: CreateCustomer,
      },
      {
        path: "/subdealrs/customers/editcustomer",
        name: local.EditCustomer,
        component: EditCustomer,
      },

      {
        path: "/subdealrs/customers/viewcustomer",
        name: local.ViewCustomer,
        component: ViewCustomer,
      },

      {
        path: "/subdealrs/customers/importcustomer",
        name: local.AddCustomer,
        exact: true,
        component: FileData,
      },

      {
        path: "/subdealrs/tracking/live",
        name: local.TrackingLive,
        exact: true,
        component: TrackingLive,
      },

      {
        path: "/subdealrs/tracking/history",
        name: local.TrackingHistory,
        exact: true,
        component: TrackingHistory,
      },

      {
        path: "/subdealrs/counties",
        exact: true,
        name: local.Counties,
        component: Counties,
      },

      {
        path: "/subdealrs/counties/addcounty",
        name: local.AddCounty,
        component: CreateCounty,
      },

      {
        path: "/subdealrs/counties/editcounty",
        name: local.EditCounty,
        component: EditCounty,
      },
      {
        path: "/subdealrs/counties/viewcounty",
        name: local.ViewCounty,
        component: ViewCounty,
      },

      {
        path: "/subdealrs/cities",
        exact: true,
        name: local.Cities,
        component: Cities,
      },

      {
        path: "/subdealrs/cities/addcity",
        name: local.AddCity,
        component: CreateCity,
      },

      {
        path: "/subdealrs/cities/editcity",
        name: local.EditCity,
        component: EditCity,
      },

      {
        path: "/subdealrs/prices",
        exact: true,
        name: local.Prices,
        component: Prices,
      },

      {
        path: "/subdealrs/prices/addprice",
        name: local.AddPrice,
        component: CreatePrices,
      },
      {
        path: "/subdealrs/prices/editprice",
        name: local.EditPrice,
        component: EditPrices,
      },
      {
        path: "/subdealrs/prices/viewprice",
        name: local.ViewPrice,
        component: ViewPrice,
      },
    ];
  }
  return route;
};

export const setCodeForMap = (country, list) => {
  let item = list.filter((item) => item.value === country);

  if (item.length > 0) {
    return item[0];
  }
};

export const onChangeCountry = (
  e,
  getCitiesForDropDown,
  general,
  action,
  prop
) => {
  general(
    [
      { prop: "newForm.info.country.name", value: e.label },
      {
        prop: "mapCodePoints",
        value: { code: e.code, lat: e.lat, lng: e.lng },
      },
    ],
    action
  );
  onSelectCountry(e, getCitiesForDropDown, general, action, prop);
};

export const isUrl = (s) => {
  var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  if (regexp.test(s) === true) return true;
  else return false;
};

export const onSelectCountry = (
  e,
  getCitiesForDropDown,
  general,
  action,
  prop
) => {
  if (e !== null) {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    let filter = {
      object: {
        country: e.value,
      },
      duration: {
        from: "2006-01-02T15:04:05Z",
        to: date.toISOString(),
      },
      page: 0,
    };
    general([{ prop: prop, value: e.value }], action);
    getCitiesForDropDown(filter);
  }
};

export const onChangeCity = (
  e,
  getCountiesForDropDown,
  general,
  action,
  prop
) => {
  general([{ prop: "newForm.info.state.name", value: e.label }], action);
  onSelectCity(e, getCountiesForDropDown, general, action, prop);
};

export const onSelectCity = (
  e,
  getCountiesForDropDown,
  general,
  action,
  prop
) => {
  if (e !== null) {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    let filter = {
      object: {
        state: e.value,
        ar_name: "",
        en_name: "",
      },
      duration: {
        from: "2006-01-02T15:04:05Z",
        to: date.toISOString(),
      },
    };

    general([{ prop: prop, value: e.value }], action);
    getCountiesForDropDown(filter);
  }
};

export const onSelectSearch = (id, value, getCitiesForDropDown) => {
  if (id !== "") {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    let filters = {
      object: {
        country: id,
        ar_name: "",
        en_name: "",
      },
      duration: {
        from: "2006-01-02T15:04:05Z",
        to: date.toISOString(),
      },
    };
    if (value !== "") {
      if (HasArabicCharacters(value) === true) {
        filters.object.ar_name = value;
      } else {
        filters.object.en_name = value;
      }
    }
    getCitiesForDropDown(filters);
  }
};

export const onSelectSearch2 = (id, value, getCountiesForDropDown) => {
  if (id !== "") {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    let filters = {
      object: {
        state: id,
        ar_name: "",
        en_name: "",
      },
      duration: {
        from: "2006-01-02T15:04:05Z",
        to: date.toISOString(),
      },
      page: 0,
    };
    if (value !== "") {
      if (HasArabicCharacters(value) === true) {
        filters.object.ar_name = value;
      } else {
        filters.object.en_name = value;
      }
    }
    getCountiesForDropDown(filters);
  }
};

import { Request } from "./Api";
import { MERGE_STATISTICS_PROPS, UPDATE_STATISTICS_PROPS } from "./types";
import toast from "toasted-notes";
import { local } from "../../Localization/local";

export const getStatisticsResult = (filter, history) => async (dispatch) => {
  dispatch({
    type: UPDATE_STATISTICS_PROPS,
    payload: [
      { prop: "isLoadingSearch", value: true },
      {
        prop: "rtable",
        value: {
          delivered: [],
          assigned: [],
          noFound: [],
          rejected: [],
        },
      },
    ],
  });
  let counter = 1;
  let pages = 0;
  let allShipments = [];

  if (counter === 1) {
    await Request()
      .post("/shipment", filter)
      .then(async (response) => {
        if (response.data.status === true) {
          if (response.data.data.items !== null) {
            pages = await response.data.data.pages;
          }
        } else {
          dispatch({
            type: UPDATE_STATISTICS_PROPS,
            payload: [{ prop: "isLoadingSearch", value: true }],
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_STATISTICS_PROPS,
          payload: [{ prop: "isLoadingSearch", value: true }],
        });
        history.push("/error");

        console.log(error);
      })
      .finally();
  }

  while (counter <= pages) {
    await Request()
      .post("/shipment", filter)
      .then(async (response) => {
        if (response.data.status === true) {
          if (response.data.data.items !== null) {
            response.data.data.items.map(async (item) => {
              if (item.status !== undefined) {
                if (item.status === 1) {
                  allShipments.push(allShipments);
                  dispatch({
                    type: MERGE_STATISTICS_PROPS,
                    payload: [{ prop: "rtable.assigned", value: item }],
                  });
                }
                if (item.status === 2) {
                  allShipments.push(allShipments);

                  dispatch({
                    type: MERGE_STATISTICS_PROPS,
                    payload: [{ prop: "rtable.delivered", value: item }],
                  });
                }
                if (item.status === 3) {
                  allShipments.push(allShipments);

                  dispatch({
                    type: MERGE_STATISTICS_PROPS,
                    payload: [{ prop: "rtable.rejected", value: item }],
                  });
                }
                if (item.status === 5) {
                  allShipments.push(allShipments);

                  dispatch({
                    type: MERGE_STATISTICS_PROPS,
                    payload: [{ prop: "rtable.noFound", value: item }],
                  });
                }
              }
            });
            filter.page = (await filter.page) + 1;
            counter = counter + 1;
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_STATISTICS_PROPS,
          payload: [{ prop: "isLoadingSearch", value: false }],
        });
        history.push("/error");

        console.log(error);
      })
      .finally();
  }
  if (counter > pages) {
    if (allShipments.length === 0) {
      toast.notify(local.SearchResult, {
        position: "top-right",
        duration: 2000,
      });
    }
    dispatch({
      type: UPDATE_STATISTICS_PROPS,
      payload: [{ prop: "isLoadingSearch", value: false }],
    });
  }
};

import {
  UPDATE_CITIES_PROPS,
  MERGE_CITIES_PROPS,
  DELETE_CITIES_PROPS,
} from '../Actions/types'
import * as general from './methods'

const INITIAL_STATE = {
  rtable: {
    cities: [],
  },

  search: { country: null },
  newForm: {
    country: '',
    ar_name: '',
    en_name: '',
  },
  errorMessage: {
    errorNameAR: '',
    errorNameEN: '',
    errorCountry: '',
  },
  filter: {
    object: {},
    duration: {
      from: '2006-01-02T15:04:05Z',
      to: null,
    },
    page: 0,
  },

  isLoading: false,
  isLoadingSave: false,
  isLoadingSearch: false,
  isCreated: false,
  isSearchable: false,
  cityId: null,
  pageNumber: null,
  cityIndex: null,
  citiesOptions: [],
  count: 0,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CITIES_PROPS: {
      return general.updateProps(state, action)
    }
    case MERGE_CITIES_PROPS: {
      return general.mergeProps(state, action)
    }
    case DELETE_CITIES_PROPS: {
      return general.deleteProps(state, action)
    }
    default:
      return state
  }
}

import {
  UPDATE_AGENTS_PROPS,
  MERGE_AGENTS_PROPS,
  DELETE_AGENTS_PROPS,
} from "../Actions/types";
import * as general from "./methods";
import { getLanguage } from "../../Localization/local";

const INITIAL_STATE = {
  rtable: {
    agents: [],
  },

  lightbox: { isOpen: false, image: null },

  courier: [],

  filter: {
    object: {},
    duration: {
      from: "2006-01-02T15:04:05Z",
      to: null,
    },
    page: 0,
  },

  newForm: {
    company: null,
    ssn: "",
    name: "",
    county: null,
    info: {
      name: "",
      address: "",
      phone: "",
      image: null,
      companyName: "",
      agent_type: null,
    },
    password: "",
    is_active: "",
  },
  errorMessage: {
    errorCompany: "",
    errorName2: "",
    errorName: "",
    errorStatus: "",
    errorSSN: "",
    errorPassword: "",
    errorPhone: "",
    errorAddress: "",
    errorImage: "",
    errorEmail: "",
    error_Driving_License_Photo: "",
    error_Driving_License_No: "",
    error_Driver_Photo_With_Car: "",
    error_Iqama_Number_Id_Number: "",
    error_Car_Insurance_Photo: "",
    error_Car_Insurance_No: "",
    error_Car_License_Photo: "",
    error_Car_License_No: "",
    error_Car_Serial_Number: "",
    error_Car_Image_With_Plate_Number: "",
    error_Hejri_Birth_Date: "",
    error_Birth_Date_En: "",
    errorCountry: "",
    errorCity: "",
    errorCounties: "",
  },

  isViewPassword: "password",

  search: { ssn: "", from: "", to: "", company: null },

  statusOptions: [
    { label: getLanguage() === "en" ? "Active" : "نشط", value: 1 },
    { label: getLanguage() === "en" ? "In Active" : "غير نشيط", value: 0 },
  ],

  agentٍStatus: [
    {
      label: getLanguage() === "en" ? "Shipping representative" : "مندوب شخن",
      value: 0,
    },
    { label: getLanguage() === "en" ? "Servant" : "خادمة", value: 1 },
    { label: getLanguage() === "en" ? "Nurse" : "ممرضة", value: 2 },
  ],
  isLoading: false,
  isLoadingSave: false,
  isLoadingSearch: false,
  isCreated: false,
  isSearchable: false,
  agentId: null,
  agentIndex: null,
  agentsOptions: [],
  inActiveAgents: [],

  count: 0,
  isEditCompanyName: false,
  countryPhone: "",
  evaluation: [],
  country_company: "",
  state: null,
  country: null,

  addModalOpen: false,
  fileData: {
    columns: [],
    data: [],
    myFile: null,
    isLoading: false,
    CitiesCounties: [],
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_AGENTS_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_AGENTS_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_AGENTS_PROPS: {
      return general.deleteProps(state, action);
    }
    default:
      return state;
  }
};

import React, { Component } from "react";
import { Row, Col, Input } from "reactstrap";
import { local } from "../Localization/local";
import AsyncSelect from "react-select/async";
import { MERGE_CUSTOMERS_PROPS } from "../Redux/Actions/types";
import { input } from "./GeneralStyle";
const Wrapper = {
  width: "40%",
  position: "absolute",
  top: "0px",
  right: "0px",
  zIndex: 1000,
  marginTop: "1%",
  height: "35px",
  marginRight: "3%",
};

class SearchMap extends Component {
  constructor(props) {
    super(props);
    this.clearSearchBox = this.clearSearchBox.bind(this);
  }

  clearSearchBox() {
    this.searchInput.value = "";
  }

  filterRoutes = () => {
    return this.props.searchOptions.filter((i) => i.label.toLowerCase());
  };
  promiseOptions = () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.filterRoutes());
      }, 1000);
    });

  // getMapConfig = () => {
  //   let mapConfig = JSON.parse(localStorage.getItem(SHIPMENT_IP_LOCATION))
  //   if (mapConfig !== null) {
  //     return mapConfig
  //   }
  // }

  searchInMap = (e) => {
    if (e.length > 2) {
      const { MapFilter, action, mapCodePoints } = this.props;
      // let filters = {
      //   code: this.getMapConfig().countryCode,
      //   q: e,
      //   lat: this.getMapConfig().lat,
      //   lng: this.getMapConfig().lon,
      // }

      MapFilter(e, action, "searchOptions", mapCodePoints);
    }
  };

  onChangeSearch = (e) => {
    const {
      general,
      action,
      latProp,
      LngProp,
      searchOptions,
      propPoints,
      isCustomer,
      points,
    } = this.props;

    if (e !== null)
      if (searchOptions.length > 0) {
        let point =
          "POINT(" + JSON.stringify(e.lng) + " " + JSON.stringify(e.lat) + ")";
        if (isCustomer !== undefined && isCustomer === true) {
          general(
            [
              { prop: latProp, value: e.lat },
              { prop: LngProp, value: e.lng },
            ],
            action
          );

          general([{ prop: propPoints, value: point }], MERGE_CUSTOMERS_PROPS);
          if (points.length === 0) {
            general(
              [
                {
                  prop: "locationAddress.addressProp",
                  value: "newForm.addresses." + 0,
                },

                {
                  prop: "newForm.addresses." + points.length,
                  value: "",
                },
                {
                  prop: "locationAddress.isOpen",
                  value: true,
                },
              ],
              action
            );
          }
          if (points.length > 0) {
            general(
              [
                {
                  prop: "locationAddress.addressProp",
                  value: "newForm.addresses." + points.length,
                },
                {
                  prop: "newForm.addresses." + points.length,
                  value: "",
                },
                {
                  prop: "locationAddress.isOpen",
                  value: true,
                },
              ],
              action
            );
          }
        } else {
          general(
            [
              { prop: latProp, value: e.lat },
              { prop: LngProp, value: e.lng },
              { prop: propPoints, value: point },
            ],
            action
          );
        }
      }
  };
  handelLink = (e) => {
    const {
      general,
      action,
      latProp,
      LngProp,
      propPoints,
      isCustomer,
      points,
    } = this.props;

    if (e.target.value !== "") {
      let url = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      if (url.test(e.target.value)) {
        general([{ prop: "urlLink", value: e.target.value }], action);
        let point =
          "POINT(" +
          e.target.value.split("@")[1].split(",")[1] +
          " " +
          e.target.value.split("@")[1].split(",")[0] +
          ")";

        if (isCustomer !== undefined && isCustomer === true) {
          general(
            [
              {
                prop: latProp,
                value: e.target.value.split("@")[1].split(",")[0],
              },
              {
                prop: LngProp,
                value: e.target.value.split("@")[1].split(",")[1],
              },
            ],
            action
          );

          general([{ prop: propPoints, value: point }], MERGE_CUSTOMERS_PROPS);
          if (points.length === 0) {
            general(
              [
                {
                  prop: "locationAddress.addressProp",
                  value: "newForm.addresses." + 0,
                },

                {
                  prop: "newForm.addresses." + points.length,
                  value: "",
                },
                {
                  prop: "locationAddress.isOpen",
                  value: true,
                },
              ],
              action
            );
          }
          if (points.length > 0) {
            general(
              [
                {
                  prop: "locationAddress.addressProp",
                  value: "newForm.addresses." + points.length,
                },
                {
                  prop: "newForm.addresses." + points.length,
                  value: "",
                },
                {
                  prop: "locationAddress.isOpen",
                  value: true,
                },
              ],
              action
            );
          }
          // e.target.value = ''
        } else {
          general(
            [
              {
                prop: latProp,
                value: e.target.value.split("@")[1].split(",")[0],
              },
              {
                prop: LngProp,
                value: e.target.value.split("@")[1].split(",")[1],
              },
              { prop: propPoints, value: point },
            ],
            action
          );
        }
      } else {
        alert(local.NotUrl);
      }
    }
  };

  render() {
    const {
      searchOptions,
      latProp,
      LngProp,
      general,
      action,
      isCustomer,
      urlLink,
    } = this.props;

    return (
      <center>
        <div style={Wrapper}>
          <Row>
            <Col>
              <AsyncSelect
                className="css-g1d714-ValueContainer1"
                placeholder={local.PlaceName}
                isMulti={false}
                onInputChange={(e) => this.searchInMap(e)}
                onChange={(e) =>
                  e !== null
                    ? this.onChangeSearch(e)
                    : general(
                        [
                          { prop: latProp, value: "" },
                          { prop: LngProp, value: "" },
                        ],
                        action
                      )
                }
                defaultOptions
                loadOptions={() => this.promiseOptions()}
                isClearable={true}
              />
              {/* {isCustomer === undefined ? ( */}
              <Input
                style={input}
                value={urlLink !== undefined ? urlLink : null}
                onChange={(e) => this.handelLink(e)}
                type="text"
                style={{ marginTop: "1%" }}
                placeholder={local.PutLink}
                className="link"
              />

              {/* ) : null} */}
            </Col>
          </Row>
        </div>
      </center>
    );
  }
}

export default SearchMap;

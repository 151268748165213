import { Request } from "./Api";
import { UPDATE_CITIES_PROPS, UPDATE_COUNTIES_PROPS } from "./types";
import { checkisAuthorized } from "../../General/GeneralFunctions";
import toast from "toasted-notes";
import { local, getLanguage } from "../../Localization/local";
export const fetchCities = (history, filter) => (dispatch) => {
  if (checkisAuthorized() === false) {
    history.push("/admin/login");
  } else {
    if (filter.object.country === undefined) {
      dispatch({
        type: UPDATE_CITIES_PROPS,
        payload: [
          { prop: "isLoading", value: true },
          { prop: "isSearchable", value: false },
          { prop: "isCreated", value: false },
        ],
      });
    } else {
      dispatch({
        type: UPDATE_CITIES_PROPS,
        payload: [
          { prop: "isLoadingSearch", value: true },
          { prop: "isSearchable", value: true },
          { prop: "isCreated", value: false },
        ],
      });
    }
    let date = new Date();
    date.setDate(date.getDate() + 1);
    filter.duration.to = date.toISOString();

    Request()
      .post("/states", filter)
      .then((response) => {
        if (response.data.status === true) {
          if (response.data.data.items.States !== null) {
            dispatch({
              type: UPDATE_CITIES_PROPS,
              payload: [
                {
                  prop: "rtable.cities",
                  value: response.data.data.items.States,
                },
                {
                  prop: "count",
                  value: response.data.data.pages,
                },
              ],
            });
          } else {
            if (filter.object.country !== undefined) {
              toast.notify(local.SearchResult, {
                position: "top-right",
                duration: 2000,
              });
            }
            dispatch({
              type: UPDATE_CITIES_PROPS,
              payload: [{ prop: "rtable.cities", value: [] }],
            });
          }
        }
        dispatch({
          type: UPDATE_CITIES_PROPS,
          payload: [
            { prop: "isLoading", value: false },
            { prop: "isLoadingSearch", value: false },
          ],
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_CITIES_PROPS,
          payload: [
            { prop: "isLoading", value: false },
            { prop: "isLoadingSearch", value: false },
          ],
        });
        history.push("/error");

        console.log(error);
      })
      .finally();
  }
};

export const CreateuOrUpdateCity = (newForm, history, index) => (dispatch) => {
  dispatch({
    type: UPDATE_CITIES_PROPS,
    payload: [
      { prop: "isLoadingSave", value: true },
      { prop: "isCreated", value: true },
    ],
  });

  let url = null;
  if (newForm.id === undefined) {
    url = "/states/new";
  } else {
    url = "/states/update";
  }
  Request()
    .post(url, newForm)
    .then((response) => {
      if (response.data.status === true) {
        history.push("/subdealrs/cities");
        if (newForm.id === undefined) {
          toast.notify(local.Successed, {
            position: "top-right",
            duration: 2000,
          });
        } else {
          dispatch({
            type: UPDATE_CITIES_PROPS,
            payload: [
              {
                prop: "rtable.cities." + index,
                value: newForm,
              },
            ],
          });

          toast.notify(local.Edited, {
            position: "top-right",
            duration: 2000,
          });
        }
      }
      // else {
      //   if (
      //     response.data.error.message !== null &&
      //     response.data.error.message[0] ===
      //       'pq: duplicate key value violates unique constraint "company_phone"'
      //   ) {
      //     toast(local.UniquePhone)

      //   }
      // }
      dispatch({
        type: UPDATE_CITIES_PROPS,
        payload: [{ prop: "isLoadingSave", value: false }],
      });
    })

    .catch((error) => {
      dispatch({
        type: UPDATE_CITIES_PROPS,
        payload: [{ prop: "isLoadingSave", value: false }],
      });
    })
    .finally();
};

export const getCitiesForDropDown = (filter, type) => (dispatch) => {
  Request()
    .post("/states", filter)
    .then((response) => {
      if (response.data.status === true) {
        if (response.data.data.items.States !== null) {
          let cities = [];
          response.data.data.items.States.map((city) => {
            if (filter.object.ar_name !== "" && filter.object.en_name === "") {
              cities.push({
                label: city.ar_name,
                value: city.id,
                country: city.country,
              });
            } else if (
              filter.object.en_name !== "" &&
              filter.object.ar_name === ""
            ) {
              cities.push({
                label: city.en_name,
                value: city.id,
                country: city.country,
              });
            } else {
              if (getLanguage() === "en") {
                cities.push({
                  label: city.en_name,
                  value: city.id,
                  country: city.country,
                });
              } else {
                cities.push({
                  label: city.ar_name,
                  value: city.id,
                  country: city.country,
                });
              }
            }
          });
          if (type !== undefined && type === "counties") {
            dispatch({
              type: UPDATE_COUNTIES_PROPS,
              payload: [{ prop: "country", value: cities[0].country }],
            });
          }
          dispatch({
            type: UPDATE_CITIES_PROPS,
            payload: [{ prop: "citiesOptions", value: cities }],
          });
        } else {
          dispatch({
            type: UPDATE_COUNTIES_PROPS,
            payload: [{ prop: "citiesOptions", value: [] }],
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally();
};

export const addCity = () => (dispatch) => {
  let cities = [
    { country: 63, ar_name: "الاسكندارية", en_name: "Alexandria" },
    { country: 63, ar_name: "اسوان", en_name: "Aswan" },
    { country: 63, ar_name: "اسيوط", en_name: "Asyut" },
    { country: 63, ar_name: "البحيرة", en_name: "Beheira" },
    { country: 63, ar_name: "بني سويف", en_name: "Beni Suef	" },
    { country: 63, ar_name: "الدقهلية", en_name: "Dakahlia" },
    { country: 63, ar_name: "دمياط", en_name: "Damietta" },
    { country: 63, ar_name: "الفيوم", en_name: "Faiyum	" },
    { country: 63, ar_name: "الفربية", en_name: "Gharbia" },
    { country: 63, ar_name: "كفر الشيخ", en_name: "Kafr El Sheikh" },
    { country: 63, ar_name: "الاقصر", en_name: "Luxor" },
    { country: 63, ar_name: "مرسي مطروح", en_name: "Matruh" },
    { country: 63, ar_name: "المنيا", en_name: "Minya" },
    { country: 63, ar_name: "المنوفية", en_name: "Monufia" },
    { country: 63, ar_name: "الوادي الجديد", en_name: "New Valley" },
    { country: 63, ar_name: "شمال سينا", en_name: "North Sinai" },
    { country: 63, ar_name: "بورسعيد", en_name: "Port Said" },
    { country: 63, ar_name: "القليوبية", en_name: "Qalyubia" },
    { country: 63, ar_name: "قنا", en_name: "Qena" },
    { country: 63, ar_name: "البحر الاحمر", en_name: "Red Sea" },
    { country: 63, ar_name: "الشرقية", en_name: "Sharqia" },
    { country: 63, ar_name: "سوهاج", en_name: "Sohag" },
    { country: 63, ar_name: "جنوب سينا", en_name: "South Sinai" },
    { country: 63, ar_name: "السويس", en_name: "Suez" },
    { country: 63, ar_name: "الاقصر", en_name: "Luxor" },
    { country: 63, ar_name: "طنطا", en_name: "Tanta" },
  ];

  cities.map((form) => {
    Request()
      .post("/states/new", form)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      })
      .finally();
  });
};

export const en = {
  lang: "en",
  Settings: "Settings",
  English: "English",
  Arabic: "Arabic",
  Home: "Home",
  Logout: "Logout",
  ShipmentNumberExist: "Shipment Number is Exist",
  tabToOpen: "tab to open",
  tabToClose: "tab to close",
  NavigateMe: "Navigate to my location",
  NavigateToAgent: "Navigate to agent location",
  ScanQrcode: "Scan Qrcode",
  ScuessfullyScanned: "Shipment has been Scanned Successfully",
  shipmentExist: "this shipment is exist",
  Pending: "Pending ...",
  Saved: "Saved",
  PhoneExist: "Phone is Exist",
  Agents: "Agents",
  LinesOrder: "Lines Order",
  LineTrip: "Line Trip",
  Customers: "Customers",
  Tracking: "Tracking",
  TrackingLive: "Tracking Live",
  TrackingHistory: "Tracking History",
  Salesagents: "Sales agents",
  Users: "Sales Companies",
  Help: "Help",
  Feedback: "Feedback",
  AddLine: "Add Line",
  AddCustomer: "Add Customer",
  FromDateLengthValidation: "From Date cannot be greater then To Date",
  DateLengthValidation: "Created Date cannot be greater then Delivery Date",
  ToDateValidation: "To Date is Required",
  FromDateValidation: "From Date is Requored",
  AddAgent: "Add Agent",
  AddedIn: "Added In",
  SearchCustomers: "Search Customers",
  SearchAgents: "Search Agents",
  Name: "Name",
  ID: "ID",
  Edit: "Edit",
  Status: "Status",
  CreatedIn: "Created In",
  SalesAgents: "Sales Agents",
  AddSalesagent: "Add Sales agent",
  Company: "Company",
  IDLogin: "Id Number is Required",
  SalesRequired: "Sales Company is Required",
  ShipmentDetails: "Shipment Details",
  Notes: "Notes",
  ArabicLang: "Only Arabic Language is Allowed",
  EnglishLang: "Only English Language is Allowed",
  Egcurrany: " LE ",
  NumberOnly: "Numbers only are allowed",
  SaleCompany: "Sales Company",
  OrderIDLength: "Patient ID must be greater then 4 nubmer",
  EditShipment: "Edit Shipment",
  DeliveryDate: "Specified Delivery Date",
  DeliveryDate1: " Delivered Date",

  OrderIDRequired: "Patient ID is Required",
  SearchBy: "Search By",
  SearchKey: "Search Key",
  Search: "Search",
  Reset: "Reset",
  PhoneNumber: "Phone Number",
  ProfilePicture: "Profile Picture",
  NationalIdNumber: "National Id Number",
  National: "National Id",
  AddPhotoCopy: "Add Photo Copy",
  DrivingLicenseNumber: "Driving License Number",
  DrivingLicenseandDate: "Driving License and Date",
  NotifiyBeforexpiration: "Status",
  Save: "Save",
  Lines: "Lines",
  LineID: "Line ID",
  Agent: "Agent",
  ChooseYourCity: "Choose Your City",
  Customer: "Customer",
  Photo: " Photo",
  DeliveryStatus: "Delivery Status",
  Language: "Language",
  ImageLengthRequired: "Image length must be less then 1 mb",
  imageSize: "Image Size less then 1MB",

  Time: "Time",
  TimeZoneRequired: "TimeZone is Required",
  Logo: "Logo Here",
  PatientID: "Client ID",
  CustomerLocationExist: "this Location is",
  SalesAgent: "Sales Agent",
  Date: "Date",
  AddMoreDestinations: "Add More Destinations",
  FromDate: "From Date",
  ToDate: "To Date",
  CustomerID: "Customer ID",
  City: "City",
  LogoHere: "Logo Here",
  CustomerName: "Customer Name",
  Email: "Email",
  Order: "Order",
  Started: "Started",
  Battery: "Battery",
  PROCESSEDORDERS: "PROCESSED ORDERS",
  Admin: "Admin",
  Courier: "Courier",
  Password: "Password",
  ErrorCompanyLogin: "Phone Number or Password is Incorrct",
  ErrorAdminLogin: "UserName or Password is Incorrct or Not Activited",
  ErrorCourierLogin: "National ID or Password is Incorrct",

  SignIn: "Sign In",
  Successed: "Item has been Successfully Added",
  CleintSuccessed: "Client has been Successfully Added",
  Edited: "Item has been Successfully Edited",
  EditCustomer: "Edit Customer",
  SearchResult: "No Search Result Found",
  PhoneRequired: "Phone is Required",
  SearchShipment: "Search Shipment",
  ViewCard: "View Customer and Shipment Information",
  DeliveryShipments: "Delivery Shipments",
  Confirmation: "Shipment Status by Agent",
  Confirmed: "Confirmed",
  NotConfirmed: "Not Confirmed",
  Shipment: "Shipment",
  HomeView: "View Sales Company and their Shipments",
  PasswordRequired: "Password is Required",
  CompanyRequired: " Company is required",
  InternetConnection: "Check Internet Connection",
  Reload: "Reload",
  NoAgents: "No Agents today",
  NoMobile: "No Mobile",
  AgentClientRequired: "Agent or Client is Required",
  AgentClientRequired1: "Agent or Client or Shipment Number is Required",
  NameRequired: "Name is Required",
  NationalIdNumberRequired: "National Id Number is Required",
  DateRequired: "Date is Required",
  Inactive: "InActive",
  Active: "Active",
  StatusRequired: "Status is Required",
  ContactPersionRequired: "Responsible Person is Required",
  PasswordLength: "password is less than 8 runes",
  CompanyName: "Company Name",
  IdNumber: "ID Number",
  AddCounty: "Add Area",
  EditCounty: "Edit Area",
  AddCity: "Add City",
  EditCity: "Edit City",
  Counties: "Counties",
  CountyID: "Area ID",
  EnglishNameRequired: "English Name is Required",
  ArabicNameRequired: "Arabic Name is Required",
  StateRequired: "State is Required",
  CountryRequired: "Country is Required",
  PageNotFound: "Page Not Found",
  EnglishName: "English Name",
  ArabicName: "Arabic Name",
  CountryValidationRequired: "Choose Country",
  CityValidationRequired: "Select City",
  SSNLength: "National Id must be Equal 10 Numbers",
  SSNNumberEgypt: "National Id must be Equal 14 Numbers",
  SSNNumberCyprus: "National Id must be Equal 9 charactor ",
  SSNNumberEmirates: "National Id must be Equal 15 Numbers",
  CityPhoneRequired: "City or Phone is Required",
  Shipments: "Shipments",
  SSNNumber: "National ID must be Number only",
  Payed: "Payed",
  NotPaid: "Not Paid",
  Assign: "Assign",
  ReAssign: "ReAssign",
  Finished: "Finished",
  NotAssigned: "Not Assigned",
  AgentRequired: "Select Agent",
  AssignShipment: "Assign Shipment",
  SelectCourier: "Select Agent ",
  SuccessfullyAssign: "Successfully Shipment is Assigned",
  SearchUser: "Search Sales Companies",
  ViewShipment: "View Shipment",
  ShipmentNumber2: "Number of Shipments",
  LoginID: "Login ID",
  ShipmentCancel: "Shipment has been Canceled Successfully",
  SearchShipments: "Search Shipments",
  Paid: "Paid",
  Cities: "Cities",
  EditAgent: "Edit Agent",
  UniqueID: "National Id is Already Exist",
  TimeZone: "TimeZone",
  UniquePhone: "Phone is Already Exist",
  AgentType: "Agent Type",
  WorkTime: "Time of Work",
  View: "View",
  RecordValidation: "Display Date or National Id",
  RecordValidation2: "Select Date and Agent",
  RecordValidation1: "One Record is Required at least",
  LocationAddress: "Enter Address for this Location",
  DateValidation: " Date is Required",
  InputValidation: "Cannot allowed to search by National id and Date",
  PhoneNumberRequired: "Phone Must be English Number Only",
  PhoneNumberRequired1: "Phone Must be English Number Only",
  NoAddress: "No Address",
  Address: "Address",
  NoShipmentForCustomer: "No Customer has shipment to Dliver it",
  NotUrl: "it is not Valid Url",
  Phone: "Phone",
  Mail: "Mail",
  Address1: "Address 1",
  Address2: "Address 2",
  EmailRequired: "Email is Required",
  AddressRequired: "Address is Required",
  LocationRequired: "Location is Required",
  PhoneNumber1: "Phone Number 1",
  PhoneNumber2: "Phone Number 2",
  Location: "Location",
  Remove: "Remove",
  Delete: "Delete",
  ViewAgent: "View Agent",
  EditMessage: "Are you sure want to edit this Item",
  EditPassword: "Are you sure want to update your Data",
  ConfirmPasswordRequired: "Confirm Password is Required",
  Yes: "Yes",
  No: "No",
  SelectUser: "Select User Type",
  UserType: "User Type",
  UserName: "UserName",
  SSN: "National ID",
  SSNRequired: "National ID is Required",
  Companies: "Companies",
  AddCompany: "Add Company",
  Statistics: "Statistics",
  SearchStatistics: "Search Statistics",
  Time: "Time",
  EditCompany: "Edit Company",
  CompanyID: "Company ID",
  Contact_Person: "Responsible Person",
  ImportFileData: "Import File From Data",
  InvaldMail: "Invald Mail For Client ",
  PhoneDuplicate: "this Phone ",
  PhoneDuplicated: " are duplicated in Phone 1 Column",
  PhoneDuplicated1: " are duplicated in Phone 2 Column",
  PhoneExistIn: "this phone ",
  PhoneExistIn1: " are exist in Phone1 and Phone2",
  errorInClient: "Error With Client Name ",
  SSNFILELengthEgypt: "National ID Length must equal 14 numbers with name ",
  SSNFILELengthSaudi: "National ID Length must equal 10 number with name ",
  NameDuplicated: "this UserName are Duplicated",
  PassswordLength: "All Password Length must greater then 7 number",
  WrongActive: "Wrong Text in Is_Active please check it",
  Country: "Country",
  SsnDuplicated: "National Id Number is dublicated",
  CityID: "City ID",
  CountiesRequired: "Counties is Required",
  RequiredDate: "you must insert Required Data",
  Unpaid: "Unpaid",
  PaymentStatusRequired: "Payment Status is Required",
  ReturnedPrice: "Returned Price",
  PhoneNotExist: "Phone Not Exist",
  MeetingPoint: "Meeting Point",
  PhoneNumberofAgent: "Phone Number of Agent",
  SelectCustomerLocation: "Select Customer Location",
  PayedRequired: "Price of Shipment is Required",
  PaymentStatus: "Payment Status",
  PricePart: "The Rest of the Price",
  Prices: "Prices of Shipments",
  KiloMeterRequired: "Price for Each Kill Meter is Required",
  SecondRequired: "Price for Each Second is Required",

  Comments: "Write your Comment",
  MessageSave: "thank you for your comment",
  addRate: "please add a rate to send your comment ",
  CommentSent: "comment and Rate successfully sent",
  Price: "Price",
  NotAllow: "Not Allow",
  UniqueUserName: "UserName is Exist",
  Comment: "Agent Comment",
  NoComment: "No Comment",
  evaluation: "Evaluations",
  DrivingLicensePhoto: "Driving License Photo",
  DrivingLicensePhotoRequired: "Driving License Photo is Required",
  DriverPhotowithCar: "Driver Photo with Car",
  DriverPhotowithCarRequired: "Driver Photo with Car is Required",
  IqamaNumberIDNumber: "Iqama Number",
  IqamaNumberIDNumberRequired: "Iqama Number is Required",
  DrivinglicenseNo: "Driving license No",
  DrivinglicenseNoRequired: "Driving license No is Required",
  CarInsurancePhoto: "Car Insurance Photo",
  CarInsurancePhotoRequired: "Car Insurance Photo is Required",
  CarInsuranceNo: "Car Insurance No",
  CarInsuranceNoRequired: "Car Insurance No is Required",
  CarLicensePhoto: "Car License Photo",
  CarLicensePhotoRequired: "Car License Photo is Required",
  CarLicenseNo: "Car License No",
  CarLicenseNoRequired: "Car License No is Required",
  CarSerialNumber: "Car Serial Number",
  CarSerialNumberRequired: "Car Serial Number is Required",
  CarImageWithPlateNumber: "Car Image with Plate Number",
  CarImageWithPlateNumberRequired: "Car Image with Plate Number is Required",
  HejriBirthDate: "Hejri Birth Date",
  HejriBirthDateRequired: "Hejri Birth Date is Required",
  BirthDateEN: "Birth Date (EN)",
  BirthDateENRequired: "Birth Date must be equal 18 years old or greater",
  FileName: "File Name: ",
  PutLink: "Put Link Here",
  CancelDelivry: "Cancel Delivry",
  FinishedOrders: "Finished Orders",
  NameExist: "Name is Exist",
  UserNameExist: "UserName is Exist",
  CityRequired: "City is Required",
  WorkMessage: "No Agents Working until Now",
  SomethingWrong: "Something Wrong",
  AddressFor: "Address for ",
  NoTracking: " Tracking History",
  PhoneSaudiaLength: "Phone must be equal then 11 or 12 number",
  PhoneLbLength: "Phone must be equal then 10 or 11 number",
  PhoneCyLength: "Phone must be equal then 11 number",
  County: "Area",
  CompanySearchValidation: "Mobile Number Or Location is Required",
  ShipmentID: "Number of Shipment",
  CodeDelivery: "Code of Delivery",
  IDRequired: "Id of Shipment is Required",
  CodeShipmentRequired: "Code of Shipment is Required",
  IDNumber: "Id of Shipment must be a Number",
  CodeNumber: "Code of Shipment must be a Number",
  PhoneLength: "Phone is Required and must be greater then six Number",
  PhoneWithSameCountry: "two phones numbers must be egyption phones",
  EmailValidRequired: "Email must be like this (test@email.com)",
  Sacurrany: "Egyptian Pound",
  Documents: "Documents",
  PhoneEgyptLength: "Phone must be equal then 12 number",
  Empty: "Empty",
  PhoneEqual: "Display Diffrent Phone Numbers to  Phone 1 and 2",
  AddShipment: "Add Shipment",
  AddPrice: "Add Price",
  EditPrice: "Edit Price",
  ViewPrice: "View Price",
  PriceID: "Price ID",
  PriceInKM: "Price In Kilo Meter",
  PriceAtMinutes: "Price At Minutes",
  PlaceName: "Place Name",
  PlaceNameOrUrl: "Place Name Or Display Url",
  WatingClientData: "Wating Client to send your Data",
  UserPhone: "Sales Company Phone",
  DateRequired: "Date is Required",
  SearchCities: "Search Cities",
  SearchCompanies: "Search Companies",
  shippingCompany: "Shipping Company",
  SmsClient: "send sms or generate link to client",
  yourClientlink: "click here and fill your data",
  DataSubmitted: "your data is submitted",
  ThankYou: "thank you",
  CustomerCompany: "Customer Company",
  ViewUser: "View Sales Company",
  ViewCustomer: "View Customer",
  NoShipments: "No Shipments",
  AgentID: "Agent ID",
  ShipmentNumber: "Shipment Number",
  NoShipment: "No Shipment with this Number",
  CustomerPhone: "Customer Phone",
  CustomerRequired: "Customer is Required",
  PriceRequired: "Price is Required",
  NumberofShipmentRequired: "Shipment Number is Required",
  PriceNumber: "Price must be Number only",
  ClientComment: "Client Comment",
  Rating: "Rating",
  User: "Sales Company",
  CreateUser: "Create Sales Company",
  EditUser: "Edit Sales User",
  CountyRequired: "Area is Required",
  UserID: "ID",
  ViewCompany: "View Company",
  ViewCity: "View City",
  AddUser: "Add Sales Company",
  PasswordRequiredLenth: "Password must be greater then 8 charactor",
  ConfirmPasswordMatch: "Confirm Password must not matched Password",
  ConfirmPassword: "Confirm Password",
  PasswordSucceessed: "Password has been Updated Successfully",
  ImageSucceessed: "Image has been Updated Successfully",

  PowerBy: "Powerd By Too Locations",
  AllDocumentsLength: "All Documents Length Must be less then 1 mb",
  PasswordRequiredStrong:
    "Passwords must be :At least 8 characters long,Include at least 1 lowercase letter,1 capital letter,1 number,1 special character => !@#$%^&*",
  UserRequired: "Sales Company is Required",
  yourshipmentslink: "your Shipments link ",
  Send: "Send",
  SMSMessage: "SMS Message",
  Created: "Created",
  Assigned: "Assigned",
  Delivered: "Delivered",
  Rejected: "Rejected",
  Canceled: "Canceled",
  ClientNotFound: "Client Not Found",
  UserNameRequired: "UserName is Required and must not contain Space",
  CusNameValidation: "Name must not contain Space",
  ViewAgentCard: "View Info",
  StartedTime: "Started",
  Loading: "Loading",
  PreferdTimeFrom: "From Preferred Time For Delivery is Required",
  PreferdTimeTo: "To Preferred Time For Delivery is Required",
  PreferedTime: "Preferred Time For Delivery Shipment to Customer",
  FromPreferedTime: "From Prefered Time",
  ToPreferedTime: "To Prefered Time",
  Message: "Type Here",
  MessageRequired: "Message is Required",
  LinkID: "Shipment Link  of the Customer",
  Close: "Close",
  Link: "Link",
  NoHistory: "No Tracking History Found",
  AgentName: "Agent Name",
  ArrivingAt: "Arriving At",
  ShipmentFrom: "Shipment From",
  MessageSent: "Message Successffully Sent",
  LogoutConfirm: "do you want to Logout ?",
  YourVerfiyCode: "Your Verfiy Code",
  ShipmentTypeRequired: "Shipment type is Required",
  ShipmentType: "Shipment Type",
  SearchCounty: "Search Counties",
  CompanyNamePhone: "Company Name or Phone",
  UserNamePhone: "Sales Company Name or Phone",
  CustomerNamePhone: "Customer Name or Phone",
  newCustomer: "New Customer",
  oldCustomer: "old Customer",
  LinkSms: "Link or send Sms",
  SendSmS: "send sms",
  LinkCopied: "Link is Copied",
  GenerateLink: "generate link",
  WayChoose: "you Must choose send sms or generate link or select two options",
  AddInvoice: "Add Invoice",
  DefaulltInvoice: "Use Deafult Invoice",
  uploadInvoice: "Upload Invoice",
  FileLength: "File length must be less then 2 mb",
  FileType: "pdf file only allowed",
  PreviewFile: "Preview File",
  BillTo: "Bill to",
  Charge: "Charge",
  VAT: "VAT",
  AdditionalTax: "Additional Tax",
  Total: "Total",
  Items: "Items",
  Quantity: "Quantity",
  UnitePrice: "Unite Price",
  AddItem: "Add Item",
  CompleteData: "Complete your data",
  RemoveInvoice: "Remove Invoice",
  Invoice: "Invoice",
  OrderID: "Order ID",
  ShippingSalesCompany: "Shipping & Sales Company",
  CountyNameArabic: "Arabic County Name",
  CountyNameEnglish: "English County Name",
  UserNameID: "May be UserName or or National ID is Dublicated",
  CancelShipment: "Cancel Shipment",
  UserNamePhoneExist: "May be UserName or Phone is Exist",
  Viewd: "Viewd",
};

import { Request } from "./Api";
import { UPDATE_USERS_PROPS, UPDATE_CUSTOMERS_PROPS } from "./types";
import {
  checkisAuthorized,
  logoutWithhoutConfirmation,
} from "../../General/GeneralFunctions";
import toast from "toasted-notes";
import { local } from "../../Localization/local";

export const fetchUsers = (history, filter) => async (dispatch) => {
  if (checkisAuthorized() === false) {
    history.push("/");
  } else {
    if (
      filter.object.phone !== undefined ||
      filter.object.county !== undefined
    ) {
      dispatch({
        type: UPDATE_USERS_PROPS,
        payload: [
          { prop: "isLoadingSearch", value: true },
          { prop: "isCreated", value: false },
          { prop: "isSearchable", value: true },
        ],
      });
    } else {
      dispatch({
        type: UPDATE_USERS_PROPS,
        payload: [
          { prop: "isLoading", value: true },
          { prop: "isCreated", value: false },
          { prop: "isSearchable", value: false },
        ],
      });
    }
    let date = new Date();
    date.setDate(date.getDate() + 1);

    filter.duration.to = date.toISOString();
    Request()
      .post("/user", filter)
      .then((response) => {
        if (response.data.status === true) {
          if (response.data.data.items !== null) {
            dispatch({
              type: UPDATE_USERS_PROPS,
              payload: [
                { prop: "rtable.users", value: response.data.data.items },
                { prop: "count", value: response.data.data.pages },
              ],
            });
          } else {
            if (
              filter.object.phone !== undefined ||
              filter.object.county !== undefined
            ) {
              toast.notify(local.SearchResult, {
                position: "top-right",
                duration: 2000,
              });
            }
            dispatch({
              type: UPDATE_USERS_PROPS,
              payload: [{ prop: "rtable.users", value: [] }],
            });
          }
        }
        dispatch({
          type: UPDATE_USERS_PROPS,
          payload: [
            { prop: "isLoading", value: false },
            { prop: "isLoadingSearch", value: false },
          ],
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: UPDATE_USERS_PROPS,
          payload: [
            { prop: "isLoading", value: false },
            { prop: "isLoadingSearch", value: false },
          ],
        });
        history.push("/error");
      })
      .finally();
  }
};

export const CreateuOrUpdateUser = (newForm, history, userIndex) => (
  dispatch
) => {
  dispatch({
    type: UPDATE_USERS_PROPS,
    payload: [{ prop: "isLoadingSave", value: true }],
  });

  if (newForm.info.image !== undefined) {
    if (newForm.info.image !== null)
      if (newForm.info.image[0] instanceof File)
        uploadUserImage(newForm.info.image, newForm.password, history);
  }

  let url = null;
  if (newForm.id === undefined) {
    url = "user/new";
  } else {
    url = "user/update";
  }
  Request()
    .post(url, newForm)
    .then((response) => {
      if (response.data.status === true) {
        if (userIndex === null) {
          dispatch({
            type: UPDATE_USERS_PROPS,
            payload: [{ prop: "isCreated", value: true }],
          });
          toast.notify(local.Successed, {
            position: "top-right",
            duration: 2000,
          });
          history.push("/subdealrs/users");

          // dispatch({
          //   type: UPDATE_USERS_PROPS,
          //   payload: [{ prop: 'isLoadingSave', value: false }],
          // })
        } else {
          if (userIndex === -1) {
            if (newForm.password !== "") {
              toast.notify(local.PasswordSucceessed, {
                position: "top-right",
                duration: 2000,
              });
            }
            if (newForm.info.image !== null) {
              toast.notify(local.ImageSucceessed, {
                position: "top-right",
                duration: 2000,
              });
            }
            logoutWithhoutConfirmation(history);
          } else {
            dispatch({
              type: UPDATE_USERS_PROPS,
              payload: [
                {
                  prop: "rtable.users." + userIndex,
                  value: newForm,
                },
              ],
            });
          }
          // toast.notify(local.Edited, {
          //   position: 'top-right',
          //   duration: 2000,
          // })
          // history.push('/subdealrs/users')

          dispatch(update_user_name(newForm.id, newForm.info.name, history));
        }
        // dispatch({
        //   type: UPDATE_USERS_PROPS,
        //   payload: [{ prop: 'isLoadingSave', value: false }],
        // })
        // history.push('/subdealrs/users')
      } else {
        if (response.data.error.message !== null) {
          if (response.data.error.message[0] === "invalid data supplied") {
            toast.notify(local.UserNameID, {
              position: "top-right",
              duration: 2000,
            });
          }
          if (
            response.data.error.message[0].includes("client_phones_unique_ctx")
          ) {
            toast.notify(local.UniquePhone, {
              position: "top-right",
              duration: 2000,
            });
          }
          if (response.data.error.message[0].includes("users_unique")) {
            toast.notify(local.UniqueUserName, {
              position: "top-right",
              duration: 2000,
            });
          } else {
            response.data.error.message.map((mess) => {
              if (
                !response.data.error.message[0] === " invalid data supplied"
              ) {
                toast.notify(mess, {
                  position: "top-right",
                  duration: 2000,
                });
              }
            });
          }
        }
      }
      dispatch({
        type: UPDATE_USERS_PROPS,
        payload: [{ prop: "isLoadingSave", value: false }],
      });
    })

    .catch((error) => {
      dispatch({
        type: UPDATE_USERS_PROPS,
        payload: [{ prop: "isLoadingSave", value: false }],
      });
    })
    .finally();
};

export const update_user_name = (id, newName, history) => (dispatch) => {
  let date = new Date();
  date.setDate(date.getDate() + 1);

  let filter = {
    object: {
      user: id,
    },
    duration: {
      from: "2006-01-02T15:04:05Z",
      to: date.toISOString(),
    },
  };

  Request()
    .post("/client", filter)
    .then((response) => {
      if (response.data.status === true) {
        if (response.data.data.items !== null) {
          response.data.data.items[0].info.userName = newName;
          let client = response.data.data.items[0];

          dispatch({
            type: UPDATE_CUSTOMERS_PROPS,
            payload: [{ prop: "isEditUserName", value: true }],
          });
          dispatch(modifyUserName(client, history));
        } else {
          toast.notify(local.Edited, {
            position: "top-right",
            duration: 2000,
          });
          history.push("/subdealrs/users");
          dispatch({
            type: UPDATE_USERS_PROPS,
            payload: [{ prop: "isLoadingSave", value: false }],
          });
        }
      } else {
        dispatch({
          type: UPDATE_USERS_PROPS,
          payload: [{ prop: "isLoadingSave", value: false }],
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_USERS_PROPS,
        payload: [{ prop: "isLoadingSave", value: false }],
      });
      console.log(error);
    })
    .finally();
};

export const modifyUserName = (newForm, history) => (dispatch) => {
  Request()
    .post("/client/update", newForm)
    .then((response) => {
      console.log(response);
      toast.notify(local.Edited, {
        position: "top-right",
        duration: 2000,
      });
      history.push("/subdealrs/users");
      dispatch({
        type: UPDATE_USERS_PROPS,
        payload: [{ prop: "isLoadingSave", value: false }],
      });
    })
    .catch((error) => {
      console.log(error);
    })
    .finally();
};

export const getUsersForDropDown = (filter) => (dispatch) => {
  let date = new Date();
  date.setDate(date.getDate() + 1);

  filter.duration.to = date.toISOString();
  Request()
    .post("/user", filter)
    .then((response) => {
      if (response.data.status === true) {
        if (response.data.data.items !== null) {
          let users = [];
          response.data.data.items.map((user) => {
            users.push({
              label: user.name,
              value: user.id,
              country: user.info.country.id,
            });
          });
          dispatch({
            type: UPDATE_USERS_PROPS,
            payload: [
              {
                prop: "usersOptions",
                value: users,
              },
            ],
          });
        }
      }
    })
    .catch((error) => {
      // toast.notify(local.InternetConnection, {
      //   position: 'top-right',
      //   duration: 2000,
      // })
      console.log(error);
    })
    .finally();
};

// export const fetchUserById = (id) => async (dispatch) => {
//   let date = new Date()
//   date.setDate(date.getDate() + 1)

//   let filter = {
//     user: { id: id },
//     range: {
//       from: '2006-01-02T15:04:05Z',
//       to: date.toISOString(),
//     },
//   }
//   Request()
//     .post('/company/user/list', filter)
//     .then((response) => {
//       if (response.data.status === true) {
//         if (response.data.data !== null) {
//           localStorage.setItem(
//             USER_SHIPMENT,
//             JSON.stringify(response.data.data),
//           )
//           dispatch({
//             type: UPDATE_USERS_PROPS,
//             payload: [{ prop: 'user', value: response.data.data }],
//           })
//         }
//       }
//     })
//     .catch((error) => {
//       toast(local.InternetConnection)

//       console.log(error)
//     })
//     .finally()
// }

export const uploadUserImage = (file, password, history) => {
  let formImage = new FormData();
  formImage.append("myFile", file[0]);
  Request()
    .post("/user/upload", formImage)
    .then((response) => {
      if (password === "") {
        toast.notify(local.ImageSucceessed, {
          position: "top-right",
          duration: 2000,
        });
      }
      logoutWithhoutConfirmation(history);
    })
    .catch((error) => {
      console.log(error);
    })
    .finally();
};

import {
  UPDATE_LOGIN_PROPS,
  MERGE_LOGIN_PROPS,
  DELETE_LOGIN_PROPS,
} from '../Actions/types'
import * as general from './methods'

const INITIAL_STATE = {
  userType: '',
  isViewAdminPassword: 'password',
  isViewCompanyPassword: 'password',
  isViewCustomerPassword: 'password',

  adminAuth: {
    name: '',
    password: '',
    role: 0,
  },

  companyAuth: {
    name: '',
    password: '',
    role: 1,
  },

  customerAuth: {
    name: '',
    password: '',
    company: null,
    role: 2,
  },

  ErrorMessage: {
    ereorUserName: '',
    errorPassword: '',
    errorIDNumber: '',
  },
  isLoading: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_LOGIN_PROPS: {
      return general.updateProps(state, action)
    }
    case MERGE_LOGIN_PROPS: {
      return general.mergeProps(state, action)
    }
    case DELETE_LOGIN_PROPS: {
      return general.deleteProps(state, action)
    }
    default:
      return state
  }
}

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Input } from "reactstrap";
import { local, getLanguage } from "../../Localization/local";
import toast from "toasted-notes";
import { UPDATE_CUSTOMERS_PROPS } from "../../Redux/Actions/types";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Message = (props) => {
  const { locationAddress, general } = props;
  const handleClose = () => {
    general(
      [
        { prop: "locationAddress.isOpen", value: false },
        { prop: "locationAddress.addressProp", value: "" },
        { prop: "locationAddress.address", value: "" },
        { prop: "urlLink", value: "" },
      ],
      UPDATE_CUSTOMERS_PROPS
    );
  };

  const onSave = (e) => {
    e.preventDefault();

    // if (locationAddress.address === '') {
    //   toast.notify(local.AddressRequired, {
    //     position: 'top-right',
    //     duration: 2000,
    //   })
    // } else {
    general(
      [
        { prop: "locationAddress.isOpen", value: false },
        { prop: "locationAddress.addressProp", value: "" },
        { prop: "locationAddress.address", value: "" },
        { prop: "urlLink", value: "" },
      ],
      UPDATE_CUSTOMERS_PROPS
    );
    // }
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={locationAddress.isOpen}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={getLanguage() === "en" ? handleClose : null}
          style={{
            color: "white",
            borderRaduios: "15px",
            backgroundColor: "#1d6aab",
          }}
        >
          {local.LocationAddress}
        </DialogTitle>

        <Input
          type="textarea"
          name="text"
          rows="5"
          cols="40"
          placeholder={local.Address}
          onChange={(e) =>
            general(
              [
                { prop: locationAddress.addressProp, value: e.target.value },
                { prop: "locationAddress.address", value: e.target.value },
              ],
              UPDATE_CUSTOMERS_PROPS
            )
          }
          style={{
            width: "100%",
            height: "100%",
            direction: getLanguage() === "en" ? "ltr" : "rtl",
            fontSize: getLanguage() === "en" ? "15px" : "18px",
            color: "black",
            fontFamily: "cursive",
            border: 0,
          }}
        />

        <DialogActions className="white">
          <Button
            className="btn-file"
            onClick={(e) => onSave(e)}
            style={{ direction: "rtl" }}
          >
            {local.Save}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Message;

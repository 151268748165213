import { Request } from './Api'
import { UPDATE_ADMIN_HOME_PROPS } from './types'
import { checkisAuthorized } from '../../General/GeneralFunctions'

export const getStatistics = (history) => (dispatch) => {
  if (checkisAuthorized() === false) {
    history.push('/')
  } else {
    dispatch({
      type: UPDATE_ADMIN_HOME_PROPS,
      payload: [{ prop: 'isLoading', value: true }],
    })

    Request()
      .post('/stat')
      .then((response) => {
        if (response.data.status === true) {
          dispatch({
            type: UPDATE_ADMIN_HOME_PROPS,
            payload: [{ prop: 'statistics', value: response.data.data }],
          })
        }
        dispatch({
          type: UPDATE_ADMIN_HOME_PROPS,
          payload: [{ prop: 'isLoading', value: false }],
        })
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_ADMIN_HOME_PROPS,
          payload: [{ prop: 'isLoading', value: false }],
        })
        history.push('/error')
        console.log(error)
      })
      .finally()
  }
}

import { Request } from "./Api";
import {
  MERGE_CUSTOMERS_PROPS,
  UPDATE_CUSTOMERS_PROPS,
  UPDATE_SHIPMENTS_PROPS,
} from "./types";
import {
  checkisAuthorized,
  setCodeForMap,
} from "../../General/GeneralFunctions";
import toast from "toasted-notes";
import { local } from "../../Localization/local";
import { getCitiesForDropDown } from "./CitiesActions";
import { EnglishCountries } from "../../General/EnglishCountries";
import { findDefaultItem } from "../../General/SelectFunctions";
export const fetchCustomers = (history, filter, isSearch) => async (
  dispatch
) => {
  if (checkisAuthorized() === false) {
    history.push("/");
  } else {
    if (isSearch !== undefined) {
      dispatch({
        type: UPDATE_CUSTOMERS_PROPS,
        payload: [
          { prop: "isLoadingSearch", value: true },
          { prop: "isSearchable", value: true },
        ],
      });
    } else {
      dispatch({
        type: UPDATE_CUSTOMERS_PROPS,
        payload: [
          { prop: "isSearchable", value: false },
          { prop: "isCreated", value: false },
          { prop: "isLoading", value: true },
          { prop: "isEditUserName", value: false },
        ],
      });
    }
    let date = new Date();
    date.setDate(date.getDate() + 1);

    filter.duration.to = date.toISOString();
    if (isSearch === undefined) {
      Request()
        .post("/user", filter)
        .then((res) => {
          if (res.data.status === true) {
            if (res.data.data.items !== null) {
              filter.object.user = res.data.data.items[0].id;

              Request()
                .post("/client", filter)
                .then((response) => {
                  if (response.data.status === true) {
                    if (response.data.data.items !== null) {
                      dispatch(getShipments(response.data.data.items));

                      dispatch({
                        type: UPDATE_CUSTOMERS_PROPS,
                        payload: [
                          {
                            prop: "customerShipments",
                            value: [],
                          },
                          {
                            prop: "rtable.customers",
                            value: response.data.data.items,
                          },
                          { prop: "count", value: response.data.data.pages },
                        ],
                      });
                    } else {
                      if (isSearch !== undefined) {
                        toast.notify(local.SearchResult, {
                          position: "top-right",
                          duration: 2000,
                        });
                      }
                      dispatch({
                        type: UPDATE_CUSTOMERS_PROPS,
                        payload: [{ prop: "rtable.customers", value: [] }],
                      });
                    }
                  }
                  dispatch({
                    type: UPDATE_CUSTOMERS_PROPS,
                    payload: [
                      { prop: "isLoading", value: false },
                      { prop: "isLoadingSearch", value: false },
                    ],
                  });
                })
                .catch((error) => {
                  dispatch({
                    type: UPDATE_CUSTOMERS_PROPS,
                    payload: [{ prop: "isLoading", value: false }],
                  });
                  history.push("/error");

                  console.log(error);
                });
            } else {
              dispatch({
                type: UPDATE_CUSTOMERS_PROPS,
                payload: [
                  {
                    prop: "customerShipments",
                    value: [],
                  },
                  { prop: "rtable.customers", value: [] },
                  { prop: "isLoading", value: false },
                  { prop: "isLoadingSearch", value: false },
                ],
              });
            }
          }
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_CUSTOMERS_PROPS,
            payload: [{ prop: "isLoading", value: false }],
          });
          // history.push('/error')

          console.log(error);
        })
        .finally();
    } else {
      Request()
        .post("/client", filter)
        .then((response) => {
          if (response.data.status === true) {
            if (response.data.data.items !== null) {
              dispatch(getShipments(response.data.data.items));

              dispatch({
                type: UPDATE_CUSTOMERS_PROPS,
                payload: [
                  {
                    prop: "customerShipments",
                    value: [],
                  },
                  {
                    prop: "rtable.customers",
                    value: response.data.data.items,
                  },
                  { prop: "count", value: response.data.data.pages },
                ],
              });
            } else {
              if (isSearch !== undefined) {
                toast.notify(local.SearchResult, {
                  position: "top-right",
                  duration: 2000,
                });

                dispatch({
                  type: UPDATE_CUSTOMERS_PROPS,
                  payload: [
                    {
                      prop: "customerShipments",
                      value: [],
                    },
                    { prop: "rtable.customers", value: [] },
                    { prop: "isLoading", value: false },
                    { prop: "isLoadingSearch", value: false },
                  ],
                });
              }
            }
          }
          dispatch({
            type: UPDATE_CUSTOMERS_PROPS,
            payload: [
              { prop: "isLoading", value: false },
              { prop: "isLoadingSearch", value: false },
            ],
          });
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_CUSTOMERS_PROPS,
            payload: [{ prop: "isLoading", value: false }],
          });
          history.push("/error");

          console.log(error);
        })
        .finally();
    }
  }
};

export const getCustomerSearchResult = (filter) => (dispatch) => {
  dispatch({
    type: UPDATE_CUSTOMERS_PROPS,
    payload: [
      { prop: "isLoadingSearch", value: true },
      { prop: "isSearchable", value: true },
    ],
  });

  Request()
    .post("/client/byphone", filter)
    .then((response) => {
      if (response.data.status === true) {
        dispatch(getShipments(response.data.data));

        if (response.data.data.length !== 0) {
          dispatch({
            type: UPDATE_CUSTOMERS_PROPS,
            payload: [
              {
                prop: "customerShipments",
                value: [],
              },
              { prop: "rtable.customers", value: response.data.data },
            ],
          });
        } else {
          toast.notify(local.SearchResult, {
            position: "top-right",
            duration: 2000,
          });
          dispatch({
            type: UPDATE_CUSTOMERS_PROPS,
            payload: [
              {
                prop: "customerShipments",
                value: [],
              },
              { prop: "rtable.customers", value: [] },
            ],
          });
        }
      }
      dispatch({
        type: UPDATE_CUSTOMERS_PROPS,
        payload: [{ prop: "isLoadingSearch", value: false }],
      });
    })
    .catch((error) => {
      // toast.notify(local.InternetConnection, {
      //   position: 'top-right',
      //   duration: 2000,
      // })
      console.log(error);
      dispatch({
        type: UPDATE_CUSTOMERS_PROPS,
        payload: [{ prop: "isLoadingSearch", value: false }],
      });
    })
    .finally();
};

export const modifyCustomer = (newForm, history, index, isSms, isLink) => (
  dispatch
) => {
  dispatch({
    type: UPDATE_CUSTOMERS_PROPS,
    payload: [{ prop: "isLoadingSave", value: true }],
  });

  let url = null;
  if (newForm.id === undefined) {
    url = "/client/new";
  } else {
    if (newForm.info.details !== undefined) {
      delete newForm.info.details;
    }
    url = "/client/update";
  }
  Request()
    .post(url, newForm)
    .then(async (response) => {
      if (response.data.status === true) {
        if (newForm.id === undefined) {
          if (isSms !== undefined && isLink !== undefined) {
            if (isLink === true) {
              newForm.id = response.data.data;
              dispatch(getCustomerLink(response.data.data));

              await dispatch({
                type: UPDATE_CUSTOMERS_PROPS,
                payload: [
                  { prop: "generateLink.isOpen", value: true },
                  // {
                  //   prop: "generateLink.link",
                  //   value:
                  //     " https://medical.altawasol.app/client/add/id/" +
                  //     response.data.data,
                  // },

                  { prop: "generateLink.customer", value: newForm },
                  { prop: "generateLink.isSendSms", value: false },
                  { prop: "generateLink.isGetLink", value: false },

                  {
                    prop: "newForm",
                    value: {
                      name: "",
                      user: null,
                      mail: "",
                      info: {
                        name: "",
                        // image: null,
                        county: { id: null, name: "" },
                        state: { id: null, name: "" },
                        country: { id: null, name: "" },
                        patient_id: "",
                        notes: "",
                        userName: "",
                      },
                      phones: [],
                      addresses: [],
                      locations: [],
                    },
                  },
                ],
              });
            }
          }
          if (newForm.locations.length === 0) {
            dispatch({
              type: UPDATE_CUSTOMERS_PROPS,
              payload: [{ prop: "isCreated", value: true }],
            });
          }
          dispatch({
            type: UPDATE_CUSTOMERS_PROPS,
            payload: [
              { prop: "newForm.info.country.id", value: null },
              { prop: "newForm.info.country.name", value: "" },
              { prop: "newForm.phones", value: [] },
              { prop: "newForm.user", value: null },
              { prop: "smsData.isOpen", value: false },
            ],
          });
          // if (isSms === undefined) {
          toast.notify(local.Successed, {
            position: "top-right",
            duration: 2000,
          });
          // }
          dispatch({
            type: UPDATE_CUSTOMERS_PROPS,
            payload: [{ prop: "isCreated", value: true }],
          });
        } else {
          dispatch({
            type: UPDATE_CUSTOMERS_PROPS,
            payload: [
              {
                prop: "rtable.customers." + index,
                value: newForm,
              },
            ],
          });

          toast.notify(local.Edited, {
            position: "top-right",
            duration: 2000,
          });
        }
        history.push("/subdealrs/customers");
      } else {
        if (response.data.error.message !== null) {
          if (
            response.data.error.message[0].includes("_phones") &&
            response.data.error.message[0].includes("_phone_user_idx")
          ) {
            toast.notify(local.UniquePhone, {
              position: "top-right",
              duration: 2000,
            });
          }
          if (response.data.error.message[0].includes("_uname")) {
            toast.notify(local.UserNameExist, {
              position: "top-right",
              duration: 2000,
            });
          }

          if (
            response.data.error.message[0] ===
            "user name should not contain spaces"
          ) {
            toast.notify(local.CusNameValidation, {
              position: "top-right",
              duration: 2000,
            });
          } else {
            response.data.error.message.map((mess) => {
              if (
                !response.data.error.message[0].includes("_uname") &&
                !response.data.error.message[0].includes("_phones") &&
                !response.data.error.message[0].includes("_phone_user_idx")
              )
                toast.notify(mess, {
                  position: "top-right",
                  duration: 2000,
                });
            });
          }
        }
      }
      dispatch({
        type: UPDATE_CUSTOMERS_PROPS,
        payload: [{ prop: "isLoadingSave", value: false }],
      });
    })

    .catch((error) => {
      dispatch({
        type: UPDATE_CUSTOMERS_PROPS,
        payload: [{ prop: "isLoadingSave", value: false }],
      });
    })
    .finally();
};

export const getCustomersforDropDown = (filter) => (dispatch) => {
  // if (isNaN(filter.object.name) === true) {
  let date = new Date();
  date.setDate(date.getDate() + 1);

  filter.duration.to = date.toISOString();

  Request()
    .post("/client", filter)
    .then((response) => {
      if (response.data.status === true) {
        if (response.data.data.items !== null) {
          let customers = [];
          response.data.data.items.map((cus) => {
            let locations = [];
            if (cus.locations !== undefined) {
              if (cus.locations.length > 0) {
                cus.locations.map((loc, index) => {
                  locations.push({
                    label: cus.addresses[index],
                    value: loc,
                    index: index,
                  });
                });
              }
            }
            if (cus.phones !== undefined) {
              if (cus.phones.length > 0) {
                cus.phones[0] = cus.phones[0].replace(/[()]/g, "");
                cus.phones[0] = cus.phones[0].replace(/"/g, "");
              }
            }

            if (cus.info.country !== undefined) {
              customers.push({
                label: cus.name,
                OrderID: cus.info.patient_id,
                value: cus.id,
                locations: locations,
                phone: cus.phones[0],
                address: cus.addresses,
                country: cus.info.country.id,
                countryName: cus.info.country.name,
                user: cus.user,
                userName: cus.info.userName,
              });
            } else {
              customers.push({
                label: cus.name,
                value: cus.id,
                locations: locations,
                phone: cus.phones[0],
                address: cus.addresses,
                user: cus.user,
                userName: cus.info.userName,
              });
            }
          });

          dispatch({
            type: UPDATE_CUSTOMERS_PROPS,
            payload: [{ prop: "customerOptions", value: customers }],
          });
        }
      }
    })
    .catch((error) => {
      // toast.notify(local.InternetConnection, {
      //   position: 'top-right',
      //   duration: 2000,
      // })
      console.log(error);
    })
    .finally();
  // } else {
  //   let newFilter = {
  //     // user: search.user,
  //     phones: [filter.object.name],
  //   };
  //   Request()
  //     .post("/client/byphone", newFilter)
  //     .then((response) => {
  //       if (response.data.status === true) {
  //         if (response.data.data.length !== 0) {
  //           debugger;
  //           let customers = [];
  //           response.data.data.map((cus) => {
  //             let locations = [];
  //             if (cus.locations !== undefined) {
  //               if (cus.locations.length > 0) {
  //                 cus.locations.map((loc, index) => {
  //                   locations.push({
  //                     label: cus.addresses[index],
  //                     value: loc,
  //                     index: index,
  //                   });
  //                 });
  //               }
  //             }
  //             if (cus.phones !== undefined) {
  //               if (cus.phones.length > 0) {
  //                 cus.phones[0] = cus.phones[0].replace(/[()]/g, "");
  //                 cus.phones[0] = cus.phones[0].replace(/"/g, "");
  //               }
  //             }

  //             if (cus.info.country !== undefined) {
  //               customers.push({
  //                 label: cus.name,
  //                 value: cus.id,
  //                 locations: locations,
  //                 phone: cus.phones[0],
  //                 address: cus.addresses,
  //                 country: cus.info.country.id,
  //                 countryName: cus.info.country.name,
  //                 user: cus.user,
  //                 userName: cus.info.userName,
  //               });
  //             } else {
  //               customers.push({
  //                 label: cus.name,
  //                 value: cus.id,
  //                 locations: locations,
  //                 phone: cus.phones[0],
  //                 address: cus.addresses,
  //                 user: cus.user,
  //                 userName: cus.info.userName,
  //               });
  //             }
  //           });
  //           dispatch({
  //             type: UPDATE_CUSTOMERS_PROPS,
  //             payload: [{ prop: "customerOptions", value: customers }],
  //           });
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })
  //     .finally();
  // }
};

export const getCustomerById = (id, history, index) => (dispatch) => {
  dispatch({
    type: UPDATE_SHIPMENTS_PROPS,
    payload: [{ prop: "isLoadingCustomer", value: index }],
  });

  let date = new Date();
  date.setDate(date.getDate() + 1);

  let filter = {
    object: {
      id: id,
    },
    duration: {
      from: "2006-01-02T15:04:05Z",
      to: date.toISOString(),
    },
  };
  Request()
    .post("/client", filter)
    .then((response) => {
      if (response.data.status === true) {
        if (response.data.data.items !== null) {
          response.data.data.items[0].phones[0] = response.data.data.items[0].phones[0].replace(
            /[()]/g,
            ""
          );
          if (response.data.data.items[0].phones.length > 1) {
            response.data.data.items[0].phones[1] = response.data.data.items[0].phones[1].replace(
              /[()]/g,
              ""
            );
          }

          if (response.data.data.items[0].info.image === undefined) {
            response.data.data.items[0].info.image = null;
          }
          if (response.data.data.items[0].info.country === undefined) {
            response.data.data.items[0].info.country = { id: null, name: "" };
          }
          if (response.data.data.items[0].info.state === undefined) {
            response.data.data.items[0].info.state = { id: null, name: "" };
          }
          if (response.data.data.items[0].info.county === undefined) {
            response.data.data.items[0].info.county = { id: null, name: "" };
          }

          if (response.data.data.items[0].info.details === undefined) {
            response.data.data.items[0].info.details = "";
          }

          if (response.data.data.items[0].info.orderId === undefined) {
            response.data.data.items[0].info.orderId = "";
          }

          if (response.data.data.items[0].info.notes === undefined) {
            response.data.data.items[0].info.notes = "";
          }

          if (response.data.data.items[0].info.userName === undefined) {
            response.data.data.items[0].info.userName = "";
          }

          dispatch({
            type: UPDATE_CUSTOMERS_PROPS,
            payload: [{ prop: "newForm", value: response.data.data.items[0] }],
          });

          history.push("customers/viewcustomer");
        }
      }
      dispatch({
        type: UPDATE_SHIPMENTS_PROPS,
        payload: [{ prop: "isLoadingCustomer", value: null }],
      });
    })
    .catch((error) => {
      console.log(error);
    })
    .finally();
};

export const getClientInfo = (company, user, id) => (dispatch) => {
  dispatch({
    type: UPDATE_CUSTOMERS_PROPS,
    payload: [{ prop: "smsData.isLoading", value: true }],
  });
  Request()
    .post("client/info/" + company + "/" + id + "/" + user)
    .then((res) => {
      if (res.data.status === true) {
        let sdate = new Date();
        sdate.setDate(sdate.getDate() + 1);

        let StateFilter = {
          object: { country: res.data.data[0].info.country.id },
          duration: {
            from: "2006-01-02T15:04:05Z",
            to: sdate.toISOString(),
          },
        };
        dispatch(getCitiesForDropDown(StateFilter));

        if (res.data.data[0].info.patient_id === undefined) {
          dispatch({
            type: UPDATE_CUSTOMERS_PROPS,
            payload: [
              {
                prop: "mapCodePoints",
                value: {
                  code: setCodeForMap(
                    res.data.data[0].info.country.id,
                    EnglishCountries
                  ).code,
                  lat: setCodeForMap(
                    res.data.data[0].info.country.id,
                    EnglishCountries
                  ).lat,
                  lng: setCodeForMap(
                    res.data.data[0].info.country.id,
                    EnglishCountries
                  ).lng,
                },
              },
              { prop: "newForm.id", value: res.data.data[0].id },
              { prop: "newForm.date", value: res.data.data[0].date },
              {
                prop: "newForm.name",
                value: res.data.data[0].name,
              },
              {
                prop: "newForm.info.country",
                value: res.data.data[0].info.country,
              },
              {
                prop: "newForm.user",
                value: res.data.data[0].user,
              },
              {
                prop: "newForm.info.userName",
                value: res.data.data[0].info.userName,
              },
              {
                prop: "newForm.info.sms",
                value: res.data.data[0].info.sms,
              },
            ],
          });
        } else {
          if (res.data.data[0].phones === undefined) {
            res.data.data[0].phones = [];
          }
          if (res.data.data[0].locations === undefined) {
            res.data.data[0].locations = [];
          }
          if (res.data.data[0].addresses === undefined) {
            res.data.data[0].addresses = [];
          }
          dispatch({
            type: UPDATE_CUSTOMERS_PROPS,
            payload: [
              // { prop: "newForm.info.isAdded", value: true },

              // {
              //   prop: "mapCodePoints",
              //   value: {
              //     code: setCodeForMap(
              //       res.data.data[0].info.country.id,
              //       EnglishCountries
              //     ).code,
              //     lat: setCodeForMap(
              //       res.data.data[0].info.country.id,
              //       EnglishCountries
              //     ).lat,
              //     lng: setCodeForMap(
              //       res.data.data[0].info.country.id,
              //       EnglishCountries
              //     ).lng,
              //   },
              // },
              { prop: "newForm", value: res.data.data[0] },
            ],
          });
        }
      }
      dispatch({
        type: UPDATE_CUSTOMERS_PROPS,
        payload: [{ prop: "smsData.isLoading", value: false }],
      });
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_CUSTOMERS_PROPS,
        payload: [{ prop: "smsData.isLoading", value: false }],
      });
      console.log(error);
    })
    .finally();
};

export const saveClientData = (company, user, id, newForm) => (dispatch) => {
  dispatch({
    type: UPDATE_CUSTOMERS_PROPS,
    payload: [{ prop: "smsData.isUpdate", value: true }],
  });

  console.log(JSON.stringify(newForm));

  Request()
    .post("client/update/" + company + "/" + id + "/" + user, newForm)
    .then((response) => {
      if (response.data.state === true) {
        newForm.info.isSaved = true;

        dispatch(getClientInfo(company, user, id));
      } else {
        if (response.data.error !== null) {
          if (response.data.error.message !== null) {
            response.data.error.message.map((mes) => {
              toast.notify(mes, {
                position: "top-right",
                duration: 2000,
              });
            });
          }
        }
      }
      dispatch({
        type: UPDATE_CUSTOMERS_PROPS,
        payload: [{ prop: "smsData.isUpdate", value: false }],
      });
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_CUSTOMERS_PROPS,
        payload: [{ prop: "smsData.isUpdate", value: false }],
      });
    })
    .finally();
};

export const createCustomersFormFile = (customers, CitiesCounties, history) => (
  dispatch
) => {
  dispatch({
    type: UPDATE_CUSTOMERS_PROPS,
    payload: [{ prop: "fileData.isLoading", value: true }],
  });
  customers.map((customer, index) => {
    let point1 =
      "POINT(" +
      customer.locations[0].split("@")[1].split(",")[1] +
      " " +
      customer.locations[0].split("@")[1].split(",")[0] +
      ")";

    let point2 =
      "POINT(" +
      customer.locations[1].split("@")[1].split(",")[1] +
      " " +
      customer.locations[1].split("@")[1].split(",")[0] +
      ")";
    let newForm = {
      name: customer.name,
      user: customer.user,
      mail: customer.mail,
      info: {
        name: "",
        county: {
          id: findDefaultItem(customer.county, CitiesCounties).value,
          name: findDefaultItem(customer.county, CitiesCounties).label,
        },
        state: {
          id: findDefaultItem(customer.state, CitiesCounties).value,
          name: findDefaultItem(customer.state, CitiesCounties).label,
        },
        country: { id: customer.countryID, name: customer.country },
        patient_id: customer.patient_id,
        notes: customer.notes,
        userName: customer.userName,
      },
      phones: customer.phones,
      addresses: customer.addresses,

      locations: [point1, point2],
    };
    Request()
      .post("/client/new", newForm)
      .then((response) => {
        if (response.data.status === true) {
          if (customers.length - 1 === index) {
            toast.notify(local.Successed, {
              position: "top-right",
              duration: 2000,
            });

            history.push("/subdealrs/customers");
            dispatch({
              type: UPDATE_CUSTOMERS_PROPS,
              payload: [
                {
                  prop: "fileData",
                  value: {
                    columns: [],
                    data: [],
                    myFile: null,
                    isLoading: false,
                    CitiesCounties: [],
                  },
                },
              ],
            });
          }
        } else {
          if (response.data.error.message !== null) {
            response.data.error.message.map((mess) => {
              toast.notify(mess + local.errorInClient + " " + newForm.name, {
                position: "top-right",
                duration: 2000,
              });
            });
          }
        }
        dispatch({
          type: UPDATE_CUSTOMERS_PROPS,
          payload: [{ prop: "fileData.isLoading", value: false }],
        });
      })

      .catch((error) => {
        dispatch({
          type: UPDATE_CUSTOMERS_PROPS,
          payload: [{ prop: "fileData.isLoading", value: false }],
        });
      })
      .finally();
  });
};

export const getCityandCounty = (filterCity, filterCounty) => (dispatch) => {
  Request()
    .post("/states", filterCity)
    .then((response) => {
      if (response.data.status === true) {
        if (response.data.data.items.States !== null) {
          response.data.data.items.States.map((city) => {
            dispatch({
              type: MERGE_CUSTOMERS_PROPS,
              payload: [
                {
                  prop: "fileData.CitiesCounties",
                  value: {
                    label: filterCity.object.en_name,
                    value: city.id,
                    country: city.country,
                  },
                },
              ],
            });
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally();

  Request()
    .post("/counties", filterCounty)
    .then((response) => {
      if (response.data.data.items.Counties !== null) {
        response.data.data.items.Counties.map((county) => {
          dispatch({
            type: MERGE_CUSTOMERS_PROPS,
            payload: [
              {
                prop: "fileData.CitiesCounties",
                value: {
                  label: filterCounty.object.en_name,
                  value: county.id,
                  state: county.state,
                },
              },
            ],
          });
        });
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally();
};

export const getCustomerLink = (id) => (dispatch) => {
  let date = new Date();
  date.setDate(date.getDate() + 1);
  let filter = {
    object: { id: id },
    duration: {
      from: "2006-01-02T15:04:05Z",
      to: date.toISOString(),
    },
  };
  Request()
    .post("/client", filter)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data.items !== null) {
          debugger;
          dispatch({
            type: UPDATE_CUSTOMERS_PROPS,
            payload: [
              {
                prop: "generateLink.link",
                value: res.data.data.items[0].info.sms,
              },
            ],
          });
          debugger;
        }
      }
    })
    .catch((error) => {
      console.log(error).finally();
    });
};

export const getShipments = (customers) => (dispatch) => {
  let date = new Date();
  date.setDate(date.getDate() + 1);

  if (customers.length > 0) {
    customers.map((customer) => {
      let filter = {
        object: {
          client: customer.id,
        },
        duration: {
          from: "2006-01-02T15:04:05Z",
          to: date.toISOString(),
        },
      };

      Request()
        .post("/shipment", filter)
        .then((response) => {
          if (response.data.status === true) {
            if (response.data.data.items !== null) {
              let client = {
                customer: customer,
                shipment: response.data.data.items,
              };

              dispatch({
                type: MERGE_CUSTOMERS_PROPS,
                payload: [
                  {
                    prop: "customerShipments",
                    value: client,
                  },
                ],
              });
            } else {
              let client = {
                customer: customer,
              };

              dispatch({
                type: MERGE_CUSTOMERS_PROPS,
                payload: [
                  {
                    prop: "customerShipments",
                    value: client,
                  },
                ],
              });
            }
          } else {
            let client = {
              customer: customer,
            };

            dispatch({
              type: MERGE_CUSTOMERS_PROPS,
              payload: [
                {
                  prop: "customerShipments",
                  value: client,
                },
              ],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
};

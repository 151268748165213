import { combineReducers } from "redux";
import HomeReducer from "./HomeReducer";
import AgaintReducer from "./AgaintReducer";
import CustomerReducer from "./CustomerReducer";
import LoginReducer from "./LoginReducer";
import TrackingReducer from "./TrackingReducer";
import CompaniesReducer from "./CompaniesReducer";
import CountiesReducer from "./CountiesReducer";
import CitiesReducer from "./CitiesReducer";
import CountriesReducer from "./CountriesReducer";
import ShipmentsReducer from "./ShipmentsReducer";
import UsersReducer from "./UsersReducer";
import AdminHomeReducer from "./AdminHomeReducer";
import CustomerHomeReducer from "./CustomerHomeReducer";
import TrackingHistoryReducer from "./TrackingHistoryReducer";
import PriceReducer from "./PriceReducer";
import StatisticsReducer from "./StatisticsReducer";

export default combineReducers({
  homeR: HomeReducer,
  AgaintR: AgaintReducer,
  CustomerR: CustomerReducer,
  TrackingR: TrackingReducer,
  LoginR: LoginReducer,
  CompanyR: CompaniesReducer,
  CountiesR: CountiesReducer,
  CityR: CitiesReducer,
  CountriesR: CountriesReducer,
  ShipmentsR: ShipmentsReducer,
  UsersR: UsersReducer,
  AdminHomeR: AdminHomeReducer,
  CustomerHomeR: CustomerHomeReducer,
  TrackingHistoryR: TrackingHistoryReducer,
  PriceR: PriceReducer,
  StatisticsR: StatisticsReducer,
});

import { RequestLogin, Request } from "./Api";
import { UPDATE_LOGIN_PROPS } from "./types";
import { ShipmentSUSER, TOKEN } from "../../General/StaticKeys";
import toast from "toasted-notes";
import { local } from "../../Localization/local";
export const login = (auth, history, type) => (dispatch) => {
  dispatch({
    type: UPDATE_LOGIN_PROPS,
    payload: [{ prop: "isLoading", value: true }],
  });

  RequestLogin()
    .post("/logon", auth)
    .then((response) => {
      if (response.data.status === true) {
        if (type === "admin") {
          let admin = { name: "Admin" };
          response.data.data = admin;
        }
        localStorage.setItem(TOKEN, response.data.token);
        // dispatch(getIPLcation())
        if (type === "admin") {
          localStorage.setItem(
            ShipmentSUSER,
            JSON.stringify(response.data.data)
          );
          dispatch({
            type: UPDATE_LOGIN_PROPS,
            payload: [{ prop: "isLoading", value: false }],
          });
          history.push("/subdealrs/dashboard");
        } else if (type === "shipping") {
          dispatch(getCompanyInfo(history));
        } else {
          dispatch(getCustomerInfo(history));
        }
      } else {
        if (response.data.error !== null) {
          toast.notify(local.ErrorAdminLogin, {
            position: "top-right",
            duration: 2000,
          });
        }
        dispatch({
          type: UPDATE_LOGIN_PROPS,
          payload: [{ prop: "isLoading", value: false }],
        });
      }
    })
    .catch((error) => {
      // if (type === 'admin') {

      // }
      dispatch({
        type: UPDATE_LOGIN_PROPS,
        payload: [{ prop: "isLoading", value: false }],
      });
      history.push("/error");
    })
    .finally();
};

// export const getIPLcation = () => (dispatch) => {
//   let url = '/gis/ip_location'
//   RequestLocation()
//     .get(url)
//     .then((response) => {
//       localStorage.setItem(
//         SHIPMENT_IP_LOCATION,
//         JSON.stringify(response.data.data),
//       )
//     })
//     .catch((err) => {
//       console.log(err)
//     })
//     .finally()
// }

export const getCompanyInfo = (history) => async (dispatch) => {
  let date = new Date();
  date.setDate(date.getDate() + 1);
  let filter = {
    object: {},
    duration: {
      from: "2006-01-02T15:04:05Z",
      to: date.toISOString(),
    },
  };
  let image = null;

  Request()
    .post("/company/retrive", {})
    .then((response) => {
      image = response.data;
    })
    .catch((error) => {
      console.log(error);
    })
    .finally();
  await Request()
    .post("/company", filter)
    .then(async (response) => {
      response.data.data.items[0].info.image = image;
      localStorage.setItem(
        ShipmentSUSER,
        JSON.stringify(response.data.data.items[0])
      );
      dispatch({
        type: UPDATE_LOGIN_PROPS,
        payload: [{ prop: "isLoading", value: false }],
      });
      await history.push("/subdealrs/dashboard");
    })

    .catch((error) => {
      console.log(error);
    })
    .finally();
};

export const getCustomerInfo = (history) => async (dispatch) => {
  let date = new Date();
  let image = null;
  date.setDate(date.getDate() + 1);
  let filter = {
    object: {},
    duration: {
      from: "2006-01-02T15:04:05Z",
      to: date.toISOString(),
    },
  };

  Request()
    .post("/user/retrive", {})
    .then((response) => {
      image = response.data;
    })
    .catch((error) => {
      console.log(error);
    })
    .finally();
  await Request()
    .post("/user", filter)
    .then(async (response) => {
      response.data.data.items[0].info.image = image;
      localStorage.setItem(
        ShipmentSUSER,
        JSON.stringify(response.data.data.items[0])
      );
      dispatch({
        type: UPDATE_LOGIN_PROPS,
        payload: [{ prop: "isLoading", value: false }],
      });
      await history.push("/subdealrs/dashboard");
    })

    .catch((error) => {
      console.log(error);
    })
    .finally();
};
